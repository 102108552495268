import { customerServerApiClient } from 'utils/apiClient';

export const saasUserCorporationApi = {
  async getSaasUserCorporation(data: any) {
    const { domain } = data;
    try {
      const res = await customerServerApiClient.get(`saas-user-corporation?domain=${domain}`);
      const resBody = res.data;
      return resBody;
    } catch (err) {
      throw err;
    }
  },
};
