import { PATHS } from "utils/consts";
import { CancelSvg, CheckGreenSvg } from "utils/icons";
import Button from '../buttons/Button';

interface IProps {
  onClose: () => void;
}

const PasswordUpdated: React.FC<IProps> = (props) => {
  const { onClose } = props;

  return (
    <div className="fixed left-0 top-0 w-full h-screen z-50 bg-surface-primary p-4">
      <div className="flex justify-between items-end mb-4">
        <label className="text-lg font-bold text-font-primary"></label>
        <img className="" src={CancelSvg} onClick={() => { onClose(); }} />
      </div>
      <div className="w-full mt-8">
				<div className="bg-feedback-success-tint flex flex-col justify-center items-center p-8 rounded-xl">
          <div className="rounded-full border-2 border-feedback-success p-4 mb-8">
            <img className="w-24" src={CheckGreenSvg} />
          </div>
					<div className="w-full font-bold text-3xl font-text-primary text-center">Password Updated</div>
					<div className="w-full font-medium text-base font-text-primary mt-4">Your password has been updated. Please use your new password to login.</div>
          <Button
            className="w-full mt-8"
            onClick={() => { window.location.href = PATHS.LOGIN; }}
          >
            <>Proceed to Login</>
          </Button>
				</div>
      </div>
    </div>
  );
};

export default PasswordUpdated;