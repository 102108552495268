import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/common/buttons/Button";
import Header from "../../components/common/header/Header";
import RadioButton from "../../components/common/inputs/RadioButton";
import { ArrowRightSvg, BankCardSvg, PaymentLogo1, PaymentLogo2, PaymentLogo3 } from "../../utils/icons";
import { PATHS } from "utils/consts";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";

const BankCard: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cards } = useSelector((root: RootState) => root.card);
  const [cardId, setCardId] = useState<string>('');

  const onClickContinue = () => {
    navigate(`${PATHS.SELL}${PATHS.CONFRIM_ORDER}?payment=card&id=${cardId}`);
  }

  useEffect(() => {
    dispatch(AppActions.card.getCards({}));
  }, [])
  return (
    <>
      <Header
        title="Select a Card"
        backTo="/payment-method"
      />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Select a Card</label>
        <div className="max-h-96 overflow-auto">
          {
            cards.map((card) =>
              <RadioButton
                className="flex items-start bg-surface-secondary border rounded-xl p-4 mt-4 mb-2"
                onChange={(value) => {
                  if (value) {
                    setCardId(card.id);
                  }
                }}
                name="card"
              >
                <div className="ml-4">
                  <div className="flex">
                    {
                      {
                        ['Master']: <img src={PaymentLogo1} />,
                        ['Visa']: <img src={PaymentLogo2} />,
                        default: <img src={PaymentLogo3} />,
                      }[card.cardType]
                    }&nbsp;
                    <span className="text-base font-bold">{card.cardNumber}</span>
                  </div>
                </div>
              </RadioButton>
            )
          }
        </div>
        <Link to={`${PATHS.SELL}${PATHS.NEWCARD}`}>
          <div className="flex justify-between items-center bg-surface-secondary rounded-xl p-4 mt-4 mb-2">
            <div className="flex items-center mr-6">
              <img src={BankCardSvg} />
              <div className="flex items-center ml-4">
                <span className="text-base font-bold text-font-primary">Add Another Card</span>
                <img className="ml-2" src={PaymentLogo3} />
                <img className="ml-2" src={PaymentLogo1} />
                <img className="ml-2" src={PaymentLogo2} />
              </div>
            </div>
            <img src={ArrowRightSvg} />
          </div>
        </Link>
        <div
          className="absolute w-full px-2 left-0 bottom-2"
        >
          <Button
            className="w-full"
            disabled={!cardId}
            onClick={onClickContinue}
          >
            <>Continue</>
          </Button>
        </div>
      </div>
    </>
  );
}

export default BankCard;