import Accordion from "components/common/accordiation";
import Button from "components/common/buttons/Button";
import Header from "components/common/header/Header";
import { SelectCurrency } from "components/common/selects";

interface IProps {

}

const preferences = [
  {
    title: "Strictly Necessary Cookies",
    content: "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
    always: true,
    selected: false,
  },
  {
    title: "Targeting Cookies",
    content: "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
    always: false,
    selected: false,
  },
  {
    title: "Functional Cookies",
    content: "These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function properly.",
    always: false,
    selected: false,
  },
  {
    title: "Performance Cookies",
    content: "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.",
    always: false,
    selected: false,
  },
]

const CookiePreference: React.FC<IProps> = (props) => {
  return (
    <>
      <Header title="Cookie Preferences" />
      <div className="p-4 mt-4 flex flex-col">
        <label className="text-2xl font-bold text-font-primary">Privacy Preference Center</label>
        <p className="text-sm font-medium text-font-primary mt-4">
          When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalised web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.
        </p>
        <a className="text-sm font-medium text-primary mt-4">Read our cookie policy</a>
        <Button className="mt-4 mb-8"><>Allow All</></Button>
        <label className="text-2xl font-bold text-font-primary">Manage Consent Preferences</label>
        <div className="mt-4 mb-40">
          {
            preferences.map((el) => (
              <Accordion
                key={el.title}
                title={el.title}
                always={el.always}
                selected={el.selected}
              >
                <p className="text-xs font-medium text-font-primary">{el.content}</p>
              </Accordion>
            ))
          }
        </div>
      </div>
      <div className="fixed bottom-0 w-full p-4 border-t bg-surface-primary">
        <Button className="w-full">Reject All</Button>
        <Button className="w-full mt-4">Confirm My Choices</Button>
      </div>
    </>
  );
};

export default CookiePreference;