import { IQuote } from 'types';
import { bankAccountRules, ibanCurrencies } from './consts';

export const ellipsisAddress = (address: string) => {
  return `${address.slice(0, 6)}...${address.slice(
    address.length - 4,
    address.length
  )}`;
};

export const getCrypto = (
  amount: number,
  quote: IQuote,
  fee: number,
  type: number
) => {
  if (amount == 0) return '0.00000';
  if (type == 0)
    return Math.max((+amount - +fee) / +quote.liquidityQuotes, 0).toFixed(5);
  return Math.max((+amount + +fee) / +quote.liquidityQuotes, 0).toFixed(5);
};

export const getFiat = (
  amount: number,
  quote: IQuote,
  fee: number,
  type: number
) => {
  if (amount == 0) return '0.00';
  if (type == 0)
    return Math.max(+amount * +quote.liquidityQuotes + +fee, 0).toFixed(2);
  return Math.max(+amount * +quote.liquidityQuotes - +fee, 0).toFixed(2);
};

export const getRemainingDays = (createdAt: string) => {
  return (
    Math.ceil(
      (new Date(createdAt).getTime() - new Date().getTime()) / 1000 / 3600 / 24
    ) + 30
  );
};

export const getBankInputFields = (currency: string) => {
  if (ibanCurrencies.includes(currency)) {
    return bankAccountRules.IBAN_COUNTRY;
  }
  if (Object.keys(bankAccountRules).includes(currency))
    return bankAccountRules[currency];
  return bankAccountRules.OTHER;
};