import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { validate } from "crypto-address-validator-ts";

import WalletAddressInput from "components/common/inputs/WalletAddressInput";
import Button from "components/common/buttons/Button";
import Header from "components/common/header/Header";
import { AppActions } from "store";
import { PlatformContext } from "contexts";
import { PATHS } from "utils/consts";

const EnterWalletAddress: React.FC = () => {
  const dispatch = useDispatch();
  const { crypto } = useContext(PlatformContext);

  const [walletAddress, setWalletAddress] = useState<string>('');
  const [error, setError] = useState<string>('');

  const onChangeWalletAddress = (value: string) => {
    setWalletAddress(value);
  }

  useEffect(() => {
    let valid = false;
    if (walletAddress) {
      valid = validate(walletAddress, (crypto ?? 'USDT').toLowerCase(), {
        networkType: '',
        chainType: '',
      });
    }

    if (!valid && walletAddress) {
      setError('Wallet Address is not correct.');
    } else {
      setError('');
    }
  }, [walletAddress]);

  const onClickContinue = () => {
    dispatch(AppActions.user.setWalletAddress(walletAddress));
  }

  return (
    <>
      <Header title={`Enter ${crypto} Wallet Address`} />
      <div className="flex flex-col h-full justify-between p-4 mt-4">
        <div className="flex flex-col">
          <label className="text-2xl font-bold text-font-primary">Enter {crypto} Wallet Address</label>
          <div className="mt-4">
            <WalletAddressInput
              label={`${crypto} Wallet Address`}
              crypto={crypto}
              onChange={onChangeWalletAddress}
              error={error}
            />
          </div>
        </div>
        <div
          className="w-full px-2"
        >
          <Link to={`${PATHS.SELL}${PATHS.BANK_DETAIL}`}>
            <Button
              className="w-full"
              disabled={!!error || !walletAddress}
              onClick={onClickContinue}
            >
              Continue
            </Button>
          </Link>
          <div className="bottem-6 flex flex-col items-center pr-4 pl-4 w-full">
            <p className="text-center text-xs font-medium text-font-primary mt-2">By continuing you confirm that you own and have control of this wallet.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterWalletAddress;