interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

const CryptoIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#FEFEFE"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M20.6 12C20.6 16.7496 16.7496 20.6 12 20.6C7.25035 20.6 3.4 16.7496 3.4 12C3.4 7.25035 7.25035 3.4 12 3.4C16.7496 3.4 20.6 7.25035 20.6 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM18.2923 12C18.2923 15.4751 15.4751 18.2923 12 18.2923C8.52481 18.2923 5.70764 15.4751 5.70764 12C5.70764 8.52481 8.52481 5.70764 12 5.70764C15.4751 5.70764 18.2923 8.52481 18.2923 12ZM19.6923 12C19.6923 16.2483 16.2483 19.6923 12 19.6923C7.75161 19.6923 4.30764 16.2483 4.30764 12C4.30764 7.75161 7.75161 4.30764 12 4.30764C16.2483 4.30764 19.6923 7.75161 19.6923 12Z" />
      <path d="M15.2817 11.1182C15.4234 10.1726 14.7029 9.66417 13.7185 9.3249L14.0379 8.04364L13.2583 7.84964L12.947 9.09707C12.7422 9.04564 12.5319 8.99782 12.3221 8.95L12.6357 7.69445L11.8561 7.5L11.5367 8.78082C11.3671 8.74202 11.2001 8.70412 11.0386 8.66352L11.0395 8.65946L9.96398 8.39102L9.75645 9.22385C9.75645 9.22385 10.3353 9.35648 10.3231 9.3646C10.6389 9.44356 10.6957 9.65244 10.6863 9.81846L10.3226 11.2779C10.3443 11.2833 10.3723 11.2915 10.4039 11.3036L10.3213 11.2833L9.81149 13.328C9.7727 13.4236 9.6748 13.5675 9.45373 13.5129C9.46185 13.5242 8.88709 13.3717 8.88709 13.3717L8.5 14.2641L9.51509 14.5172C9.70367 14.5646 9.88864 14.6142 10.0705 14.6607L9.74788 15.9564L10.527 16.1504L10.8464 14.8691C11.0594 14.9264 11.266 14.9796 11.4681 15.0302L11.1496 16.306L11.9292 16.5L12.2518 15.207C13.5818 15.4587 14.5815 15.3572 15.0024 14.1545C15.3417 13.1863 14.9857 12.6273 14.286 12.2632C14.7958 12.1459 15.1793 11.8107 15.2817 11.1182ZM13.4997 13.6167C13.2592 14.5853 11.6283 14.0615 11.0995 13.9302L11.5281 12.2136C12.0569 12.3458 13.7518 12.607 13.4997 13.6167ZM13.741 11.1042C13.5213 11.9853 12.1642 11.5373 11.7244 11.4277L12.1124 9.87125C12.5522 9.98088 13.9702 10.1852 13.741 11.1042Z" />
    </svg>
  );
};

export default CryptoIcon;