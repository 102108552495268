import BinIcon from "assets/icons/BinIcon";
import EditIcon from "assets/icons/EditIcon";
import ReplyIcon from "assets/icons/ReplyIcon";
import Button from "components/common/buttons/Button";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PATHS } from "utils/consts";
import { ArrowLeftSvg, MenuSvg } from "utils/icons";
import RemoveMessagePopup from "./RemoveMessagePopup";

interface IProps {
  title: string,
  backTo?: string,
  onClickBack?: () => void,
  editable?: boolean,
  removable?: boolean,
  repliable?: string,
  sendable?: boolean,
  disabled?: boolean,
}

const MessageHeader: React.FC<IProps> = (props) => {
  const { title, backTo, onClickBack, editable, removable, repliable, sendable, disabled } = props;
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(false);

  const goBack = () => {
    onClickBack && onClickBack();
    if (backTo) {
      navigate(backTo as string);
    }
    else navigate(-1);
  };

  const onSend = () => {
    navigate(PATHS.MESSAGES);
  }

  return (
    <>
      <div className="flex justify-between items-center text-lg font-bold text-center text-gray-500 p-4">
        <div className="flex items-center">
          <img src={ArrowLeftSvg} onClick={goBack} />
          <span className="text-base font-medium text-font-primary ml-4">{title}</span>
        </div>
        <div className="flex">
          {editable && <Link to={`${PATHS.MESSAGES}${PATHS.EDIT}`}><EditIcon className="fill-primary" /></Link>}
          {removable && <BinIcon className="fill-primary ml-2" onClick={() => { setOpen(true); }} />}
          {repliable && <Link to={`${PATHS.MESSAGES}${PATHS.REPLY}/${repliable}`}><ReplyIcon className="fill-primary ml-2" /></Link>}
          {sendable && <Button className="py-2" disabled={disabled} onClick={onSend}>Send</Button>}
        </div>
      </div>
      {
        isOpen && <RemoveMessagePopup
          onConfirm={() => { setOpen(false); navigate(PATHS.MESSAGES); }}
          onClose={() => { setOpen(false); }}
        />
      }
    </>
  );
};

export default MessageHeader;