import { Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { PATHS } from "utils/consts";
import AddBank from "./AddBank";
import ConfirmOrder from "./ConfirmOrder";
import CryptoDeposit from "./CryptoDeposit";
import EnterWalletAddress from "./EnterWalletAddress";
import PaymentMethod from "./PaymentMethod";
import BankCard from "./BankCard";
import BankCardAuthentication from "./BankCardAuthentication";
import NewCard from "./NewCard";
import BillingAddress from "./BillingAddress";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const Sell: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { amount } = useSelector((root: RootState) => root.crypto);
  useEffect(() => {
    if (amount == 0) {
      navigate(PATHS.SELL);
    }
  }, [amount]);
  return (
    <>
      <Outlet />
      <Routes location={location}>
        <Route path={PATHS.BANK_DETAIL} element={<AddBank />}></Route>
        <Route path={PATHS.WALLET_ADDRESS} element={<EnterWalletAddress />} />
        <Route path={PATHS.CONFRIM_ORDER} element={<ConfirmOrder />}></Route>
        <Route path={PATHS.PAYMENT_METHOD} element={<PaymentMethod />}></Route>
        <Route path={PATHS.BANKCARD} element={<BankCard />}></Route>
        <Route path={PATHS.BANKCARD_AUTHENTICATION} element={<BankCardAuthentication />}></Route>
        <Route path={PATHS.NEWCARD} element={<NewCard />}></Route>
        <Route path={PATHS.BILLING_ADDRESS} element={<BillingAddress />}></Route>
      </Routes>
    </>
  );
};