interface IProps {
	className?: string;
	width?: number;
	height?: number;
	fill?: string;
	onClick?: () => void;
}

const EyeCloseSvg: React.FC<IProps> = (props) => {
	const { className, width = 24, height = 24, fill = "#7F818A", onClick } = props;
	return (
		<svg
			className={className}
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path fillRule="evenodd" clipRule="evenodd" d="M6.72742 7.82889C7.3179 7.46491 7.9587 7.11267 8.62691 6.81611L9.90077 8.2381C9.16506 8.51518 8.40967 8.90642 7.67195 9.36117C6.22668 10.2521 4.97025 11.3101 4.25205 11.9583C4.24859 11.9614 4.2466 11.9638 4.24553 11.9652C4.24444 11.9667 4.24391 11.9677 4.24365 11.9682L4.24317 11.9696L4.24396 11.9712C4.24456 11.9722 4.24592 11.9743 4.24869 11.9775C4.9266 12.7401 6.15158 14.0218 7.59733 15.1058C9.07297 16.2121 10.6317 17 12 17C13.3034 17 14.7795 16.2851 16.1921 15.261L17.3989 16.6082C15.8603 17.7483 13.9387 18.8 12 18.8C10.026 18.8 8.06981 17.7097 6.51755 16.5459C4.9354 15.3597 3.61951 13.979 2.90333 13.1733C2.23473 12.4211 2.30516 11.2907 3.04606 10.622C3.80534 9.93676 5.15253 8.7997 6.72742 7.82889ZM8.59998 12.4C8.59998 10.9507 9.50675 9.71323 10.7839 9.22393L12.2078 10.8134C12.1398 10.8045 12.0704 10.8 12 10.8C11.1163 10.8 10.4 11.5163 10.4 12.4C10.4 13.2837 11.1163 14 12 14C12.8836 14 13.6 13.2837 13.6 12.4C13.6 12.389 13.5999 12.378 13.5996 12.3671L15.0235 13.9566C14.4588 15.0514 13.3168 15.8 12 15.8C10.1222 15.8 8.59998 14.2778 8.59998 12.4ZM19.7513 11.9775C19.2963 12.4894 18.5949 13.235 17.7456 13.9978L18.9466 15.3385C19.8661 14.5129 20.6163 13.7137 21.0967 13.1733C21.7653 12.4211 21.6948 11.2907 20.9539 10.622C20.1947 9.93676 18.8475 8.7997 17.2726 7.8289C15.7235 6.87403 13.8282 6 12 6C11.5693 6 11.1348 6.04851 10.7021 6.13517L12.1978 7.8048C13.4685 7.86566 14.93 8.49939 16.328 9.36117C17.7733 10.2521 19.0297 11.3101 19.7479 11.9583C19.7514 11.9614 19.7534 11.9638 19.7545 11.9652C19.7555 11.9667 19.7561 11.9677 19.7563 11.9682C19.7568 11.9692 19.7568 11.9697 19.7568 11.9697L19.756 11.9712C19.7554 11.9722 19.7541 11.9743 19.7513 11.9775Z"/>
			<rect x="5" y="5.45001" width="1.8" height="18" transform="rotate(-41.85 5 5.45001)"/>
		</svg>
	);
};

export default EyeCloseSvg;