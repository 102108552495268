import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { RAMPING } from "utils/consts";
import { ArrowDownSvg, InfoSvg } from "utils/icons";
import { getFiat } from "utils/supports";

interface IProps {
  type: RAMPING,
  amount: number,
  crypto: string,
  currency: string,
}

const BuyCryptoSummary: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { type, amount, crypto, currency } = props;

  const [updateIn, setUpdateIn] = useState<number>(10);
  const { quote } = useSelector((state: RootState) => state.quote);
  const { currencies, offRampCurrencies } = useSelector((state: RootState) => state.currency);
  const { cryptos } = useSelector((state: RootState) => state.crypto);
  const { fee } = useSelector((state: RootState) => state.fee);
  const [isMarketLink, setMarketLink] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const getQuotes = () => {
    const ticker = cryptos.find(el => el.shortName === crypto)?.ticker;

    if (ticker) {
      dispatch(AppActions.quote.getQuote({
        side: type,
        quantity: 1,
        crypto: ticker,
        currency: currency,
      }));
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setUpdateIn(updateIn => updateIn > 0 ? updateIn - 1 : 10);
    }, 1000);

    if (updateIn === 0) {
      getQuotes();
    }
  }, [updateIn]);

  useEffect(() => {
    if (crypto && currency && cryptos.length)
      getQuotes();
  }, [crypto, currency, cryptos]);

  const _currency = useMemo(() => {
    if (type)
      return offRampCurrencies.find(el => el.code === currency);
    return currencies.find(el => el.code === currency);
  }, [currency, currencies, offRampCurrencies, type]);

  useEffect(() => {
    if (fee.fee > 0) {
      setMarketLink(true);
    } else {
      setMarketLink(false);
    }
  }, [fee.fee]);

  return (
    <>
      <div className="flex justify-between items-end mb-2">
        <label className="text-base font-medium text-font-primary">Summary</label>
        <label className="text-xs font-medium text-gray-400">Quote updates in {updateIn}s</label>
      </div>
      <div className="bg-feedback-information-tint divide-y p-4 rounded-xl">
        <div className="flex justify-between" onClick={() => { setVisible((prev) => !prev) }}>
          <span className="text-base font-medium text-font-primary mb-2">
            <span className="text-font-subtle">You get</span> <span className="font-bold">
              {
                {
                  [RAMPING.ON]: <>{amount.toFixed(5)} {crypto}</>,
                  [RAMPING.OFF]: <>~{_currency && _currency.symbol}{getFiat(amount, quote, fee.fee, type === RAMPING.ON ? 0 : 1)}</>
                }[type]
              }
            </span> <span className="text-font-subtle">for</span> <span className="font-bold">
              {
                {
                  [RAMPING.ON]: <>{_currency && _currency.symbol}{getFiat(amount, quote, fee.fee, type === RAMPING.ON ? 0 : 1)}</>,
                  [RAMPING.OFF]: <>{amount.toFixed(5)} {crypto}</>
                }[type]
              }
            </span>
          </span>
          <img src={ArrowDownSvg} className={`${visible ? 'transform rotate-180' : ''} `} />
        </div>
        {
          visible &&
          <>
            <div className="mt-4">
              <div className="flex justify-between items-end mt-4">
                <div>
                  <span className="text-sm font-medium text-font-subtle mr-1">
                    {amount.toFixed(5)}
                  </span>
                  <span className="text-sm font-medium text-font-subtle">@ {_currency && _currency.symbol}{(+quote.liquidityQuotes).toFixed(2)}</span>
                </div>
                <span className="text-sm font-medium text-font-primary">{_currency && _currency.symbol}{(amount * quote.liquidityQuotes).toFixed(2)}</span>
              </div>

              <div className="flex justify-between items-end mt-3">
                <div className="flex">
                  <span className="text-sm font-medium text-font-primary mr-1">Processing fee</span>
                </div>
                <div className="flex">
                  <span className="text-xs font-medium text-font-primary mr-1">as low as</span>
                  <span className="text-sm font-medium text-font-primary">{_currency && _currency.symbol}{fee.fee}</span>
                </div>
              </div>
              {
                isMarketLink &&
                <div className="flex mt-1">
                  <img src={InfoSvg} />
                  <div className="">
                    <p className="text-xs font-medium text-font-subtle">To be able to compare the quoted rate against the market rate please follow this <a className="text-primary" href="https://www.coinbase.com/explore" target="_blink">link</a></p>
                  </div>
                </div>
              }
            </div>
          </>
        }
      </div>
    </>
  )
}

export default BuyCryptoSummary;