import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { PATHS } from "utils/consts";
import ConfirmOrder from "./ConfirmOrder";
import ConfirmWalletAddress from "./ConfirmWalletAddress";
import EnterWalletAddress from "./EnterWalletAddress";
import EnterCardInfoCheckout from "./EnterCardInfoCheckout";
import TravelRulesChecking from "./TravelRulesChecking";
import OrderAMLHold from "./OrderAMLHold";
import { CardPayment } from "./CardPayment";

export const Buy: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <Outlet />
      <Routes location={location}>
        <Route path={PATHS.WALLET_ADDRESS} element={<EnterWalletAddress />} />
        <Route path={PATHS.CONFIRM_ADDRESS} element={<ConfirmWalletAddress />} />
        <Route path={PATHS.TRAVEL_RULES_CHECKING} element={<TravelRulesChecking />} />
        <Route path={PATHS.CONFRIM_ORDER} element={<ConfirmOrder />} />
        <Route path={PATHS.CARD_INFO} element={<CardPayment />} />
        <Route path={PATHS.ORDER_AML_HOLD} element={<OrderAMLHold />} />
      </Routes>
    </>
  );
};