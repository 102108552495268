import { createSlice } from '@reduxjs/toolkit';
import { ICurrency } from 'types';

interface IInitialCryptoSlice {
  currencies: ICurrency[],
  offRampCurrencies: ICurrency[],
  gettingCurrencies: boolean,
  gotCurrencies: boolean,
  error: any,
}

const currencySlice = createSlice({
  name: 'quote',
  initialState: {
    currencies: [],
    offRampCurrencies: [],
    gettingCurrencies: false,
    gotCurrencies: false,
    error: '',
  } as IInitialCryptoSlice,
  reducers: {
    getCurrencies(state, action) {
      state.gettingCurrencies = true;
      state.gotCurrencies = false;
    },

    getCurrenciesSuccess(state, action) {
      state.gettingCurrencies = false;
      state.gotCurrencies = true;
      state.currencies = [...action.payload.currencies];
      state.offRampCurrencies = [...action.payload.offRampCurrencies];
    },

    getCurrenciesFailure(state, action) {
      state.gettingCurrencies = false;
      state.gotCurrencies = false;
      state.error = action.payload;
    }
  },
});

export const actions = currencySlice.actions;
export const reducer = currencySlice.reducer;