import Textarea from "components/common/inputs/Textarea";
import MessageHeader from "components/messages/MessageHeader";
import { useParams } from "react-router-dom";
import { messages } from "./MessagesList";

const ReplyMessage: React.FC = () => {
  const { id } = useParams();

  return (
    <>
      <MessageHeader title="Reply" sendable />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">{`Re: ${messages[id as any].title}`}</label>
        <p className="text-sm font-medium text-font-primary mt-2">{messages[id as any].date}</p>
        <Textarea className="mt-8" placeholder="Type your message here" />
        <p className="text-sm font-bold text-font-primary mt-8">Please note</p>
        <p className="text-sm font-medium text-font-primary mt-2">We aim to respond to your message within 2 working days.
          For an immediate response use our Chat service. You can start, pause, and continue a conversation at your convenience.
          If you use the TIFI App you can also continue your conversation with us there.</p>
      </div>
    </>
  );
}

export default ReplyMessage;