import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ACTION_STATUS, AUTHENTICATION_TYPE, SIZE, PATHS, merchantId, UserType, LOGIN_AS, saasUserCorporationId, privacyPolicyLink, termsOfServiceLink } from "utils/consts";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { setSignInStatus, signIn } from "store/slices/userSlice";
import Header from "components/common/header/Header";
import Input from "components/common/inputs/Input";
import PhoneCountryCode from "components/common/inputs/PhoneCountryCode";
import Button from "components/common/buttons/Button";
import PasswordInput from "components/common/inputs/PasswordInput";
import { CancelInCircleSvg } from "utils/icons";
import { AuthenticationInfo } from "types";

interface IProps {
  authType: AUTHENTICATION_TYPE,
  loginAs: LOGIN_AS,
  setAuthenticationType: (type: AUTHENTICATION_TYPE) => void,
  nextStep: (authInfo: AuthenticationInfo) => void,
  setLoginAsType: (type: LOGIN_AS) => void;
}

const EmailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PhoneNoRegExp = /^[0-9]{9,14}$/;

const Login: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [params] = useSearchParams();

  const referralCode = params.get('promoCode') || '';

  const { authType, setAuthenticationType, nextStep, loginAs, setLoginAsType } = props;

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState<string>("+44");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [userType, setUserType] = useState<UserType>(UserType.INDIVIDUAL_CUSTOMER);

  const { errorMessage } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    setError('');
    setEmailAddress('');
    setPhoneNumber('');
  }, [loginAs]);

  useEffect(() => {
    setError('');
    if (emailAddress && !EmailRegExp.test(emailAddress)) {
      setError('Please enter a valid email address.');
    }
  }, [emailAddress]);

  useEffect(() => {
    setError('');
    if (phoneNumber && !PhoneNoRegExp.test(phoneNumber)) {
      setError('Please enter a valid phone number.');
    }
  }, [phoneNumber]);

  useEffect(() => {
    setError('');
    setEmailAddress('');
    setPhoneNumber('');
  }, [authType]);

  const onClickContinue = () => {
    dispatch(signIn({
      email: authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? emailAddress : undefined,
      phone: authType === AUTHENTICATION_TYPE.PHONE_AUTHENTICATION ? `${phoneCode}${phoneNumber}` : undefined,
      password,
      userType: loginAs === LOGIN_AS.INDIVIDUAL_CUSTOMER ? UserType.INDIVIDUAL_CUSTOMER : UserType.BUSINESS_CUSTOMER,
      saasUserCorporationId: saasUserCorporationId,//TODO 
      merchantId: merchantId,//TODO
      next: () => {
        nextStep({
          emailAddress,
          phoneCode,
          phoneNumber,
          merchantId: +merchantId,
          saasUserCorporationId,
          userType,
        });
      }
    }));
  };

  const changeVerificationMethod = (type: AUTHENTICATION_TYPE) => {
    setAuthenticationType(type);
  };

  const onCloseWarning = () => {
    dispatch(setSignInStatus(ACTION_STATUS.NONE));
  };

  const changeLoginAsType = (type: LOGIN_AS, userType: UserType) => {
    setLoginAsType(type);
    setUserType(userType);
  }

  return (
    <>
      <Header title="Log In to WynPay Platform" backTo={PATHS.BUY} />
      <div className="flex flex-col justify-between h-full p-4 mt-4">
        <div className="flex flex-col">
          {
            !!errorMessage &&
            <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={onCloseWarning}>
              <img src={CancelInCircleSvg} />
              <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
            </div>
          }
          {
            {
              [LOGIN_AS.INDIVIDUAL_CUSTOMER]: <h2 className="text-h2 font-bold text-font-primary">Login As <a className="text-feedback-success">Personal Account</a></h2>,
              [LOGIN_AS.BUSINESS_CUSTOMER]: <h2 className="text-h2 font-bold text-font-primary">Login As <a className="text-feedback-warning">Business Account</a></h2>,
            }[loginAs]
          }
          {
            {
              [LOGIN_AS.INDIVIDUAL_CUSTOMER]:
                <span className="text-sm text-primary font-medium" ><br />Switch to <a className="cursor-pointer" onClick={() => { changeLoginAsType(LOGIN_AS.BUSINESS_CUSTOMER, UserType.BUSINESS_CUSTOMER); }}>Business Account Login</a></span>,
              [LOGIN_AS.BUSINESS_CUSTOMER]:
                <span className="text-sm text-primary font-medium" ><br />Switch to <a className="cursor-pointer" onClick={() => { changeLoginAsType(LOGIN_AS.INDIVIDUAL_CUSTOMER, UserType.INDIVIDUAL_CUSTOMER); }}>Personal Account Login</a></span>
            }[loginAs]
          }
          {/* <h2 className="text-h2 font-bold text-font-primary">Log In to BlockDance </h2> */}
          {
            {
              [AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION]: (
                <>
                  <Input
                    className="mt-8 mb-2"
                    label="Email"
                    onChange={(value: string) => { setEmailAddress(value); }}
                    error={error}
                    size={SIZE.md}
                  />
                  {/* <a className="text-sm text-primary font-medium" onClick={() => { changeVerificationMethod(AUTHENTICATION_TYPE.PHONE_AUTHENTICATION); }}>Log In with Phone</a> */}
                </>
              ),
              [AUTHENTICATION_TYPE.PHONE_AUTHENTICATION]: (
                <>
                  <div className="flex mt-8 mb-2">
                    <PhoneCountryCode className="w-60" onChangeCode={(value) => { setPhoneCode(value); }} />
                    <Input
                      className="w-full ml-2"
                      label="Phone Number"
                      onChange={(value: string) => { setPhoneNumber(value); }}
                      error={error}
                      size={SIZE.md}
                    />
                  </div>
                  <a className="text-sm text-primary font-medium" onClick={() => { changeVerificationMethod(AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION); }}>Log In with Email</a>
                </>
              ),
            }[authType]
          }
          <div className="flex mt-8 mb-2">
            <PasswordInput
              className="w-full"
              label="password"
              onChange={(value: string) => { setPassword(value); }}
            />
          </div>
          <a className="text-sm text-primary font-medium" onClick={() => { navigate('/forgotpassword'); }}>Forgot password?</a>

          <Button
            className="w-full mt-8"
            disabled={(authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? !emailAddress : !phoneNumber) || !!error}
            onClick={onClickContinue}
          >
            <>Continue</>
          </Button>
          <div className="flex justify-center items-center mt-8">
            <span className="w-full text-center text-sm font-medium text-font-primary">Don’t have an account? <Link to={`${PATHS.REGISTER}${referralCode.length ? '?promoCode=' + referralCode : ''}`} className="text-primary">Sign up</Link></span>
          </div>
        </div>
        <div className="flex flex-col items-center w-full">
          <div className="h-full">
            <span className="text-center text-xs font-medium text-font-primary mt-2">This site is protected by the WynPay <a className="underline underline-offset-2" href={privacyPolicyLink} target='_blank'>Privacy Policy</a> and <a className="underline underline-offset-2" href={termsOfServiceLink} target='_blank'>Terms of Service</a> apply.</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;