import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { PATHS } from "utils/consts";
import BusinessDetail from "./BusinessDetail";
import BusinessProfile from "./BusinessProfile";
import BusinessAddress from "./BusinessAddress";
import Director from "./Director";
import UploadDocuments from "./UploadDocuments";
import DirectorProfile from "./DirectorProfile";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect } from "react";
import { PlatformContext } from "contexts";
import { AppActions, RootState } from "store";

export const BusinessPage: React.FC = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const { getUser } = useContext(PlatformContext);
  const { businessDetail } = useSelector((root: RootState) => root.business);

  useEffect(() => {
    const user = getUser();
    if (user) {
      dispatch(AppActions.business.getBusinessDetail({
        customerId: user?.customerId,
      }));

      dispatch(AppActions.business.getBusinessMember({
        customerId: user?.customerId,
      }));
    }
  }, []);

  return (
    <>
      <Outlet />
      <Routes location={location}>
        <Route path='/' element={<BusinessProfile />} />
        <Route path={PATHS.BUSINESS_DETAIL} element={<BusinessDetail />} />
        <Route path={PATHS.BUSINESS_ADDRESS} element={<BusinessAddress businessDetail={businessDetail} />} />
        <Route path={PATHS.DIRECTOR} element={<Director />} />
        <Route path={`${PATHS.DIRECTOR}/new}`} element={<DirectorProfile />} />
        <Route path={`${PATHS.DIRECTOR}/:id`} element={<DirectorProfile />} />
        <Route path={PATHS.UPLOAD_DOCUMENTS} element={<UploadDocuments />} />
      </Routes>
    </>
  );
};