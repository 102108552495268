import { createSlice } from '@reduxjs/toolkit';
import { ICard } from 'types';

interface IInitialCardSlice {
  cards: ICard[];
  gettingCards: boolean;
  gotCards: boolean;
  error: any;
}

const cardSlice = createSlice({
  name: 'card',
  initialState: {
    cards: [],
    error: '',
    gettingCards: false,
    gotCards: false,
  } as IInitialCardSlice,
  reducers: {
    getCards(state, action) {
      state.gettingCards = true;
      state.gotCards = false;
    },

    getCardsSuccess(state, action) {
      state.gettingCards = false;
      state.gotCards = true;
      state.cards = [...action.payload];
    },

    getCardsFailure(state, action) {
      state.gettingCards = false;
      state.gotCards = false;
      state.error = action.payload;
    },
  },
});

export const actions = cardSlice.actions;
export const reducer = cardSlice.reducer;
