import Button from "components/common/buttons/Button";

interface IProps {
  onClose: () => void,
  onConfirm: () => void,
}

const RemoveMessagePopup: React.FC<IProps> = (props) => {
  const { onConfirm, onClose } = props;

  return (
    <div className="fixed left-0 top-0 w-full h-screen flex justify-center items-center z-50 bg-font-primary/75 p-8">
      <div className="bg-surface-primary flex flex-col justify-center items-center rounded-xl">
        <span className="text-base font-medium text-font-primary text-center px-5 py-4">Are you sure you want to delete this message?</span>
        <div className="flex w-full">
          <div className="flex items-center justify-center w-full p-4 border-t" onClick={onClose}><p className="text-sm font-medium text-font-primary">Cancel</p></div>
          <div className="flex items-center justify-center w-full p-4 border-t border-l" onClick={onConfirm}><p className="text-sm font-medium text-primary">Confirm</p></div>
        </div>
      </div>
    </div>
  )
}

export default RemoveMessagePopup;