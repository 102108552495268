import {
  createBusinessDocumentSaga,
  createBusinessMemberSaga,
  getBusinessDetailSaga,
  getBusinessDocumentsSaga,
  getBusinessMembersSaga,
  getDocumentTypesSaga,
  getOperatingAddressSaga,
  getRegisteredBusinessAddressSaga,
  updateBusinessAddressSaga,
  updateBusinessDetailSaga,
} from './saga/businessSaga';
import { getCardsSaga } from './saga/cardSaga';
import { getCountrySaga } from './saga/countrySaga';
import { createPaymentRequestSaga } from './saga/createPayment.saga';
import { createPaymentCheckoutRequestSaga } from './saga/createPaymentCheckout.saga';
import { createPayoutCheckoutRequestSaga } from './saga/createPayoutCheckout.saga';
import { checkTravelRulesSaga, getCryptosSaga } from './saga/cryptoSaga';
import { getCurrenciesSaga } from './saga/currencySaga';
import { getFeeSaga } from './saga/feeSaga';
import { openOrderRequestSaga } from './saga/openOrder.saga';
import {
  createOffRampOrderSaga,
  createOrderSaga,
  getOrderSaga,
  getOrdersSaga,
  getUnCompletedOrdersSaga,
  updateOrderSaga,
} from './saga/orderSaga';
import { getQuoteSaga } from './saga/quoteSaga';
import { getSaasUserCorporationSaga } from './saga/saasUserCorporationSaga';
import {
  finishKYCSaga,
  forgotPasswordSaga,
  generateTokenSaga,
  resetPasswordSaga,
  signInResendSaga,
  signInSaga,
  signInVerifySaga,
  signUpResendSaga,
  signUpSaga,
  signUpVerifySaga,
  ssoLoginSaga,
  verifyForgotPasswordSaga,
  verifyUserKycStatusByUuidSaga,
} from './saga/userSaga';
import { validateNewCardSaga } from './saga/validateNewCard.saga';
import {
  businessActions,
  cardActions,
  countryActions,
  cryptoActions,
  currencyActions,
  feeActions,
  orderActions,
  quoteActions,
  saasUserCorporationActions,
} from './slices';
import {
  finishKYC,
  forgotPassword,
  generateToken,
  resetPassword,
  signIn,
  signInResend,
  signInVerify,
  signUp,
  signUpResend,
  signUpVerify,
  ssoLogin,
  verifyForgotPassword,
  verifyUserKycStatusByUuid,
} from './slices/userSlice';

import { all, takeLatest } from 'redux-saga/effects';

function* rootSaga() {
  yield all([
    takeLatest(signUp.type, signUpSaga),
    takeLatest(signUpResend.type, signUpResendSaga),
    takeLatest(signUpVerify.type, signUpVerifySaga),

    takeLatest(finishKYC.type, finishKYCSaga),
    takeLatest(verifyUserKycStatusByUuid.type, verifyUserKycStatusByUuidSaga),

    takeLatest(signIn.type, signInSaga),
    takeLatest(signInResend.type, signInResendSaga),
    takeLatest(signInVerify.type, signInVerifySaga),

    takeLatest(ssoLogin.type, ssoLoginSaga),

    takeLatest(forgotPassword.type, forgotPasswordSaga),
    takeLatest(verifyForgotPassword.type, verifyForgotPasswordSaga),
    takeLatest(resetPassword.type, resetPasswordSaga),
    takeLatest(generateToken.type, generateTokenSaga),

    takeLatest(quoteActions.getQuote.type, getQuoteSaga),

    takeLatest(orderActions.createOrder.type, createOrderSaga),
    takeLatest(orderActions.createOffRampOrder.type, createOffRampOrderSaga),
    takeLatest(orderActions.openOrderRequest.type, openOrderRequestSaga),
    takeLatest(orderActions.updateOrder.type, updateOrderSaga),
    takeLatest(orderActions.getOrders.type, getOrdersSaga),
    takeLatest(orderActions.getOrder, getOrderSaga),
    takeLatest(
      orderActions.createPaymentRequest.type,
      createPaymentRequestSaga
    ),
    takeLatest(
      orderActions.createPaymentCheckout.type,
      createPaymentCheckoutRequestSaga
    ),
    takeLatest(orderActions.getUncompletedOrders.type, getUnCompletedOrdersSaga),
    takeLatest(
      orderActions.createPayoutCheckout.type,
      createPayoutCheckoutRequestSaga
    ),
    takeLatest(orderActions.validateNewCard.type, validateNewCardSaga),

    takeLatest(cryptoActions.getCryptos.type, getCryptosSaga),
    takeLatest(cryptoActions.checkTravelRules.type, checkTravelRulesSaga),

    takeLatest(currencyActions.getCurrencies.type, getCurrenciesSaga),
    takeLatest(feeActions.getFee.type, getFeeSaga),

    takeLatest(countryActions.getCountry.type, getCountrySaga),

    takeLatest(businessActions.getDocumentTypes.type, getDocumentTypesSaga),
    takeLatest(businessActions.getBusinessDetail.type, getBusinessDetailSaga),
    takeLatest(
      businessActions.updateBusinessDetail.type,
      updateBusinessDetailSaga
    ),
    takeLatest(
      businessActions.updateBusinessAddress.type,
      updateBusinessAddressSaga
    ),
    takeLatest(
      businessActions.getRegisteredBusinessAddress.type,
      getRegisteredBusinessAddressSaga
    ),
    takeLatest(
      businessActions.getOperatingAddress.type,
      getOperatingAddressSaga
    ),
    takeLatest(
      businessActions.createBusinessMember.type,
      createBusinessMemberSaga
    ),
    takeLatest(businessActions.getBusinessMember.type, getBusinessMembersSaga),
    takeLatest(
      businessActions.createBusinessDocument.type,
      createBusinessDocumentSaga
    ),
    takeLatest(
      businessActions.getBusinessDocuments.type,
      getBusinessDocumentsSaga
    ),

    takeLatest(
      saasUserCorporationActions.getSaaSUserCorporationRequest.type,
      getSaasUserCorporationSaga
    ),

    takeLatest(cardActions.getCards.type, getCardsSaga),
  ]);
}

export default rootSaga;
