import Header from "components/common/header/Header";
import { HeaderButtonType, flagImages } from "utils/consts";
import SelectButton from "components/common/buttons/SelectButton";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import SelectCountryPopup from "components/common/popups/SelectCountryPopup";
import Input from "components/common/inputs/Input";
import Textarea from "components/common/inputs/Textarea";
import { PlatformContext } from "contexts";
import { CheckGreenSvg } from "utils/icons";
import CancelIcon from "assets/icons/CancelIcon";

const BusinessDetail: React.FC = () => {
  const dispatch = useDispatch();
  const [country, setCountry] = useState<string>('FR');
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const [isValid, setValid] = useState<boolean>(false);
  const { countries } = useSelector((root: RootState) => root.country);
  const { businessDetail, updatedBusinessDetail } = useSelector((root: RootState) => root.business);
  const [businessName, setBusinessName] = useState<string>('');
  const [businessNo, setBusinessNo] = useState<string>('');
  const [registrationDate, setRegistrationDate] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [supplierName, setSupplierName] = useState<string>('');
  const [sideIndustry, setSideIndustry] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [businessDesc, setBusinessDesc] = useState<string>('');
  const { getUser } = useContext(PlatformContext);

  const [openAlert, setOpenAlert] = useState<boolean>(false);

  useEffect(() => {
    dispatch(AppActions.country.getCountry({}));
  }, []);

  useEffect(() => {
    if (businessDetail.businessName) {
      setBusinessName(businessDetail.businessName);
      setBusinessNo(businessDetail.businessNo || '');
      setRegistrationDate((businessDetail.registrationDate || '').split('T')[0]);
      setIndustry(businessDetail.industry || '');
      setSupplierName(businessDetail.supplierName || '');
      setSideIndustry(businessDetail.sideIndustry || '');
      setWebsite(businessDetail.website || '');
      setBusinessDesc(businessDetail.businessDesc || '');
    }
    if (businessName) {
      setOpenAlert(true);
      setTimeout(() => {
        setOpenAlert(false);
      }, 5000);
    }
  }, [businessDetail, updatedBusinessDetail]);

  const onClickUpdate = () => {
    const user = getUser();
    if (user) {
      dispatch(AppActions.business.updateBusinessDetail({
        customerId: user.customerId,
        country,
        businessName,
        businessNo,
        registrationDate,
        industry,
        supplierName,
        sideIndustry,
        website,
        businessDesc,
      }));
    }
  }

  useEffect(() => {
    const valid = (country !== businessDetail.country ||
      businessName !== businessDetail.businessName ||
      businessNo !== businessDetail.businessNo ||
      registrationDate !== (businessDetail.registrationDate || '').split('T')[0] ||
      industry !== businessDetail.industry ||
      supplierName !== businessDetail.supplierName ||
      sideIndustry !== businessDetail.sideIndustry ||
      businessDesc !== businessDetail.businessDesc ||
      website !== businessDetail.website) &&
      !!country &&
      !!businessName &&
      !!businessNo &&
      !!registrationDate &&
      !!industry &&
      !!supplierName &&
      !!website && !!businessDesc;
    setValid(valid);
  }, [businessDetail, country, businessName, businessNo, registrationDate, industry, supplierName, sideIndustry, website, businessDesc]);

  return (
    <>
      <Header title='Business Detail' button={HeaderButtonType.UPDATE} onClickButton={onClickUpdate} isValid={!isValid} />
      {
        openAlert &&
        <div className="px-4 py-2 mx-4 flex items-center justify-between rounded-xl bg-feedback-success-tint">
          <div className="flex items-center">
            <img src={CheckGreenSvg} />
            <p className="text-md font-medium font-bold text-font-primary ml-2">Business detail submitted.</p>
          </div>
          <CancelIcon onClick={() => setOpenAlert(false)} />
        </div>
      }
      <div className="flex flex-col p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Business Detail</label>
        <div className="mt-4 mb-8">
          <SelectButton
            label='Country'
            text={countries.find(el => el.code === country)?.name}
            svgPath={flagImages[country]}
            onClick={() => setOpenCountrySelect(true)}
            flag
          />
          <p className="text-sm font-medium text-font-primary mt-2">Could not find your country?</p>
          <p className="text-sm font-medium text-primary-light">Our service is currently available in selected countries.</p>

          <Input
            className="mt-2"
            value={businessName}
            onChange={value => setBusinessName(value)}
            label="Legal Name"
          />

          <Input
            className="mt-2"
            value={businessNo}
            onChange={value => setBusinessNo(value)}
            label="Company house registeration number"
          />

          <Input
            type="date"
            className="mt-2"
            value={registrationDate}
            onChange={value => setRegistrationDate(value)}
            label="Date of Incorporation"
          />

          <Input
            className="mt-2"
            value={industry}
            onChange={value => setIndustry(value)}
            label="Business Type"
          />

          <Input
            className="mt-2 mb-8"
            value={supplierName}
            onChange={value => setSupplierName(value)}
            label="Trading Name"
          />

          <label className="text-2xl font-bold text-font-primary mt-8">Type of Business</label>
          <Input
            className="mt-2"
            value={sideIndustry}
            onChange={value => setSideIndustry(value)}
            label="Category"
          />

          <Input
            className="mt-2"
            value={website}
            onChange={value => setWebsite(value)}
            label="Website"
          />

          <Textarea
            className="mt-2 h-48"
            value={businessDesc}
            onChange={value => setBusinessDesc(value)}
            label="Description"
          />
        </div>
      </div>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={country}
          onChange={(value) => {
            setCountry(value);
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  );
};

export default BusinessDetail;