import { useContext, useEffect, useRef, useState } from "react";

import Button from "components/common/buttons/Button";
import CodeVerificationInput from "../common/codeVerificationInput/CodeVerificationInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import Header from "components/common/header/Header";
import { ACTION_STATUS, AUTHENTICATION_TYPE, AUTHENTICATION_STATUS, USER_MFA_SETTINGS } from "utils/consts";
import { CancelInCircleSvg } from "utils/icons";
import { AuthenticationInfo, IUser } from "types";
import { setSignInVerifyStatus, setSignUpVerifyStatus, signInResend, signInVerify, signUpResend, signUpVerify } from "store/slices/userSlice";
import { PlatformContext } from "contexts";

interface IProps {
  authType: AUTHENTICATION_TYPE,
  authInfo: AuthenticationInfo,
  authStatus: AUTHENTICATION_STATUS,
  previousStep: () => void,
  nextStep: () => void,
}

const ResendIn = 60;

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<any>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const timer = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(timer);
  }, [delay]);
}

const CodeVerification: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const { saveToken, saveUser } = useContext(PlatformContext);

  const { authType, authInfo, authStatus, previousStep, nextStep } = props;

  const { signInVerifyStatus, signUpVerifyStatus } = useSelector((state: RootState) => state.user);
  const [code, setCode] = useState<string>('');
  const [resendIn, setResendIn] = useState<number>(ResendIn);

  const onClickContinue = () => {
    if (authStatus === AUTHENTICATION_STATUS.SIGNIN) {
      dispatch(signInVerify({
        email: authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? authInfo.emailAddress : undefined,
        phone: authType === AUTHENTICATION_TYPE.PHONE_AUTHENTICATION ? `${authInfo.phoneCode}${authInfo.phoneNumber}` : undefined,
        code,
        saasUserCorporationId: authInfo.saasUserCorporationId,
        merchantId: authInfo.merchantId,
        userType: authInfo.userType,
        next: (token: string, user: IUser) => {
          saveToken(token);
          saveUser(user);
          nextStep();
        }
      }));
    } else if (authStatus === AUTHENTICATION_STATUS.SIGNUP) {
      dispatch(signUpVerify({
        email: authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? authInfo.emailAddress : undefined,
        phone: authType === AUTHENTICATION_TYPE.PHONE_AUTHENTICATION ? `${authInfo.phoneCode}${authInfo.phoneNumber}` : undefined,
        businessName: authInfo.businessName,
        code,
        mfaSetting: authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? USER_MFA_SETTINGS.EMAIL : USER_MFA_SETTINGS.SMS,
        merchantId: authInfo.merchantId,
        userType: authInfo.userType,
        saasUserCorporationId: authInfo.saasUserCorporationId,
        next: () => {
          nextStep();
        }
      }));
    }
  };

  useInterval(() => {
    if (resendIn > 0)
      setResendIn(resendIn - 1);
  }, 1000);

  const resendCode = () => {
    if (authStatus === AUTHENTICATION_STATUS.SIGNIN) {
      dispatch(signInResend({
        email: authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? authInfo.emailAddress : undefined,
        phone: authType === AUTHENTICATION_TYPE.PHONE_AUTHENTICATION ? `${authInfo.phoneCode}${authInfo.phoneNumber}` : undefined,
        saasUserCorporationId: authInfo.saasUserCorporationId,
        merchantId: authInfo.merchantId,
        userType: authInfo.userType,
        next: () => {
          setResendIn(ResendIn);
        }
      }));
    } else if (authStatus === AUTHENTICATION_STATUS.SIGNUP) {
      dispatch(signUpResend({
        email: authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? authInfo.emailAddress : undefined,
        phone: authType === AUTHENTICATION_TYPE.PHONE_AUTHENTICATION ? `${authInfo.phoneCode}${authInfo.phoneNumber}` : undefined,
        saasUserCorporationId: authInfo.saasUserCorporationId,
        merchantId: authInfo.merchantId,
        userType: authInfo.userType,
        next: () => {
          setResendIn(ResendIn);
        }
      }));
    }
  };

  const onCloseWarning = () => {
    dispatch(setSignInVerifyStatus(ACTION_STATUS.NONE));
    dispatch(setSignUpVerifyStatus(ACTION_STATUS.NONE));
  };

  return (
    <>
      <Header
        title={`Verify ${authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? 'Email' : 'Mobile'}`}
        onClickBack={previousStep}
      />
      <div className="flex flex-col h-full p-4 mt-4">
        <div className="flex flex-col">
          {
            (signInVerifyStatus === ACTION_STATUS.FAILURE || signUpVerifyStatus === ACTION_STATUS.FAILURE) &&
            <div className="flex bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={onCloseWarning}>
              <img src={CancelInCircleSvg} />
              <p className="text-sm font-medium text-font-secondary ml-2">Sorry, you have entered an invalid code. Please check and try again.</p>
            </div>
          }
          <h2 className="text-h2 font-bold text-font-primary">{`Verify ${authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? 'Email' : 'Mobile'}`}</h2>
          <span className="text-base font-medium text-font-primary mt-2">
            {
              {
                [AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION]: <>Please enter the verification code sent to your <b>{authInfo.emailAddress}</b>.</>,
                [AUTHENTICATION_TYPE.PHONE_AUTHENTICATION]: <>Please enter the verification code sent to your <b>{authInfo.phoneCode} {authInfo.phoneNumber}</b>.</>,
              }[authType]
            }
          </span>
          <CodeVerificationInput
            className="mt-8 mb-4"
            onChange={(value) => { setCode(value); }}
          />
          <span className="flex text-sm font-medium text-font-primary">
            Didn’t receive it? {
              resendIn === 0
                ? <a className="font-bold text-primary ml-1" onClick={resendCode}>Resend code</a>
                : <p className="text-font-subtle ml-1">Resend code in {resendIn}s</p>
            }
          </span>
          <Button
            className="w-full mt-8"
            disabled={code.length !== 6 || signInVerifyStatus === ACTION_STATUS.PENDING || signUpVerifyStatus === ACTION_STATUS.PENDING}
            onClick={onClickContinue}
          >
            <>Continue</>
          </Button>
        </div>
      </div>
    </>
  );
};

export default CodeVerification;
