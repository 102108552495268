import axios from 'axios';
import { COOKIES } from '../consts';

export const getClient = (baseURL: string, multipart?: boolean) => {
  const instance = axios.create({
    headers: {
      'Content-Type': `${multipart ? 'multipart/form-data' : 'application/json'
        }`,
    },
    baseURL: baseURL,
    timeout: 30000
  });

  instance.interceptors.request.use(
    async function (config) {
      // const cookies = await parseCookies();
      const token = localStorage.getItem(COOKIES.JWT);
      // if (cookies[COOKIES.JWT]) {
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
      }
      return { ...config };
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response: any) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return { ...response };
    },
    function (error: any) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return instance;
};
