interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const CheckBox: React.FC<IProps> = (props) => {
  const { className, error, ...restProps } = props;

  return (
    <div className={className}>
      <input
        type="checkbox"
        className={`w-6 h-6 border  ${!error ? 'border-surface-tertiary' : 'border-feedback-warning'} rounded-lg`}
        {...restProps}
      />
    </div>
  );
};

export default CheckBox;