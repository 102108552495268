import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ACTION_STATUS, SIZE } from "utils/consts";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { forgotPassword, setForgotPasswordStatus } from "store/slices/userSlice";
import Header from "components/common/header/Header";
import Input from "components/common/inputs/Input";
import Button from "components/common/buttons/Button";
import CheckEmail from "components/common/popups/CheckEmail";

const EmailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isOpen, setOpen] = useState<boolean>(false);

  const { forgotPasswordStatus } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    dispatch(setForgotPasswordStatus(ACTION_STATUS.NONE));
  });

  useEffect(() => {
    setError('');
    if (email && !EmailRegExp.test(email)) {
      setError('Please enter a valid email address.');
    }
  }, [email]);

  useEffect(() => {
    setError('');
    setEmail('');
  }, [forgotPasswordStatus]);

  useEffect(() => {
    if (forgotPasswordStatus === ACTION_STATUS.SUCCESS) {
      setOpen(true);
    }
  }, [forgotPasswordStatus]);

  const onClickContinue = (email: string) => {
    dispatch(forgotPassword({
      email,
    }));
  };

  return (
    <>
      <Header title="Reset Password" />
      <div className="p-4 mt-4">
        <h2 className="text-h2 font-bold text-font-primary">Reset Password</h2>
        <span className="text-base font-medium text-font-primary mt-2">
          Enter your registered email address so that we could email you instructions to reset your password.
        </span>
        <Input
          className="mt-8 mb-2"
          label="Email"
          type="email"
          value={email}
          onChange={(value: string) => { setEmail(value); }}
          error={error}
          size={SIZE.md}
        />

        <Button
          className="w-full mt-8"
          disabled={!email || !!error}
          onClick={() => { onClickContinue(email); }}
        >
          Continue
        </Button>

        {
          isOpen && <CheckEmail
            email={email}
            onClose={() => { setOpen(false); }}
            onResend={(email) => { onClickContinue(email); }}
          />
        }
      </div>
    </>
  );
};

export default ForgotPassword;