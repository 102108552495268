import { useNavigate } from "react-router-dom";
import Button from "components/common/buttons/Button";
import Header from "components/common/header/Header";
import { useContext, useEffect, useMemo, useState } from "react";
import { PlatformContext } from "contexts";
import Input from "components/common/inputs/Input";
import { flagImages, PATHS } from "utils/consts";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import SelectCountryPopup from "components/common/popups/SelectCountryPopup";
import SelectButton from "components/common/buttons/SelectButton";
import { counterPartyApi } from "api/counterparty.api";
import { CancelInCircleSvg } from "utils/icons";

const BillingAddress: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { saveAddressDetail, getAddressDetail, getUser, currency } = useContext(PlatformContext);
  const { accountDetails } = useSelector((root: RootState) => root.user);
  const { countries } = useSelector((root: RootState) => root.country);
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const [addressDetail, setAddressDetail] = useState({
    country: 'AF',
    streetLine1: '',
    city: '',
    postcode: '',    
  });
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const _addressDetail = getAddressDetail() ?? { country: 'AF' };
    setAddressDetail({ ..._addressDetail });
  }, []);

  const onChangeAddressDetail = (key: string, value: string) => {
    const temp: any = { ...addressDetail };
    temp[key] = value;
    setAddressDetail({ ...temp });
  }

  const isValid = useMemo(() => {
    return addressDetail.streetLine1 && addressDetail.city && addressDetail.country && addressDetail.postcode;
  }, [addressDetail]);

  const onClickContinue = () => {
    saveAddressDetail(addressDetail);
    dispatch(AppActions.user.setAccountDetails({
      ...accountDetails,
      ...addressDetail,
    }));

    const { bankCountry, ...restBankInfo } = accountDetails;
    const { accountNumber: accountNo, ...restAccountDetail } = restBankInfo;
    const tempDetails = { ...restAccountDetail };
    if (accountNo)
      tempDetails.accountNo = accountNo;

    const user = getUser();
    counterPartyApi.getCounterPartyId({
      userId: user?.id,
      issuerCountry: bankCountry,
      currency,
      individualName: {
        firstName: user?.firstName,
        lastName: user?.lastName,
      },
      ...tempDetails,
      address: {
        streetLine1: addressDetail.streetLine1,
        city: addressDetail.city,
        country: addressDetail.country,
        postcode: addressDetail.postcode,
      }
    }).then((data) => {
      const { httpsStatus, accountId, message } = data;
      if (!!accountId || httpsStatus === 422)
        navigate(`${PATHS.SELL}${PATHS.CONFRIM_ORDER}?payment=bank`);
      else
        setErrorMessage(message);
    }).catch((error) => {
      console.log(error);
      setErrorMessage('It has not been possible to place your Order at this time. Please try again or contact the Customer Support');
    });
  };

  const onCloseWarning = () => {
    setErrorMessage('');
  };
  
  return (
    <>
      <Header
        title={`Add Billing Address`}
        backTo={`${PATHS.SELL}`}
      />
      <div className="flex flex-col h-full p-4 mt-4 justify-between">
        <div className="flex flex-col">
          {
            !!errorMessage &&
            <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={onCloseWarning}>
              <img src={CancelInCircleSvg} />
              <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
            </div>
          }
          <h1 className="text-2xl font-bold text-font-primary mt-4 mb-2">Enter Billing Address</h1>
          <div>
            <Input
              className="mb-2"
              label={'Street Line 1'}
              value={addressDetail.streetLine1 || ''}
              onChange={value => onChangeAddressDetail('streetLine1', value)}
            />
            <Input
              className="mb-2"
              label={'City'}
              value={addressDetail.city || ''}
              onChange={value => onChangeAddressDetail('city', value)}
            />
            <Input
              className="mb-2"
              label={'Postcode / Zipcode'}
              value={addressDetail.postcode || ''}
              onChange={value => onChangeAddressDetail('postcode', value)}
            />
            <SelectButton
              className="mb-2"
              label="Country"
              text={countries.find(el => el.code === addressDetail.country)?.name}
              svgPath={flagImages[addressDetail.country]}
              onClick={() => setOpenCountrySelect(true)}
              flag
            />
          </div>
        </div>
        <div
          className="w-full px-2"
        >
          <Button
            className="w-full"
            disabled={!isValid}
            onClick={onClickContinue}
          >
            Continue
          </Button>
        </div>
      </div>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={addressDetail.country}
          onChange={(value) => {
            onChangeAddressDetail('country', value);
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  );
};

export default BillingAddress;