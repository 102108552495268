import React from "react";
import { SIZE } from "../../../utils/consts";

interface IProps {
  label?: string,
  placeholder?: string,
  value?: string,
  className?: string,
  size?: SIZE,
  error?: string,
  onChange?: (value: string) => void,
}

const Textarea: React.FC<IProps> = (props) => {
  const { className, placeholder, value, label, onChange, size = SIZE.lg, error } = props;
  return (
    <div className={`flex flex-col px-4 pt-2 pb-2 bg-surface-secondary rounded-xl border ${!error ? 'border-surface-secondary' : 'border-feedback-warning'} focus-within:border-primary duration-200 ${className}`}>
      <label
        className={`left-4 text-xs font-medium text-font-subtle`}
      >
        {label}
      </label>
      <textarea
        className={`flex-grow block px-0 py-0 bg-transparent w-full text-base font-medium text-font-primary border-none focus:ring-0 focus:outline-none focus:border-none`}
        defaultValue={value}
        placeholder={placeholder}
        onChange={(e: any) => { onChange && onChange(e.target.value); }}
      />
      {!!error && <label
        className='text-sm font-medium text-feedback-warning duration-200'
      >{error}</label>}
    </div>
  );
};

export default Textarea;