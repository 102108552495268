interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

const EditIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M8.24487 5.09521C9.46096 3.7054 11.75 4.56549 11.75 6.41222V8.82065C12.3857 8.88083 13.2749 8.99113 14.1871 9.18937C15.2471 9.41974 16.4783 9.79697 17.35 10.4508C20.4851 12.8021 21.1234 16.7043 21.2439 18.7366C21.2872 19.4682 20.8469 20.0453 20.2612 20.2658C19.6948 20.479 19.0184 20.3548 18.5489 19.8664C17.6803 18.9629 16.4177 17.7774 15.2576 17.1211C14.1052 16.4692 12.7164 16.1055 11.75 15.917V17.0893C11.75 18.936 9.46096 19.7961 8.24488 18.4063L3.57364 13.0678C2.91385 12.3137 2.91385 11.1878 3.57364 10.4338L8.24487 5.09521ZM9.75003 6.41222L5.0788 11.7508L9.75003 17.0893L9.75003 15.4106C9.75003 14.4789 10.5673 13.6923 11.5612 13.8521C12.5574 14.0123 14.5525 14.4244 16.2424 15.3804C17.2655 15.9591 18.2893 16.8243 19.1146 17.6083C18.8309 15.777 18.0701 13.4908 16.15 12.0508C15.6396 11.668 14.7656 11.3618 13.7624 11.1437C12.7917 10.9328 11.8301 10.8307 11.2459 10.7842C10.421 10.7186 9.75003 10.0336 9.75003 9.17291V6.41222Z" fill="#0A33FF" />
    </svg>
  );
};

export default EditIcon;