import { createBusinessApi, createBusinessDocumentApi, getBusinessAddressApi, getBusinessDetailApi, getBusinessMembersApi, getDocumentTypesApi, getDocumentsApi, updateBusinessAddressApi, updateBusinessDetailApi } from 'api';
import { AppActions } from 'store';

import { call, put } from 'redux-saga/effects';

export function* getDocumentTypesSaga() {
  try {
    const { documentTypes } = yield call(
      async () => await getDocumentTypesApi()
    );
    yield put(AppActions.business.getDocumentTypesSuccess(documentTypes));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.getDocumentTypesFailure(error.response.data));
  }
}

export function* getBusinessDetailSaga(action: any) {
  try {
    const { business } = yield call(getBusinessDetailApi, action.payload);
    yield put(AppActions.business.getBusinessDetailSuccess(business));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.getBusinessDetailFailure(error.response.data));
  }
}

export function* updateBusinessDetailSaga(action: any) {
  try {
    const { business } = yield call(updateBusinessDetailApi, action.payload);
    yield put(AppActions.business.updateBusinessDetailSuccess(business));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.updateBusinessDetailFailure(error.response.data));
  }
}

export function* updateBusinessAddressSaga(action: any) {
  try {
    const { business } = yield call(updateBusinessAddressApi, action.payload);
    yield put(AppActions.business.updateBusinessAddressSuccess(business));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.updateBusinessAddressFailure(error.response.data));
  }
}

export function* getRegisteredBusinessAddressSaga(action: any) {
  try {
    const { address } = yield call(getBusinessAddressApi, action.payload);
    yield put(AppActions.business.getRegisteredBusinessAddressSuccess(address));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.getRegisteredBusinessAddressFailure(error.response.data));
  }
}

export function* getOperatingAddressSaga(action: any) {
  try {
    const { address } = yield call(getBusinessAddressApi, action.payload);
    yield put(AppActions.business.getOperatingAddressSuccess(address));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.getOperatingAddressFailure(error.response.data));
  }
}

export function* getBusinessMembersSaga(action: any) {
  try {
    const { businessMembers } = yield call(getBusinessMembersApi, action.payload);
    yield put(AppActions.business.getBusinessMemberSuccess(businessMembers));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.getBusinessMemberFailure(error.response.data));
  }
}

export function* createBusinessMemberSaga(action: any) {
  try {
    yield call(createBusinessApi, action.payload);
    yield put(AppActions.business.createBusinessMemberSuccess({}));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.createBusinessMemberFailure(error.response.data));
  }
}

export function* createBusinessDocumentSaga(action: any) {
  try {
    yield call(createBusinessDocumentApi, action.payload);
    yield put(AppActions.business.createBusinessDocumentSuccess({}));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.createBusinessDocumentFailure(error.response.data));
  }
}

export function* getBusinessDocumentsSaga(action: any) {
  try {
    const { documents } = yield call(getDocumentsApi, action.payload);
    yield put(AppActions.business.getBusinessDocumentsSuccess(documents));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.business.getBusinessDocumentsFailure(error.response.data));
  }
}
