import Input from "components/common/inputs/Input";
import Textarea from "components/common/inputs/Textarea";
import MessageHeader from "components/messages/MessageHeader";

const NewMessage: React.FC = () => {

  return (
    <>
      <MessageHeader title="New Message" sendable />
      <div className="p-4 mt-4">
        <Input className="" type="text" label="Title" placeholder="Type your message here" />
        <Textarea className="mt-8" placeholder="Type your message here" />
        <p className="text-sm font-bold text-font-primary mt-8">Please note</p>
        <p className="text-sm font-medium text-font-primary mt-2">We aim to respond to your message within 2 working days.
          For an immediate response use our Chat service. You can start, pause, and continue a conversation at your convenience.
          If you use the TIFI App you can also continue your conversation with us there.</p>
      </div>
    </>
  );
}

export default NewMessage;