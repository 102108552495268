interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

const CancelIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#FEFEFE"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M7.36398 5.63604L5.94976 7.05025L10.8995 12L5.94976 16.9497L7.36398 18.364L12.3137 13.4142L17.2635 18.364L18.6777 16.9497L13.7279 12L18.6777 7.05025L17.2635 5.63604L12.3137 10.5858L7.36398 5.63604Z" />
    </svg>
  );
};

export default CancelIcon;