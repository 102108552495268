import MessageHeader from "components/messages/MessageHeader";
import { Link, useParams } from "react-router-dom";
import { PATHS } from "utils/consts";
import { messages } from "./MessagesList";

const Message: React.FC = () => {
  const { id } = useParams();

  return (
    <>
      <MessageHeader title={messages[id as any].title} removable repliable={id} />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">{messages[id as any].title}</label>
        <p className="text-sm font-medium text-font-primary mt-2">{messages[id as any].date}</p>
        <p className="text-sm font-medium text-font-primary mt-8">{messages[id as any].content}</p>
      </div>
    </>
  );
}

export default Message;