import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { PlatformContext } from "contexts";
import { PATHS } from "utils/consts";

interface IProps {
  children: JSX.Element,
}

const AuthRoute: React.FC<IProps> = (props) => {
  const { children } = props;
  const { getLoggedIn } = useContext(PlatformContext);
  // const navigate = useNavigate();

  const [params] = useSearchParams();

  const referralCode = params.get('promoCode') || '';

  useEffect(() => {
    if (!getLoggedIn())
      window.location.href = PATHS.LOGIN + `${referralCode.length ? '?promoCode=' + referralCode : ''}`;
      // navigate(PATHS.LOGIN);
  }, []);

  return (
    <>{children}</>
  );
};

export default AuthRoute;