import { customerServerApiClient } from 'utils/apiClient';
import { saasUserCorporationId } from 'utils/consts';

export const currencyApi = {
  async getCurrencies() {
    const res = await customerServerApiClient.get('currency/supported', {
      params: {
        saasUserCorporationId: saasUserCorporationId
      }
    });
    const resBody = res.data;
    return resBody;
  },
};
