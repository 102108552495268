import { useMemo, useState } from "react";

import { SearchInCircleSvg, SearchSvg } from "utils/icons";
import { IDocumentType } from "types";
import { useSelector } from "react-redux";
import { RootState } from "store";

interface IProps {
  className?: string,
  documentType?: IDocumentType,
  documentTypes: IDocumentType[],
  onChange?: (documentType: IDocumentType) => void,
}

export const SelectDocumentType: React.FC<IProps> = (props) => {
  const { className, documentType, documentTypes, onChange } = props;

  const [filter, setFilter] = useState<string>("");

  const onChangeFilter = (e: any) => {
    setFilter(e.target.value);
  };

  const onChangeDocumentType = (documentType: IDocumentType) => {
    onChange && onChange(documentType);
  };

  const _documentTypes = useMemo(() => {
    return documentTypes.filter(documentType => ((documentType.description && documentType.description.includes(filter))));
  }, [filter])

  return (
    <>
      <div className={`relative flex w-full ${className}`}>
        <img className="absolute left-4 top-4" src={SearchSvg} />
        <input
          className="block p-4 pl-12 w-full text-base font-medium text-font-primary bg-surface-secondary rounded-xl border border-surface-secondary outline-none focus:ring-primary focus:border-primary duration-200"
          onChange={onChangeFilter}
        />
      </div>
      <div className="overflow-auto flex-grow">
        {
          _documentTypes.length ? _documentTypes.map((el: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center mt-4 p-2 ${el.code === documentType?.code && 'bg-feedback-information-tint'}`}
                onClick={() => { onChangeDocumentType(el); }}
              >
                <div className="text-base font-medium text-font-primary">{el.description}</div>
              </div>
            );
          })
            : filter && (
              <div className="flex flex-col justify-content items-center p-12">
                <img src={SearchInCircleSvg} />
                <span className="text-base font-medium text-font-primary mt-4">We do not support this currency yet!</span>
              </div>
            )
        }
      </div>
    </>
  );
};