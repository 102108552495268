import { useNavigate } from "react-router-dom";
import Button from "components/common/buttons/Button";
import Header from "components/common/header/Header";
import { useContext, useMemo, useState } from "react";
import { PlatformContext } from "contexts";
import Input from "components/common/inputs/Input";
import { flagImages, PATHS } from "utils/consts";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { getBankInputFields } from "utils/supports";
import SelectCountryPopup from "components/common/popups/SelectCountryPopup";
import SelectButton from "components/common/buttons/SelectButton";
import { counterPartyApi } from "api/counterparty.api";
import { CancelInCircleSvg } from "utils/icons";

const AddBank: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currency, getAddressDetail, getUser } = useContext(PlatformContext);
  const [accountDetail, setAccountDetail] = useState<any>({});
  const { accountDetails } = useSelector((root: RootState) => root.user);
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const { countries } = useSelector((root: RootState) => root.country);
  const [country, setCountry] = useState<string>(countries[0]?.code);
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeAccountDetail = (key: string, value: string) => {
    const temp = { ...accountDetail };
    temp[key] = value;
    setAccountDetail({ ...temp });
  };

  const isValid = useMemo(() => {
    return getBankInputFields(currency).every(({ _, name }: any) => !!accountDetail[name]);
  }, [accountDetail, currency]);

  const onClickContinue = () => {
    const addressDetail = getAddressDetail() ?? {};
    dispatch(AppActions.user.setAccountDetails({
      bankCountry: country,
      ...accountDetail,
      ...addressDetail,
    }));
    if (getAddressDetail()) {
      const user = getUser();
      const { accountNumber: accountNo, ...restAccountDetail } = accountDetail;
      const tempDetails = { ...restAccountDetail };
      if (accountNo)
        tempDetails.accountNo = accountNo;
      counterPartyApi.getCounterPartyId({
        userId: user?.id,
        issuerCountry: country,
        currency,
        individualName: {
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
        ...tempDetails,
        address: {
          streetLine1: addressDetail.streetLine1,
          city: addressDetail.city,
          country: addressDetail.country,
          postcode: addressDetail.postcode,
        }
      }).then((data) => {
        const { httpsStatus, accountId, message } = data;
        if (!!accountId || httpsStatus === 422)
          navigate(`${PATHS.SELL}${PATHS.CONFRIM_ORDER}?payment=bank`);
        else
          setErrorMessage(message);
      }).catch((error) => {
        console.log(error);
        setErrorMessage('It has not been possible to place your Order at this time. Please try again or contact the Customer Support');
      });
    }
    else navigate(`${PATHS.SELL}${PATHS.BILLING_ADDRESS}`);
  };

  const onCloseWarning = () => {
    setErrorMessage('');
  };

  return (
    <>
      <Header
        title={`Add ${currency} Bank Account`}
        backTo={`${PATHS.SELL}`}
      />
      <div className="flex flex-col h-full p-4 mt-4 justify-between">
        <div className="flex flex-col">
          {
            !!errorMessage &&
            <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={onCloseWarning}>
              <img src={CancelInCircleSvg} />
              <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
            </div>
          }
          <h1 className="text-2xl font-bold text-font-primary mb-4">Enter {currency} Bank Account Detail</h1>
          <div>
            <SelectButton
              className="mb-2"
              label="Bank Country"
              text={countries.find(el => el.code === country)?.name}
              svgPath={flagImages[country]}
              onClick={() => setOpenCountrySelect(true)}
              flag
            />
            {
              currency && getBankInputFields(currency).map(({ label, name }: any) => (
                <Input
                  key={name}
                  className="mb-2"
                  label={label}
                  defaultValue={accountDetails[label] || ''}
                  onChange={value => onChangeAccountDetail(name, value)}
                />
              ))
            }
          </div>
        </div>
        <div
          className="w-full px-2"
        >
          <Button
            className="w-full"
            disabled={!isValid}
            onClick={onClickContinue}
          >
            Continue
          </Button>
        </div>
      </div>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={country}
          onChange={(value) => {
            setCountry(value);
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  );
};

export default AddBank;