import Button from "components/common/buttons/Button";
import SelectButton from "components/common/buttons/SelectButton";
import Header from "components/common/header/Header";
import Input from "components/common/inputs/Input";
import SelectCountryPopup from "components/common/popups/SelectCountryPopup";
import { PlatformContext } from "contexts";
import { useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { flagImages } from "utils/consts";

const DefaultAddress: React.FC = (props) => {
  const { saveAddressDetail, getAddressDetail } = useContext(PlatformContext);
  const { countries } = useSelector((root: RootState) => root.country);
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const [addressDetail, setAddressDetail] = useState({
    country: 'AF',
    streetLine1: '',
    city: '',
    postcode: '',  
  });

  useEffect(() => {
    const _addressDetail = getAddressDetail() ?? { country: 'AF' };
    setAddressDetail({ ..._addressDetail });
  }, []);

  const onChangeAddressDetail = (key: string, value: string) => {
    const temp: any = { ...addressDetail };
    temp[key] = value;
    setAddressDetail({ ...temp });
  }

  const isValid = useMemo(() => {
    return addressDetail.streetLine1 && addressDetail.city && addressDetail.country && addressDetail.postcode;
  }, [addressDetail]);

  const onClickSave = () => {
    saveAddressDetail(addressDetail);
  };

  return (
    <>
      <Header title="Default Address" />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Default Address</label>
        <Input
          className="mb-2"
          label={'Street Line 1'}
          value={addressDetail.streetLine1 || ''}
          onChange={value => onChangeAddressDetail('streetLine1', value)}
        />
        <Input
          className="mb-2"
          label={'City'}
          value={addressDetail.city || ''}
          onChange={value => onChangeAddressDetail('city', value)}
        />
        <Input
          className="mb-2"
          label={'Postcode / Zipcode'}
          value={addressDetail.postcode || ''}
          onChange={value => onChangeAddressDetail('postcode', value)}
        />
        <SelectButton
          className="mb-2"
          label="Country"
          text={countries.find(el => el.code === addressDetail.country)?.name}
          svgPath={flagImages[addressDetail.country]}
          onClick={() => setOpenCountrySelect(true)}
          flag
        />
      </div>
      <div
        className="w-full px-2"
      >
        <Button
          className="w-full"
          disabled={!isValid}
          onClick={onClickSave}
        >
          Save
        </Button>
      </div>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={addressDetail.country}
          onChange={(value) => {
            onChangeAddressDetail('country', value);
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  );
};

export default DefaultAddress;