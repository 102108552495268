import { ICountry } from 'types';

import bnbSvg from 'assets/cryptos/bnb.svg';
import btcSvg from 'assets/cryptos/btc.svg';
import ethSvg from 'assets/cryptos/eth.svg';
import usdcSvg from 'assets/cryptos/usdc.svg';
import usdtSvg from 'assets/cryptos/usdt.svg';
import solSvg from 'assets/cryptos/sol.svg';

function importAll(r: any) {
  let images = {} as any;
  r.keys().map((item: any) => { images[item.replace('assets/countries/', '').replace('./', '').replace('.svg', '').split(' ')[0].toUpperCase()] = r(item); });
  return images;
}

export const flagImages = importAll(require.context('../assets/countries', false, /\.svg$/));

export const saasUserCorporationId = 1; //TODO

export const merchantId = process.env.REACT_APP_MERCHANT_ID || 1733; //STG
// export const merchantId = 1733; //STG
//export const merchantId = 1; //PROD

export const flags = [Object.values(flagImages)];

export enum UserType {
  INDIVIDUAL_CUSTOMER = 0,
  BUSINESS_CUSTOMER = 1,
  SAAS_USER = 2,
  HOUSE_USER = 3,
  MERCHANT = 4,
}

const countryCurrency: {
  [key: string]: string,
} = {
  'AFN': 'AF',
  'ALL': 'AL',
  'ARS': 'AR',
  'AMD': 'AM',
  'ANG': 'CW',
  'AOA': 'AO',
  'AWG': 'AW',
  'DZD': 'DZ',
  'BHD': 'BH',
  'AUD': 'AU',
  'BDT': 'BD',
  'BYN': 'BY',
  'AZN': 'AZ',
  'BRL': 'BR',
  'BOB': 'BO',
  'BBD': 'BB',
  'BMD': 'BM',
  'BAM': 'BA',
  'BZD': 'BZ',
  'BND': 'BN',
  'BSD': 'BS',
  'BTN': 'BT',
  'GBP': 'GB',
  'BWP': 'BW',
  'BGN': 'BG',
  'KHR': 'KH',
  'CVE': 'CV',
  'BIF': 'BI',
  'CAD': 'CA',
  'CLP': 'CL',
  'COP': 'CO',
  'KMF': 'KM',
  'CNY': 'CN',
  'CDF': 'CD',
  'CRC': 'CR',
  'DJF': 'DJ',
  'EGP': 'EG',
  'HRK': 'HR',
  'CZK': 'CZ',
  'DOP': 'DO',
  'ERN': 'ER',
  'DKK': 'DK',
  'EEK': 'EE',
  'ETB': 'ET',
  'EUR': 'EU',
  'GEL': 'GE',
  'GHS': 'GH',
  'GTQ': 'GT',
  'GNF': 'GN',
  'HNL': 'HN',
  'HKD': 'HK',
  'ISK': 'IS',
  'HUF': 'HU',
  'INR': 'IN',
  'IDR': 'ID',
  'JMD': 'JM',
  'IRR': 'IR',
  'IQD': 'IQ',
  'JPY': 'JP',
  'JOD': 'JO',
  'KZT': 'KZ',
  'KES': 'KE',
  'ILS': 'IL',
  'KWD': 'KW',
  'LVL': 'LV',
  'LBP': 'LB',
  'LYD': 'LY',
  'LTL': 'LT',
  'MOP': 'MO',
  'MKD': 'MK',
  'MGA': 'MG',
  'MYR': 'MY',
  'MXN': 'MX',
  'MUR': 'MU',
  'MDL': 'MD',
  'MAD': 'MA',
  'MZN': 'MZ',
  'MMK': 'MM',
  'NPR': 'NP',
  'TWD': 'TW',
  'NZD': 'NZ',
  'NIO': 'NI',
  'NAD': 'NA',
  'NGN': 'NG',
  'NOK': 'NO',
  'OMR': 'OM',
  'PHP': 'PH',
  'PKR': 'PK',
  'PYG': 'PY',
  'QAR': 'QA',
  'PAB': 'PA',
  'PEN': 'PE',
  'RON': 'RO',
  'PLN': 'PL',
  'RUB': 'RU',
  'RSD': 'RS',
  'RWF': 'RW',
  'SAR': 'SA',
  'SGD': 'SG',
  'ZAR': 'ZA',
  'SOS': 'SO',
  'KRW': 'KR',
  'LKR': 'LK',
  'SDG': 'SD',
  'SEK': 'SE',
  'THB': 'TH',
  'CHF': 'CH',
  'SYP': 'SY',
  'TZS': 'TZ',
  'TOP': 'TO',
  'TTD': 'TT',
  'TND': 'TN',
  'TRY': 'TR',
  'USD': 'US',
  'UGX': 'UG',
  'UAH': 'UA',
  'AED': 'AE',
  'UYU': 'UY',
  'UZS': 'UZ',
  'VND': 'VN',
  'VEF': 'VE',
  'XOF': 'XO',
  'YER': 'YE',
  'ZMK': 'ZM',
  'ZWL': 'ZW',
  'FJD': 'FJ',
  'FKP': 'FK',
  'GIP': 'GI',
  'GYD': 'GY',
  'KGS': 'KG',
  'KYD': 'KY',
  'LAK': 'LA',
  'LRD': 'LR',
  'MNT': 'MN',
  'MRU': 'MR',
  'MVR': 'MV',
  'MWK': 'MW',
  'PGK': 'PG',
  'SBD': 'SB',
  'SCR': 'SC',
  'SHP': 'SH',
  'SLE': 'SL',
  'SLL': 'SL',
  'SRD': 'SR',
  'STN': 'ST',
  'SVC': 'SV',
  'SZL': 'SZ',
  'TJS': 'TJ',
  'TMT': 'TM',
  'VUV': 'VU',
  'XAF': 'CF',
  'XCD': 'AG',
  'XPF': 'PF',
  'ZMW': 'ZM',
};

export const getCurrencySvg = (currency: string) => {
  return flagImages[countryCurrency[currency]];
}

export const cryptoSvgs: {
  [key: string]: any;
} = {
  BTC: btcSvg,
  ETH: ethSvg,
  BNB: bnbSvg,
  USDT: usdtSvg,
  USDC: usdcSvg,
  SOL: solSvg,
};

export const countries: ICountry[] = [
  {
    name: 'United Kingdom',
    code: 'GB',
    phoneCode: '+44',
    language: 'English',
    flag: flagImages['GB'],
  },
  {
    name: 'Finland',
    code: 'FI',
    phoneCode: '+358',
    flag: flagImages['FI'],
  },
  {
    name: 'France',
    code: 'FR',
    phoneCode: '+33',
    flag: flagImages['FR'],
  },
  {
    name: 'Ireland',
    code: 'IE',
    phoneCode: '+353',
    flag: flagImages['IE'],
  },
  {
    name: 'United States',
    code: 'US',
    phoneCode: '+1',
    language: 'English (US)',
    flag: flagImages['US'],
  },
];

export enum CURRENCY_TYPE {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO',
}

export enum RAMPING {
  ON = 'buy',
  OFF = 'sell',
}

export enum ACTION_STATUS {
  NONE = 'NONE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum SIZE {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export enum VERIFICATION_METHOD {
  EMAIL_VERIFICATION = 'EMAIL_VERIFICATION',
  PHONE_VERIFICATION = 'PHONE_VERIFICATION',
}

export enum TO_AFTER_VERIFICATION {
  TO_LOGIN = '1',
  TO_REGISTER = '2',
}

export enum BUY_SELL {
  BUY = 'buy',
  SELL = 'sell',
}

export const COOKIES = {
  JWT: 'token',
  USER_ID: 'userId',
  LANGUAGE: 'language',
  CURRENCY: 'currency',
  CRYPTO: 'crypto',
  USER: 'user',
  EXPIRATION_TIME: 'expirationTime',
  ADDRESS: 'address',
};

export enum AUTHENTICATION_TYPE {
  EMAIL_AUTHENTICATION = 'EMAIL_AUTHENTICATION',
  PHONE_AUTHENTICATION = 'PHONE_AUTHENTICATION',
}

export enum REGISTRATION_TYPE {
  INDIVIDUAL_CUSTOMER_REGISTRATION = 'INDIVIDUAL_CUSTOMER_REGISTRATION',
  BUSINESS_CUSTOMER_REGISTRATION = 'BUSINESS_CUSTOMER_REGISTRATION',
}

export enum LOGIN_AS {
  INDIVIDUAL_CUSTOMER = 'INDIVIDUAL_CUSTOMER',
  BUSINESS_CUSTOMER = 'BUSINESS_CUSTOMER',
}

export enum AUTHENTICATION_STATUS {
  SIGNIN = 'SIGNIN',
  SIGNUP = 'SIGNUP',
}

export enum VERIFF_DISPLAY {
  CHECK = 'CHECK',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  SESSION = 'SESSION',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  APPROVED = 'APPROVED',
  CONGRATS = 'CONGRATS',
  FAILURE = 'FAILURE',
}

export enum USER_KYC_STATUS {
  NOT_TAKEN = 0,
  APPROVED = 1,
  PENDING_BY_ID = 2,
  PENDING_BY_AML = 3,
  REJECTED = 4,
}

export enum USER_MFA_SETTINGS {
  SMS,
  EMAIL,
  AUTHENTICATOR,
}

export enum ORDER_STATUS {
  CREATED = 'None',
  AML_START = 'Pending',
  AML_HOLD = 'AML Hold',
  AML_REJECT = 'AML Reject',
  WAITING_FOR_DEPOSIT = 'Waiting for Deposit',
  PROCESSING = 'Processing',
  DEPOSIT_DECLINED = 'Deposit Declined',
  CANCEL_ORDER = 'Cancel Order',
  FULLFILLED = 'Fullfilled',
  DONE = 'Done',
}

export const orderStatus: ORDER_STATUS[] = [
  ORDER_STATUS.CREATED,
  ORDER_STATUS.AML_START,
  ORDER_STATUS.AML_HOLD,
  ORDER_STATUS.AML_REJECT,
  ORDER_STATUS.WAITING_FOR_DEPOSIT,
  ORDER_STATUS.PROCESSING,
  ORDER_STATUS.DEPOSIT_DECLINED,
  ORDER_STATUS.CANCEL_ORDER,
  ORDER_STATUS.FULLFILLED,
  ORDER_STATUS.DONE,
];

export const PATHS = {
  BANK_DETAIL: '/bank-detail',
  BANK: '/bank',
  BANKCARD: '/bankcard',
  BANKCARD_AUTHENTICATION: '/authentication',
  BILLING_ADDRESS: '/billing-address',
  NEWCARD: '/newcard',
  FORGOTPASSWORD: '/forgotpassword',
  HISTORY: '/transaction',
  BUY: '/buy',
  SELL: '/sell',
  MESSAGES: '/messages',
  LOGIN: '/login',
  DEFAULT_CURRENCY: '/default-currency',
  DEFAULT_ADDRESS: '/default-address',
  LANGUAGE: '/language',
  REGISTER: '/register',
  PAYMENT_METHOD: '/payment-method',
  RESETPASSWORD: '/resetpassword',
  VERIFF: '/veriff',
  KYC: '/kyc',
  SETTINGS: '/settings',
  HOME: '/',
  MESSAGE: '/:id',
  EDIT: '/edit',
  DELETE: '/delete',
  REPLY: '/reply',
  COOKIE_PREFERENCE: '/cookie',
  WALLET_ADDRESS: '/wallet-address',
  CONFIRM_ADDRESS: '/confirm-address',
  TRAVEL_RULES_CHECKING: '/checking-travel-rules',
  CONFRIM_ORDER: '/confirm-order',
  CARD_INFO: '/card-info',
  ORDER_AML_HOLD: '/order-aml-hold',
  ORDER_STATUS: '/order-status',
  DEPOSIT: '/deposit',
  BUSINESS: '/business',
  DIRECTOR: '/director',
  UPLOAD_DOCUMENTS: '/upload-documents',
  BUSINESS_DETAIL: '/business-detail',
  BUSINESS_ADDRESS: '/business-address',
};

export enum ACQUIRERS {
  NONE,
  NUVEI,
  CHECKOUT,
}

export const ibanCurrencies = ['AED', 'CHF', 'CZK', 'BGN', 'HUF', 'NOK', 'PLN', 'RON', 'SEK', 'TRY', 'HRK', 'DKK', 'EUR'];

export const bankAccountRules: {
  [key: string]: any;
} = {
  GBP: [
    {
      label: 'Sort Code',
      name: 'sortCode',
    }, {
      label: 'Account Number',
      name: 'accountNumber',
    },
  ],
  USD: [
    {
      label: 'Routing Number',
      name: 'routingNumber',
    }, {
      label: 'Account Number',
      name: 'accountNumber',
    },
  ],
  AUD: [
    {
      label: 'Account Number',
      name: 'accountNumber',
    }, {
      label: 'BSB Code',
      name: 'bsbCode',
    },
  ],
  INR: [
    {
      label: 'Account Number',
      name: 'accountNumber',
    }, {
      label: 'IFSC',
      name: 'ifsc',
    },
  ],
  MXN: [
    {
      label: 'Clabe',
      name: 'clabe',
    }, {
      label: 'BIC',
      name: 'bic',
    },
  ],
  IBAN_COUNTRY: [
    {
      label: 'IBAN',
      name: 'iban',
    }, {
      label: 'BIC',
      name: 'bic',
    },
  ],
  OTHER: [
    {
      label: 'Account Number',
      name: 'accountNumber',
    }, {
      label: 'BIC',
      name: 'bic',
    },
  ]
};

export enum HeaderButtonType {
  MENU,
  UPDATE,
  SUBMIT,
}

export enum BusinessMemberType {
  DIRECTOR,
  SHAREHOLDER,
  DIRECTOR_SHAREHOLDER,
}

export const BusinessMemberTypeName = {
  [BusinessMemberType.DIRECTOR]: 'Director',
  [BusinessMemberType.SHAREHOLDER]: 'Shareholder',
  [BusinessMemberType.DIRECTOR_SHAREHOLDER]: 'Director & Shareholder',
};

export const cookiePolicyLink = 'https://www.wynpay.io/privacy.html';
export const privacyPolicyLink = 'https://www.wynpay.io/privacy.html';
export const termsOfServiceLink =
  'https://www.wynpay.io/terms-and-conditions.html';
