interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

const WarningIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="6"
      height="19"
      viewBox="0 0 6 19"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M4.45212 0H1.55079C0.953522 0 0.489438 0.520143 0.557255 1.11355L1.91109 12.9596C1.96881 13.4647 2.39627 13.8461 2.90462 13.8461H3.09819C3.60654 13.8461 4.034 13.4647 4.09173 12.9596L5.44565 1.11355C5.51347 0.520148 5.04938 0 4.45212 0Z" />
      <circle cx="3.00146" cy="17.2197" r="1.78021" />
    </svg>
  );
};

export default WarningIcon;