import { saasUserCorporationApi } from 'api';
import { call, put } from 'redux-saga/effects';
import { AppActions } from 'store';

export function* getSaasUserCorporationSaga(action: any) {
  try {
    const { ...data } = yield call(
      async () => await saasUserCorporationApi.getSaasUserCorporation(action.payload)
    );
    yield put(AppActions.saasUserCorporation.getSaaSUserCorporationSuccess(data));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.saasUserCorporation.getSaaSUserCorporationSuccess(error.response.data));
  }
}