import UploadDocumentsIcon from 'assets/icons/UploadDocumentsIcon';
import React, { useState, useRef } from 'react';
import { ArrowDownSvg } from 'utils/icons';

type UploadFileComponentProps = {
  type: string;
  getFile: (type: string, file: any) => void;
};

export const UploadFileComponent: React.FC<UploadFileComponentProps> = (props) => {
  const { type, getFile } = props;

  const [fileName, setFileName] = useState<string>();
  const [dragActive, setDragActive] = useState<boolean>(false);
  const fileInputRef = useRef<any>(null);

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dragTransfer.files && e.dataTransfer.files[0]) {

    }
  };

  const handleChange = (e: any) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFileName(e.target.files[0].name);
      getFile(type, e.target.files[0]);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return <div
    className='flex flex-col items-center justify-center p-8 rounded-xl border border-2 border-dashed mt-4'
    onDragOver={handleDrag}
    onDrop={handleDrop}
  >
    <UploadDocumentsIcon className='w-12 h-12 [&>path]:stroke-font-subtle' />
    <button className="flex px-4 py-2 border border-2 border-dark rounded-full mt-8" onClick={handleClick}>
      <span className="text-base text-font-primary font-medium">
        {fileName ? fileName : 'Choose File'}</span>
      <img className="left-1" src={ArrowDownSvg} />
    </button>
    <p className="text-sm font-medium text-font-subtle text-center mt-2">Upload your incorporation document in JPG, PNG or PDF format.</p>

    <input
      ref={fileInputRef}
      accept=".pdf,.jpg,.png"
      type='file'
      style={{ display: 'none' }}
      onChange={handleChange}
    />
  </div>
}