import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IUser, IBusinessMemeber } from 'types';

import {
  ACQUIRERS,
  ACTION_STATUS,
  USER_KYC_STATUS,
} from 'utils/consts';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    cryptoCurrency: 'USDT',
    walletAddress: '',
    email: '',
    kycVeriffUuid: '',
    currentUser: {} as IUser | IBusinessMemeber,

    userKycStatus: { kycStatus: USER_KYC_STATUS.NOT_TAKEN },
    accountDetails: {} as any,

    verifyUserKycStatus: ACTION_STATUS.NONE,
    verifyUserKycStatusByUuidStatus: ACTION_STATUS.NONE,
    verifyStatus: ACTION_STATUS.NONE,
    forgotPasswordStatus: ACTION_STATUS.NONE,
    verifyForgotPasswordStatus: ACTION_STATUS.NONE,

    signUpStatus: ACTION_STATUS.NONE,
    signUpResendStatus: ACTION_STATUS.NONE,
    signUpVerifyStatus: ACTION_STATUS.NONE,

    signInStatus: ACTION_STATUS.NONE,
    signInResendStatus: ACTION_STATUS.NONE,
    signInVerifyStatus: ACTION_STATUS.NONE,

    generateTokenStatus: ACTION_STATUS.NONE,
    resetPasswordStatus: ACTION_STATUS.NONE,
    acquirer: ACQUIRERS.CHECKOUT,
    signInErrorMessage: '',
    errorMessage: '',
  },
  reducers: {
    setWalletAddress(state, action) {
      state.walletAddress = action.payload;
    },

    setCryptoCurrency(state, action) {
      state.cryptoCurrency = action.payload;
    },

    setKycVeriffUuid(state, action) {
      state.kycVeriffUuid = action.payload;
    },

    setAccountDetails(state, action) {
      state.accountDetails = {...action.payload};
    },

    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },

    // Finish KYC
    finishKYCRequest(state, action) {},
    finishKYC(state, action) {},
    finishKYCSuccess(state, action) {},
    finishKYCFailure(state) {},

    // SSO Login
    ssoLogin(state, action) {},

    signUp(state, action) {
      state.signUpStatus = ACTION_STATUS.PENDING;
    },
    signUpSuccess(state) {
      state.signUpStatus = ACTION_STATUS.SUCCESS;
    },
    signUpFailure(state, action) {
      state.signUpStatus = ACTION_STATUS.FAILURE;
      state.errorMessage = action.payload.message;
    },
    setSignUpStatus(state, action) {
      state.signUpStatus = action.payload;
    },

    signUpResend(state, action) {
      state.signUpResendStatus = ACTION_STATUS.PENDING;
    },
    signUpResendSuccess(state) {
      state.signUpResendStatus = ACTION_STATUS.SUCCESS;
    },
    signUpResendFailure(state, action) {
      state.signUpResendStatus = ACTION_STATUS.FAILURE;
    },
    setSignUpResendStatus(state, action) {
      state.signUpResendStatus = action.payload;
    },

    signUpVerify(state, action) {
      state.signUpVerifyStatus = ACTION_STATUS.PENDING;
    },
    signUpVerifySuccess(state) {
      state.signUpVerifyStatus = ACTION_STATUS.SUCCESS;
    },
    signUpVerifyFailure(state, action) {
      state.signUpVerifyStatus = ACTION_STATUS.FAILURE;
    },
    setSignUpVerifyStatus(state, action) {
      state.signUpVerifyStatus = action.payload;
    },

    signIn(state, action) {
      state.signInStatus = ACTION_STATUS.PENDING;
      state.errorMessage = '';
    },
    signInSuccess(state) {
      state.signInStatus = ACTION_STATUS.SUCCESS;
    },
    signInFailure(state, action) {
      state.signInStatus = ACTION_STATUS.FAILURE;
      state.errorMessage = action.payload.message;
    },
    setSignInStatus(state, action) {
      state.signInStatus = action.payload;
      state.errorMessage = '';
    },

    signInResend(state, action) {
      state.signInResendStatus = ACTION_STATUS.PENDING;
    },
    signInResendSuccess(state) {
      state.signInResendStatus = ACTION_STATUS.SUCCESS;
    },
    signInResendFailure(state, action) {
      state.signInResendStatus = ACTION_STATUS.FAILURE;
    },
    setSignInResendStatus(state, action) {
      state.signInResendStatus = action.payload;
    },

    signInVerify(state, action) {
      state.signInVerifyStatus = ACTION_STATUS.PENDING;
    },
    signInVerifySuccess(state) {
      state.signInVerifyStatus = ACTION_STATUS.SUCCESS;
    },
    signInVerifyFailure(state, action) {
      state.signInVerifyStatus = ACTION_STATUS.FAILURE;
    },
    setSignInVerifyStatus(state, action) {
      state.signInVerifyStatus = action.payload;
    },

    forgotPassword(
      state,
      action: PayloadAction<{ email: string; }>
    ) {
      state.forgotPasswordStatus = ACTION_STATUS.PENDING;
    },
    forgotPasswordSuccess(state) {
      state.forgotPasswordStatus = ACTION_STATUS.SUCCESS;
    },
    forgotPasswordFailure(state) {
      state.forgotPasswordStatus = ACTION_STATUS.FAILURE;
    },
    setForgotPasswordStatus(state, action) {
      state.forgotPasswordStatus = action.payload;
    },

    verifyForgotPassword(state, action: PayloadAction<{ token: string; next?: () => void; error?: () => void; }>) {
      state.verifyForgotPasswordStatus = ACTION_STATUS.PENDING;
    },
    verifyForgotPasswordSuccess(state, action) {
      state.verifyForgotPasswordStatus = ACTION_STATUS.SUCCESS;
    },
    verifyForgotPasswordFailure(state) {
      state.verifyForgotPasswordStatus = ACTION_STATUS.FAILURE;
    },

    resetPassword(
      state,
      action: PayloadAction<{ password: string; token:string; next?: () => void; error?: () => void; }>
    ) {
      state.resetPasswordStatus = ACTION_STATUS.PENDING;
    },
    resetPasswordSuccess(state) {
      state.resetPasswordStatus = ACTION_STATUS.SUCCESS;
    },
    resetPasswordFailure(state) {
      state.resetPasswordStatus = ACTION_STATUS.FAILURE;
    },

    generateToken(state, action: PayloadAction<{ emailAddress: string }>) {
      state.generateTokenStatus = ACTION_STATUS.PENDING;
    },
    generateTokenSuccess(state) {
      state.generateTokenStatus = ACTION_STATUS.SUCCESS;
    },
    generateTokenFailure(state) {
      state.generateTokenStatus = ACTION_STATUS.FAILURE;
    },

    //TODO
    verifyUserKycStatusByUuid(
      state,
      action: PayloadAction<{ veriffUuid: string }>
    ) {
      state.verifyUserKycStatusByUuidStatus = ACTION_STATUS.PENDING;
    },
    verifyUserKycStatusByUuidSuccess(state, action) {
      state.verifyUserKycStatusByUuidStatus = ACTION_STATUS.SUCCESS;
    },
    verifyUserKycStatusByUuidFailure(state) {
      state.verifyUserKycStatusByUuidStatus = ACTION_STATUS.FAILURE;
    },
    setVerifyUserKycStatusByUuidStatus(state, action) {
      state.verifyUserKycStatusByUuidStatus = action.payload;
    },


    veriffSession(state) {
      state.resetPasswordStatus = ACTION_STATUS.PENDING;
    },
    veriffSessionSuccess(state) {
      state.resetPasswordStatus = ACTION_STATUS.SUCCESS;
    },
    veriffSessionFailure(state) {
      state.resetPasswordStatus = ACTION_STATUS.FAILURE;
    },
  },
});

export const {
  setCryptoCurrency,
  setKycVeriffUuid,
  finishKYC,
  finishKYCSuccess,
  finishKYCFailure,

  signUp,
  signUpSuccess,
  signUpFailure,
  setSignUpStatus,

  signUpResend,
  signUpResendSuccess,
  signUpResendFailure,
  setSignUpResendStatus,

  signUpVerify,
  signUpVerifySuccess,
  signUpVerifyFailure,
  setSignUpVerifyStatus,

  signIn,
  signInSuccess,
  signInFailure,
  setSignInStatus,

  signInResend,
  signInResendSuccess,
  signInResendFailure,
  setSignInResendStatus,

  signInVerify,
  signInVerifySuccess,
  signInVerifyFailure,
  setSignInVerifyStatus,

  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  verifyForgotPassword,
  verifyForgotPasswordSuccess,
  verifyForgotPasswordFailure,
  setForgotPasswordStatus,

  resetPassword,
  resetPasswordSuccess,
  resetPasswordFailure,
  generateToken,
  generateTokenSuccess,
  generateTokenFailure,

  verifyUserKycStatusByUuid,
  verifyUserKycStatusByUuidSuccess,
  verifyUserKycStatusByUuidFailure,
  setVerifyUserKycStatusByUuidStatus,

  veriffSession,
  veriffSessionSuccess,
  veriffSessionFailure,

  setCurrentUser,

  ssoLogin,
} = userSlice.actions;

export const userReducer = userSlice.reducer;

export const actions = userSlice.actions;
export const reducer = userSlice.reducer;
