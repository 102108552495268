import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { validate } from "crypto-address-validator-ts";

import WalletAddressInput from "components/common/inputs/WalletAddressInput";
import Button from "components/common/buttons/Button";
import Header from "components/common/header/Header";
import { AppActions, RootState } from "store";
import { PlatformContext } from "contexts";
import { getFiat } from "utils/supports";
import { PATHS } from "utils/consts";
import PendingIcon from "assets/icons/PendingIcon";
import { CancelInCircleSvg } from "utils/icons";
import { useTimedNotification } from "hooks";

const EnterWalletAddress: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { crypto, currency, getLoggedIn } = useContext(PlatformContext);
  const { addInformationRequired } = useSelector((root: RootState) => root.order);

  const { amount } = useSelector((state: RootState) => state.crypto);
  const { quote } = useSelector((state: RootState) => state.quote);
  const { fee, included } = useSelector((state: RootState) => state.fee);
  const { creatingOrder: isLoading } = useSelector((state: RootState) => state.order);
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<string>('');

  const onChangeWalletAddress = (value: string) => {
    setWalletAddress(value);
  }

  useEffect(() => {
    if (!getLoggedIn()) {
      navigate(`${PATHS.LOGIN}`);
    }
  }, [getLoggedIn]);

  const {
    isShowing: isNotificiation,
    show: showNotification,
    close: closeNotification,
  } = useTimedNotification(5000);

  useEffect(() => {
    const valid = validate(walletAddress, crypto.toLowerCase(), {
      networkType: '',
      chainType: '',
    });

    if (!valid && walletAddress) {
      setError('Wallet Address is not correct.');
    } else {
      setError('');
    }
  }, [walletAddress]);

  useEffect(() => {
    if (addInformationRequired) {
      navigate(`${PATHS.BUY}${PATHS.CONFIRM_ADDRESS}`);
    }
  }, [addInformationRequired]);

  const onClickContinue = () => {
    dispatch(AppActions.user.setWalletAddress(walletAddress));

    dispatch(AppActions.order.createOrder({
      customerWalletAddress: walletAddress,
      type: 0, // ON RAMP
      currency: currency,
      fiatAmount: getFiat(amount, quote, fee.fee, 0),
      crypto: crypto,
      cryptoAmount: amount,
      liquidityQuote: quote.liquidityQuotes,
      liquidityProvider: quote.liquidityProvider,
      processingFee: fee.fee,
      includeFee: +included,
      next: () => navigate(`${PATHS.BUY}${PATHS.CARD_INFO}`),
      travelRuleConfirm: () => navigate(`${PATHS.BUY}${PATHS.CONFIRM_ADDRESS}`),
      pending: () => {
        navigate(`${PATHS.BUY}${PATHS.ORDER_AML_HOLD}`);
      },
      error: (msg: string) => {
        setErrorMessage(msg);
        showNotification();
      }
    }))
  }

  return (
    <>
      {isLoading ?
        <div className="w-[100%] h-[100%] flex items-center justify-center">
          <div className={`w-36 h-36 z-20 animate-spin-slow`} >
            <PendingIcon className='fill-font-secondary w-36 h-36' />
          </div>
        </div> :
        <>
          <Header title={`Enter ${crypto} Wallet Address`} backTo={PATHS.BUY} onClickBack={() => { dispatch(AppActions.order.initOrderStatus({})) }} />
          <div className="flex flex-col h-full justify-between p-4 mt-4">
            <div className="flex flex-col">
              {
                isNotificiation &&
                <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={() => { dispatch(AppActions.order.initOrderStatus({})) }}>
                  <img src={CancelInCircleSvg} />
                  <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
                </div>
              }
              <label className="text-2xl font-bold text-font-primary">Enter {crypto} Wallet Address</label>
              <div className="mt-4">
                <WalletAddressInput
                  label={`${crypto} Wallet Address`}
                  crypto={crypto}
                  onChange={onChangeWalletAddress}
                  error={error}
                />
              </div>
            </div>
            <div
              className="w-full px-2"
            >
              {/* <Link to={`${PATHS.BUY}${PATHS.CONFIRM_ADDRESS}`}> */}
              <Button
                className="w-full"
                disabled={!!error || !walletAddress}
                onClick={onClickContinue}
              >
                Continue
              </Button>
              {/* </Link> */}
              <div className="bottem-6 flex flex-col items-center pr-4 pl-4 w-full">
                <p className="text-center text-xs font-medium text-font-primary mt-2">By continuing you confirm that you own and have control of this wallet.</p>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
};

export default EnterWalletAddress;