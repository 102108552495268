import { createSlice } from '@reduxjs/toolkit';
import { IFee } from 'types';

interface IInitialFeeSlice {
  fee: IFee;
  gettingFee: boolean;
  gotFee: boolean;
  included: boolean;
  error: any;
}

const feeSlice = createSlice({
  name: 'fee',
  initialState: {
    fee: {
      fee: 0,
    },
    error: '',
  } as IInitialFeeSlice,
  reducers: {
    getFee(state, action) {
      state.gettingFee = true;
      state.gotFee = false;
    },

    getFeeSuccess(state, action) {
      state.gettingFee = false;
      state.gotFee = true;
      state.fee = action.payload;
    },

    getFeeFailure(state, action) {
      state.gettingFee = false;
      state.gotFee = false;
      state.error = action.payload;
    },

    setIncluded(state, action) {
      state.included = action.payload;
    },
  },
});

export const actions = feeSlice.actions;
export const reducer = feeSlice.reducer;
