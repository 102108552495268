import { useState } from "react";
import EyeCloseSvg from "assets/icons/EyeCloseSvg";
import EyeOpenSvg from "assets/icons/EyeOpenSvg";
import { SIZE } from "utils/consts";
import Input from "./Input";

interface IProps {
	label?: string,
	className?: string,
	onChange?: (value: string) => void,
	error?: string,
}

const PasswordInput: React.FC<IProps> = (props) => {
	const { label, className, onChange, error } = props;
	const [isOpen, setOpen] = useState<boolean>(true);

	return (
		<div className={`${className} relative`}>
			<Input
				className={`font-xl`}
				label={label}
				type={isOpen ? "password" : "text"}
				size={SIZE.md}
				onChange={onChange}
				error={error}
			/>
			{
				isOpen
					? <EyeOpenSvg className="absolute right-4 top-5" onClick={() => { setOpen(false); }} />
					: <EyeCloseSvg className="absolute right-4 top-5" onClick={() => { setOpen(true); }} />
			}
		</div>
	);
};

export default PasswordInput;