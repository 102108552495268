import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Frames, CardNumber, ExpiryDate, Cvv } from 'components/checkout-frames';
import Header from "components/common/header/Header";
import Input from 'components/common/inputs/InputForCheckout';
import Button from "components/common/buttons/Button";

import { AppActions, RootState } from "store";

import { PATHS } from 'utils/consts';
import { CancelInCircleSvg } from 'utils/icons';
import { useContext, useState } from "react";
import { PlatformContext } from "contexts";


const NewCard: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickContinue = () => {
    navigate(`${PATHS.SELL}${PATHS.BILLING_ADDRESS}`);
  }

  const { currency } = useContext(PlatformContext);
  const { error: errorMessage } = useSelector((state: RootState) => state.order);
  const [cardholder, setCardholder] = useState({
    name: '',
    phone: '',
    billingAddress: ''
  });

  const onChangeCardHolderInfo = (event: any) => {
    setCardholder({ [event.target.name]: event.target.value, ...cardholder })
  }



  return (
    <>
      <Header
        title="Enter Card Details"
      />
      {!!errorMessage &&
        <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={() => { dispatch(AppActions.order.initOrderStatus({})); navigate(PATHS.SELL + PATHS.BANKCARD) }}>
          <img src={CancelInCircleSvg} />
          <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage.message}</p>
        </div>}
      {/* <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Add a New Card</label>
        <div className="mt-4">
          <Input
            label="Card Number"
          />
          <div className="flex mt-2">
            <Input
              className="mr-2"
              label="Expiry Date (MM/YY)"
            />
            <Input
              label="CVC / Card Security Code"
            />
          </div>
        </div>
        <div className="flex justify-between items-end mt-8 mb-2">
          <label className="text-base font-medium text-font-primary">Card Billing Address</label>
          <label className="text-sm font-medium text-primary">Update</label>
        </div>
        <div className="bg-feedback-information-tint rounded-lg p-4">
          <span className="text-sm font-medium text-font-subtle">Name</span>
          <p className="text-sm font-medium text-font-primary">
            1 Chapel Hill Heswall
            Bournemouth
            BH11AA
            United Kingdom
          </p>
        </div>
        <div
          className="absolute w-full px-2 left-0 bottom-2"
        >
          <Button
            className="w-full"
            onClick={onClickContinue}
          >
            <>Continue</>
          </Button>
        </div>
      </div> */}
      <Frames
        config={{
          name: cardholder.name,
          cardholder: {
            name: cardholder.name,
            billingAddress: { addressLine1: cardholder.billingAddress },
          },
          debug: true,
          publicKey: process.env.REACT_APP_CHECKOUT_PUB_KEY!,
          modes: ['cvv_optional'],
          acceptedPaymentMethods: [
            'Visa',
            'Maestro',
            'Mastercard',
            'American Express',
            'Diners Club',
            'Discover',
            'JCB',
            'Mada',
          ],
          localization: {
            cardNumberPlaceholder: 'Card number',
            expiryMonthPlaceholder: 'MM',
            expiryYearPlaceholder: 'YY',
            cvvPlaceholder: 'CVV',
          },
          style: {
            base: {
              fontSize: '14px',
            },
          },
        }}
        ready={() => { }}
        frameActivated={(e) => { }}
        frameFocus={(e) => { }}
        frameBlur={(e) => { }}
        frameValidationChanged={(e) => { }}
        paymentMethodChanged={(e) => {
          console.log('EEEE', e);
        }}
        cardValidationChanged={(e) => { }}
        cardSubmitted={() => { }}
        cardTokenized={(e) => {
          dispatch(AppActions.order.validateNewCard({
            token: e.token,
            cardNumber: `${e.bin}******${e.last4}`,
            cardType: `${e.card_type}`,
            cardScheme: `${e.scheme}`,
            currency: currency,
            successUrl: window.location.host + PATHS.SELL + PATHS.CONFRIM_ORDER,
            failureUrl: window.location.host + PATHS.SELL + PATHS.CONFRIM_ORDER,
            next: (redirectUrl: string) => {
              if (redirectUrl) window.location.replace(redirectUrl);
              else navigate(`${PATHS.SELL}${PATHS.CONFRIM_ORDER}`);
            }
          }))
        }}
        cardTokenizationFailed={(e) => { alert("Tokenization Failed") }}
        cardBinChanged={(e) => { }}
      >
        <div className='checkout-form'>
          <div className='m-2'>
            <label>Card Number</label>
            <CardNumber />
          </div>
          <Input label='Card Holder Name' name='name' placeholder='Card Holder Name' onChange={onChangeCardHolderInfo}></Input>
          <Input label='Billing Address' name='billingAddress' placeholder='Billing Address' onChange={onChangeCardHolderInfo}></Input>
          <div className="date-and-code mt-2 m-2">
            <div className='mr-2'>
              <label>Expiry Date (MM/YY)</label>
              <ExpiryDate />
            </div>
            <div>
              <label>Card Security Code</label>
              <Cvv />
            </div>
          </div>
          <div className='w-[100%] flex items-center justify-center'>
            <button
              id="pay-button"
              onClick={() => {
                Frames.submitCard();
              }}
              className='m-2 w-[95%]'
            >
              Add New Card
            </button>
          </div>
        </div>
      </Frames>
    </>
  );
}

export default NewCard;