import Header from "components/common/header/Header";
import ClockIcon from "assets/icons/ClockIcon";
import BusinessDetailIcon from "assets/icons/BusinessDetailIcon";
import { Link } from "react-router-dom";
import { PATHS } from "utils/consts";
import BusinessAddressIcon from "assets/icons/BusinessAddressIcon";
import DirectorIcon from "assets/icons/DirectorIcon";
import UploadDocumentsIcon from "assets/icons/UploadDocumentsIcon";
import { ArrowRightSvg } from "utils/icons";
import WarningIcon from "assets/icons/WarningIcon";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { useContext, useEffect } from "react";
import { PlatformContext } from "contexts";

const list = [
  {
    Icon: <BusinessDetailIcon />,
    title: 'Business Detail',
    path: `${PATHS.BUSINESS}${PATHS.BUSINESS_DETAIL}`,
    status: 'PENDING',
  },
  {
    Icon: <BusinessAddressIcon />,
    title: 'Business Address',
    path: `${PATHS.BUSINESS}${PATHS.BUSINESS_ADDRESS}`,
    status: 'PENDING',
  },
  {
    Icon: <DirectorIcon />,
    title: 'Director/Shareholders',
    path: `${PATHS.BUSINESS}${PATHS.DIRECTOR}`,
    status: 'PENDING',
  },
  {
    Icon: <UploadDocumentsIcon />,
    title: 'Upload Documents',
    path: `${PATHS.BUSINESS}${PATHS.UPLOAD_DOCUMENTS}`,
    status: 'PENDING',
  }
];

const BusinessProfile: React.FC = () => {
  const dispatch = useDispatch();
  const { businessDetail, documents, businessMembers } = useSelector((root: RootState) => root.business);
  const { user, getUser } = useContext(PlatformContext);

  useEffect(() => {
    const user = getUser();
    if (user) {
      dispatch(AppActions.business.getBusinessDocuments({
        customerId: user.customerId,
      }));
    }
  }, []);

  return (
    <>
      <Header title='Business Profile' />
      <div className="flex flex-col p-4 mt-4">
        {
          businessDetail.status &&
          <div className="flex bg-feedback-information-tint p-2 rounded-lg items-center">
            <ClockIcon className="min-w-[24px]" />
            <div className="ml-4">
              <p className="text-xl font-bold text-font-primary">Please finish your business profile and upload relevant documents for review.</p>
              <p className="text-sm font-medium text-font-primary mt-2">You have to finish this by {new Date(new Date((user && user.createdAt) as string).getTime() + 30 * 24 * 3600 * 1000).toDateString()} otherwise your account will be deactivated.</p>
            </div>
          </div>
        }
        <div className="flex flex-col mt-8">
          <label className="text-2xl font-bold text-font-primary">Business Profile</label>
          {/* <div className="flex items-center mt-2">
            <div className='flex justify-center items-center w-9 h-9 rounded-full bg-feedback-attention mr-2'>
              <WarningIcon />
            </div>
            <p className="text-md font-medium text-feedback-attention">Pending submission</p>
          </div> */}
          <ul className="mt-8">
            {
              list.map((el, index) => {
                return (
                  <li
                    key={el.title}
                    className="mb-2"
                  >
                    <Link
                      className="flex justify-between items-center bg-surface-secondary rounded-lg p-5 w-full"
                      to={`${el.path}`}
                    >
                      <div className="flex items-center mt-2">
                        {el.Icon}
                        <span className="text-base font-medium text-font-primary ml-4">{el.title}</span>
                        {
                          el.status === 'PENDING' &&
                          <>
                            {
                              {
                                0: <>
                                  {
                                    businessDetail.businessNo ?
                                      <span className="text-sm font-medium text-font-subtle ml-4"> Pending Review</span> :
                                      <div className="flex justify-center items-center text-sm font-medium w-5 h-5 text-center text-surface-primary bg-feedback-warning ml-4 rounded-full">7</div>
                                  }
                                </>,
                                1: <>
                                  {
                                    businessDetail.businessAddress ?
                                      <span className="text-sm font-medium text-font-subtle ml-4"> Pending Review</span> :
                                      <div className="flex justify-center items-center text-sm font-medium w-5 h-5 text-center text-surface-primary bg-feedback-warning ml-4 rounded-full">2</div>
                                  }
                                </>,
                                2: <>
                                  {
                                    !!businessMembers.length ?
                                      <span className="text-sm font-medium text-font-subtle ml-4"> Pending Review</span> :
                                      <div className="flex justify-center items-center text-sm font-medium w-5 h-5 text-center text-surface-primary bg-feedback-warning ml-4 rounded-full">1</div>
                                  }
                                </>,
                                3: <>
                                  {
                                    !!documents.length ?
                                      <span className="text-sm font-medium text-font-subtle ml-4"> Pending Review</span> :
                                      <div className="flex justify-center items-center text-sm font-medium w-5 h-5 text-center text-surface-primary bg-feedback-warning ml-4 rounded-full">1</div>
                                  }
                                </>,
                              }[index]
                            }
                          </>
                        }
                      </div>
                      <img src={ArrowRightSvg} />
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </div>

      </div >
    </>
  );
};

export default BusinessProfile;