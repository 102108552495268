import Header from "components/common/header/Header";
import { MessageHeader } from "components/messages";
import { Link } from "react-router-dom";
import { PATHS } from "utils/consts";

export const messages = [
  {
    title: "A new document is available",
    date: "Mar 18, 2023",
    content: "There are documents relating to your application for a new product for you to view. To access now, please select ‘Mortgage options’ from the left hand menu or within the Mortgage section under ‘my banking’. Thank you.",
  },
  {
    title: "Could not access my wallet",
    date: "Mar 17, 2023",
    content: "There are documents relating to your application for a new product for you to view. To access now, please select ‘Mortgage options’ from the left hand menu or within the Mortgage section under ‘my banking’. Thank you.",
  },
  {
    title: "How to sell crypto?",
    date: "Mar 17, 2023",
    content: "There are documents relating to your application for a new product for you to view. To access now, please select ‘Mortgage options’ from the left hand menu or within the Mortgage section under ‘my banking’. Thank you.",
  },
  {
    title: "Could not buy USDC",
    date: "Mar 16, 2023",
    content: "There are documents relating to your application for a new product for you to view. To access now, please select ‘Mortgage options’ from the left hand menu or within the Mortgage section under ‘my banking’. Thank you.",
  }
]

const MessagesList: React.FC = () => {
  return (
    <>
      <MessageHeader title="Messages" editable />
      <div className="p-4 mt-4">
        {
          messages.map((message, index) => (
            <Link to={`${PATHS.MESSAGES}/${index}`} key={index}>
              <div className="bg-surface-secondary rounded-xl px-4 py-2 w-full mt-2">
                <div className="flex justify-between mb-2">
                  <span className="text-sm font-medium">{message.title}</span>
                  <span className="text-xs font-medium">{message.date}</span>
                </div>
                <p className="text-xs font-medium text-font-subtle truncate">{message.content}</p>
              </div>
            </Link>
          ))
        }
      </div>
    </>
  );
}

export default MessagesList;