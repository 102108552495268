import { PayloadAction } from '@reduxjs/toolkit';
import { createPaymentApi } from 'api';
import { Axios, AxiosError } from 'axios';
import { AppActions } from 'store';

import * as AppActionTypes from '../../types';

import { call, put, takeEvery } from 'redux-saga/effects';

// import { makeAPIRequst } from 'utils';

interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function* createPayoutCheckoutRequestSaga(action: any) {
  try {
    const result: ResponseGenerator = yield call(
      async () => await createPaymentApi.createPayoutCheckout(action.payload)
    );

    yield put(AppActions.order.createPayoutCheckoutSuccess(result.data));

    if (action.payload.next) {
      action.payload.next(result.data.redirectUrl);
    }
  } catch (error: any) {
    // if (error instanceof AxiosError) {
    //   const reasonCode: string = error.request?.data.reason;
    //   if (reasonCode && action.payload.errors) {
    //     const errorHandler: () => void = action.payload.errors[reasonCode];
    //     errorHandler();
    //   }
    // } else {
    if (error.response)
      yield put(
        AppActions.order.createPayoutCheckoutFailure(error.response.data)
      );
    // }
  }
}
