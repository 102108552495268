import { customerServerApiClient, saasUserAPiClient } from "utils/apiClient";

export async function getDocumentTypesApi() {
  const res = await saasUserAPiClient.get('document/type');
  const resBody = res.data;
  return resBody;
};

export async function getBusinessDetailApi(data: any) {
  const { customerId } = data;
  const res = await saasUserAPiClient.get(`business/${customerId}`);
  const resBody = res.data;
  return resBody;
}

export async function getBusinessAddressApi(data: any) {
  const { addressId } = data;
  const res = await saasUserAPiClient.get(`address/${addressId}`);
  const resBody = res.data;
  return resBody;
}

export async function updateBusinessDetailApi(data: any) {
  const { customerId, ...restData } = data;

  const res = await saasUserAPiClient.put(`business/${customerId}`, restData);
  const resBody = res.data;
  return resBody;
}

export async function updateBusinessAddressApi(data: any) {
  const { customerId, ...restData } = data;

  const res = await saasUserAPiClient.put(`business/address/${customerId}`, restData);
  const resBody = res.data;
  return resBody;
}

export async function getBusinessMembersApi(data: any) {
  const { customerId } = data;

  const res = await saasUserAPiClient.get(`business/members/${customerId}`,);
  const resBody = res.data;
  return resBody;
}

export async function createBusinessApi(data: any) {
  const { customerId, ...restData } = data;

  const res = await saasUserAPiClient.post(`business/${customerId}`, restData);
  const resBody = res.data;
  return resBody;
}

export async function createBusinessDocumentApi(data: any) {
  const { customerId } = data;

  const formData: FormData = new FormData();
  formData.append('document', data.document.file);
  const res = await saasUserAPiClient.post(`document/${customerId}?type=${data.document.documentType}`, formData);

  const resBody = res.data;
  return resBody;
}

export async function getDocumentsApi(data: any) {
  const { customerId } = data;

  const res = await saasUserAPiClient.get(`document/${customerId}`);

  const resBody = res.data;
  return resBody;
}