import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {
}

const ClockIcon: React.FC<IProps> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#0A33FF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13 8V11.5L15.4 13.3L14.2 14.9L11 12.5V8H13Z" fill="#0A33FF" />
    </svg>
  );
};

export default ClockIcon;