import React, { createContext, useState, useEffect } from 'react';
import { COOKIES } from "utils/consts";
import { IUser } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, RootState } from 'store';
import { destroyCookie, setCookie } from 'nookies';

type IPlatformContext = {
  saasUserCorporationId: number,
  isLoggedIn: boolean,
  language: string,
  currency: string,
  crypto: string,
  user: IUser | null,
  addressDetail: any,
  saveToken: (token: string) => void,
  saveLanguage: (language: string) => void,
  saveCurrency: (currency: string) => void,
  saveCrypto: (crypto: string) => void,
  saveUser: (user: IUser) => void,
  saveAddressDetail: (addressDetail: any) => void,
  saveCardDetail: (cardDetail:any) => void,
  getLoggedIn: () => boolean,
  getUser: () => IUser | null,
  getAddressDetail: () => any,
  getCardDetail: () => any,
  resetSession: () => void,
};

export const PlatformContext = createContext<IPlatformContext>({
  saasUserCorporationId: -1,
  isLoggedIn: false,
  language: 'English (US)',
  currency: 'EUR',
  crypto: 'USDT',
  user: null,
  addressDetail: {},

  saveToken: () => {},
  saveLanguage: () => {},
  saveCurrency: () => {},
  saveCrypto: () => {},
  saveAddressDetail: () => {},
  saveCardDetail: () => {},
  getLoggedIn: () => false,
  getUser: () => null,
  getAddressDetail: () => null,
  getCardDetail: () => null,
  resetSession: () => {},
  saveUser: () => {},
});

type PlatformContextProviderProps = {
  children: React.ReactNode
}

export const PlatformContextProvider: React.FC<PlatformContextProviderProps> = ({ children }) => {
  const dispatch = useDispatch();
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');
  const [crypto, setCrypto] = useState<string>('');
  const [user, setUser] = useState<IUser | null>(null);
  const [addressDetail, setAddressDetail] = useState<any>();
  const [cardDetail, setCardDetail] = useState();
  const { saasUserCorporation } = useSelector((root: RootState) => root.saasUserCorporation);

  const saveToken = (token: string) => {
    setLoggedIn(true);
    setCookie(null, COOKIES.JWT, token, {
      path: '/',
      maxAge: 1800,
    });
    localStorage.setItem(COOKIES.JWT, token);
    localStorage.setItem(COOKIES.EXPIRATION_TIME, (new Date().getTime() + 30 * 60 * 1000).toString());
  };

  const saveUser = (user: IUser) => {
    // setCookie(null, COOKIES.USER, JSON.stringify(user), {
    //   path: '/',
    //   maxAge: 3600,
    // });
    setUser(user);
    localStorage.setItem(COOKIES.USER, JSON.stringify(user));
  };

  const saveLanguage = (language: string) => {
    // setCookie(null, COOKIES.LANGUAGE, language);
    localStorage.setItem(COOKIES.LANGUAGE, language);
  };

  const saveCurrency = (currency: string) => {
    // setCookie(null, COOKIES.CURRENCY, currency);
    localStorage.setItem(COOKIES.CURRENCY, currency);
    setCurrency(currency);
  };

  const saveCrypto = (crypto: string) => {
    // setCookie(null, COOKIES.CRYPTO, crypto);
    localStorage.setItem(COOKIES.CRYPTO, crypto);
    setCrypto(crypto);
  };

  const saveAddressDetail = (_addressDetail: any) => {
    localStorage.setItem(COOKIES.ADDRESS, JSON.stringify(_addressDetail));
    setAddressDetail(_addressDetail);
  };

  const resetSession = () => {
    setLoggedIn(false);
    destroyCookie(null, COOKIES.JWT, { path: '/' });
    // destroyCookie(null, COOKIES.USER, { path: '/' });
    localStorage.removeItem(COOKIES.JWT);
    localStorage.removeItem(COOKIES.USER);
    localStorage.removeItem(COOKIES.USER_ID);
    localStorage.removeItem(COOKIES.ADDRESS);
  };

  const getLoggedIn = () => {
    const token = localStorage.getItem(COOKIES.JWT);
    const expirationTime = localStorage.getItem(COOKIES.EXPIRATION_TIME);
    // if (cookies[COOKIES.JWT]) {
    if (token && new Date().getTime() < +(expirationTime || 0)) {
      setLoggedIn(true);
      return true;
    } else {
      resetSession();
    }
    setLoggedIn(false);
    return false;
  };

  const getUser = () => {
    const user = localStorage.getItem(COOKIES.USER);
    if (user) {
      const _user = JSON.parse(user);
      setUser(_user);
      return _user;
    }
    setUser(null);
    return null;
  };

  const getAddressDetail = () => {
    const addressDetailData = localStorage.getItem(COOKIES.ADDRESS);
    if (addressDetailData) {
      const _addressDetail = JSON.parse(addressDetailData);
      setAddressDetail({ ..._addressDetail });
      return _addressDetail;
    }
    setAddressDetail(null);
    return null;
  };

  useEffect(() => { 
    setLoggedIn(!!localStorage.getItem(COOKIES.JWT));
    setLanguage(localStorage.getItem(COOKIES.LANGUAGE) || 'English (US)');
    setCurrency(localStorage.getItem(COOKIES.CURRENCY) || 'EUR');
    setCrypto(localStorage.getItem(COOKIES.CRYPTO) || 'USDT');
  }, []);

  useEffect(() => {
    const [domain] = window.location.hostname.split('.');
    dispatch(AppActions.saasUserCorporation.getSaaSUserCorporationRequest({
      domain: domain
    }));
  }, [window.location.hostname, dispatch]);

  const getCardDetail = () => {
    return cardDetail;
  };

  const saveCardDetail = (_cardDetail: any) => {
    setCardDetail(_cardDetail);
  };

  return (
    <PlatformContext.Provider value={{
      isLoggedIn,
      language,
      currency,
      crypto,
      user: user || null,
      saasUserCorporationId: saasUserCorporation?.id ?? -1,
      addressDetail,
      getLoggedIn,
      getUser,
      getAddressDetail,
      getCardDetail,
      saveToken,
      saveLanguage,
      saveCurrency,
      saveCrypto,
      saveUser,
      saveAddressDetail,
      saveCardDetail,
      resetSession,
    }}>
      {children}
    </PlatformContext.Provider>
  );
};
