import { createSlice } from '@reduxjs/toolkit';
import { ICountry } from 'types';

interface IInitialCountrySlice {
  countries: ICountry[];
  gettingCountry: boolean;
  gotCountry: boolean;
  error: any;
}

const countrySlice = createSlice({
  name: 'country',
  initialState: {
    countries: [],
    gettingCountry: false,
    gotCountry: false,
    error: '',
  } as IInitialCountrySlice,
  reducers: {
    getCountry(state, action) {
      state.gettingCountry = true;
      state.gotCountry = false;
    },

    getCountrySuccess(state, action) {
      state.gettingCountry = false;
      state.gotCountry = true;
      state.countries = action.payload;
    },

    getCountryFailure(state, action) {
      state.gettingCountry = false;
      state.gotCountry = false;
      state.error = action.payload;
    },
  },
});

export const actions = countrySlice.actions;
export const reducer = countrySlice.reducer;
