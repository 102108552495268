
import { useContext, useMemo, useState } from "react";

import { PlatformContext } from "contexts";
import Header from "components/common/header/Header";
import { SearchInCircleSvg, SearchSvg } from "utils/icons";
import { countries } from "utils/consts";

const Language: React.FC = () => {
  const { language, saveLanguage } = useContext(PlatformContext);
  const [filter, setFilter] = useState<string>("");

  const languages = useMemo(() => {
    const languageObj: {
      [key: string]: any,
    } = {};
    countries.forEach((el) => {
      if (el.language && !languageObj[el.language] && el.language.includes(filter)) {
        languageObj[el.language] = el.flag;
      }
    });
    let languages = [];
    for (const [key, value] of Object.entries(languageObj)) {
      languages.push({
        language: key,
        flag: value
      });
    }

    return languages.sort((a: any, b) => a.language < b.language ? -1 : 1);
  }, [filter]);

  const onChangeFilter = (e: any) => {
    setFilter(e.target.value);
  };

  const onChangeLanuage = (language: string) => {
    saveLanguage(language);
  };

  return (
    <>
      <Header title="Language" />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Language</label>
        <div className="relative flex w-full mt-4">
          <img className="absolute left-4 top-4" src={SearchSvg} />
          <input
            className="block p-4 pl-12 w-full text-base font-medium text-font-primary bg-surface-secondary rounded-xl border border-surface-secondary outline-none focus:ring-primary focus:border-primary duration-200"
            onChange={onChangeFilter}
          />
        </div>
        {
          languages.length ? languages.map((el: any, index: number) => {
            return (
              <div
                key={index}
                className={`flex items-center mt-4 p-2 ${el.language === language && 'bg-feedback-information-tint'}`}
                onClick={() => { onChangeLanuage(el.language); }}
              >
                <img className="w-8 h-6" src={el.flag} />
                <div className="text-base font-medium text-font-primary ml-4">{el.language}</div>
              </div>
            );
          })
            : filter && (
              <div className="flex flex-col justify-content items-center p-12">
                <img src={SearchInCircleSvg} />
                <span className="text-base font-medium text-font-primary mt-4">We do not support this language yet!</span>
              </div>
            )
        }
      </div>
    </>
  );
};

export default Language;