import Button from "components/common/buttons/Button";

interface IProps {
  onClose: () => void,
  onConfirm: () => void,
}

const RemovePopup: React.FC<IProps> = (props) => {
  const { onConfirm, onClose } = props;

  return (
    <div className="fixed left-0 top-0 w-full h-screen flex justify-center items-center z-50 bg-font-primary/75 p-4">
      <div className="bg-surface-primary flex flex-col justify-center items-center rounded-xl p-8">
        <span className="text-base font-medium text-font-primary">Are you sure you want to remove this card?</span>
        <Button className="w-full mt-8 mb-4" onClick={onConfirm}><>Confirm to Remove</></Button>
        <a className="text-sm font-bold text-primary" onClick={onClose}>Back to Payment Methods</a>
      </div>
    </div>
  )
}

export default RemovePopup;