import { customerServerApiClient } from 'utils/apiClient';

export const createPaymentApi = {
  async createPaymentNuvei(data: any) {
    const res = await customerServerApiClient.post(
      'acquirer/nuvei/create-payment',
      data
    );
    const resBody = res.data;
    return resBody;
  },
  async createPaymentCheckout(data: any) {
    const res = await customerServerApiClient.post(
      'acquirer/checkout/create-payment',
      data
    );
    const resBody = res.data;
    return resBody;
  },
  async createPayoutCheckout(data: any) {
    const res = await customerServerApiClient.post(
      'acquirer/checkout/create-payout',
      data
    );
    const resBody = res.data;
    return resBody;
  },
  async validateNewCard(data: any) {
    const res = await customerServerApiClient.post(
      'acquirer/checkout/validate-card',
      data
    );
    const resBody = res.data;
    return resBody;
  },
};
