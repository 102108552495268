import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BuyCrypto from "components/landing/BuyCrypto";
import SellCrypto from "components/landing/SellCrypto";
import { PATHS, RAMPING, UserType } from "utils/consts";

import Menu from "components/menu/Menu";
import { MenuSvg, NextSvg } from "utils/icons";
import { PlatformContext } from "contexts";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import BusinessAddressIcon from "assets/icons/BusinessAddressIcon";
import { getRemainingDays } from "utils/supports";
import CompleteOrderIcon from "assets/icons/CompleteOrderIcon";
import { IOrder } from "types";

interface IProps {
  tab: RAMPING,
}

const businessActions = [{
  text: 'Complete your business profile',
  icon: <BusinessAddressIcon className="[&>path]:fill-primary" />,
  link: `${PATHS.BUSINESS}`,
}]

const Landing: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const { tab } = props;

  const { currency, crypto, user, getUser, getLoggedIn } = useContext(PlatformContext);

  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState<RAMPING>(tab || RAMPING.ON);
  const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);
  const { businessDetail } = useSelector((root: RootState) => root.business);
  const { uncompletedOrders } = useSelector((root: RootState) => root.order);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (getLoggedIn())
      dispatch(AppActions.order.getUncompletedOrders({}));
  }, [getLoggedIn]);

  const onCompleteOrder = (order: IOrder) => {
    if (order.status > 0) {
      navigate(`${PATHS.HISTORY}/${order.orderUuid}`)
    } else {
      dispatch(AppActions.user.setWalletAddress(order.walletAddress));
      dispatch(AppActions.crypto.setAmount(+order.cryptoAmount));
      navigate(`${order.type ? PATHS.SELL : PATHS.BUY}${PATHS.CONFRIM_ORDER}`);
    }
  }

  return (
    <>
      <div className="flex justify-between items-center text-lg font-bold text-center text-gray-500 border-b border-font-subtle px-4">
        <ul className="flex flex-wrap -mb-px">
          <li className="mr-2">
            <button
              className="inline-block p-4 text-font-subtle disabled:text-font-primary rounded-t-lg border-b-2 disabled:border-font-primary border-transparent disabled:font-primary duration-400 font-extrabold"
              disabled={currentTab === RAMPING.ON}
              onClick={() => {
                setCurrentTab(RAMPING.ON);
                navigate(PATHS.BUY);
              }}
            >
              Buy
            </button>
          </li>
          <li className="mr-2">
            <button
              className="inline-block p-4 text-font-subtle disabled:text-font-primary rounded-t-lg border-b-2 disabled:border-font-primary border-transparent disabled:font-primary duration-400 font-extrabold"
              disabled={currentTab === RAMPING.OFF}
              onClick={() => {
                setCurrentTab(RAMPING.OFF);
                navigate(PATHS.SELL);
              }}
            >
              Sell
            </button>
          </li>
        </ul>
        <img src={MenuSvg} onClick={() => { setSettingsOpen(true); }} />
      </div>
      {
        getLoggedIn() && user?.type === UserType.BUSINESS_CUSTOMER && !businessDetail.status &&
        <div className='px-4 mt-8'>
          <h2 className='text-lg font-bold text-primary'>Awaiting Your Action</h2>
          {
            businessActions.map((action, index) => (
              <Link to={action.link}>
                <div className='w-full flex rounded-lg bg-primary p-4 mr-2' key={index}>
                  <div className='flex justify-center items-center h-10 min-w-[40px] rounded-full bg-surface-primary'>{action.icon}</div>
                  <div className='flex flex-col ml-4 mr-4'>
                    <p className='text-lg font-bold text-font-secondary mb-2'>{action.text}</p>
                    <p className='text-md text-font-secondary'>Finish in {getRemainingDays(user.createdAt || '')} days</p>
                  </div>
                  <img src={NextSvg} />
                </div>
              </Link>
            ))
          }
        </div>
      }
      {
        getLoggedIn() && uncompletedOrders.length > 0 &&
        <div className='px-4 mt-8'>
          <h2 className='text-lg font-bold text-primary'>Awaiting Your Action</h2>
          <div className='flex overflow-auto'>
            {
              uncompletedOrders.map((order, index) => (
                <div className='flex min-w-full justify-between rounded-lg bg-primary p-4 mr-2' key={index} onClick={() => onCompleteOrder(order)}>
                  <div className='flex'>
                    <div className='flex justify-center items-center h-10 min-w-[40px] rounded-full bg-surface-primary'>
                      <CompleteOrderIcon />
                    </div>
                    <div className='flex flex-col ml-4 mr-4'>
                      <p className='text-lg font-bold text-font-secondary mb-2'>Complete Order</p>
                      <p className='text-md font-medium text-font-secondary'>
                        {
                          order.type ? `Deposit ${order.cryptoAmount} ${order.crypto} to get ${order.currency}${order.fiatAmount}` :
                            `Pay ${order.currency}${order.fiatAmount} to get ${order.cryptoAmount} ${order.crypto}`
                        }
                      </p>
                    </div>
                  </div>
                  <img src={NextSvg} />
                </div>
              ))
            }
          </div>
        </div>
      }
      {
        {
          [RAMPING.ON]: <BuyCrypto />,
          [RAMPING.OFF]: <SellCrypto />,
        }[currentTab]
      }
      {
        isSettingsOpen &&
        <Menu
          crypto={crypto}
          currency={currency}
          onClose={() => { setSettingsOpen(false); }}
        />
      }
    </>
  );
};

export default Landing;