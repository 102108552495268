import { CancelSvg } from "utils/icons";
import { IDocumentType } from "types";
import { SelectDocumentType } from "../selects/SelectDocumentType";

interface IProps {
  documentType?: IDocumentType;
  documentTypes: IDocumentType[];
  onChange: (documentType: IDocumentType) => void;
  onClose: () => void;
}

const SelectDocumentTypePopup: React.FC<IProps> = (props) => {
  const { documentType, documentTypes, onChange, onClose } = props;

  return (
    <div className="flex flex-col fixed left-0 top-0 w-full h-screen z-50 bg-surface-primary p-4">
      <div className="flex justify-between items-end mb-4">
        <label className="text-lg font-bold text-font-primary">Select a Currency</label>
        <img className="" src={CancelSvg} onClick={() => { onClose(); }} />
      </div>
      <SelectDocumentType
        documentType={documentType}
        documentTypes={documentTypes}
        onChange={(value) => {
          onChange(value);
          onClose();
        }}
      />
    </div>
  );
};

export default SelectDocumentTypePopup;