import Header from "components/common/header/Header";
import { HeaderButtonType } from "utils/consts";
import { useContext, useEffect, useMemo, useState } from "react";
import { AppActions, RootState } from "store";
import { useDispatch, useSelector } from "react-redux";
import SelectButton from "components/common/buttons/SelectButton";
import { IDocumentType } from "types";
import SelectDocumentTypePopup from "components/common/popups/SelectDocumentTypePopup";
import { UploadFileComponent } from "components/common/UploadFileComponent";
import { PlatformContext } from "contexts";

const UploadDocuments: React.FC = () => {
  const dispatch = useDispatch();
  const [documentType, setDocumentType] = useState<IDocumentType>();
  const [openDocumentSelect, setOpenDocumentSelect] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>('');
  const [file, setFile] = useState<any>();
  const { getUser } = useContext(PlatformContext);
  const { documents, documentTypes } = useSelector((root: RootState) => root.business);

  useEffect(() => {
    dispatch(AppActions.business.getDocumentTypes());
    const user = getUser();
    if (user) {
      dispatch(AppActions.business.getBusinessDocuments({
        customerId: user.customerId,
      }));
    }
  }, []);

  const getFile = (type: string, file: any) => {
    setFileType(type);
    setFile(file);
  }

  const onSubmit = () => {
    const user = getUser();
    if (user) {
      dispatch(AppActions.business.createBusinessDocument({
        customerId: user.customerId,
        document: {
          documentType: fileType,
          file,
        }
      }));
    }
  }

  const _documentTypes: IDocumentType[] = useMemo(() => {
    return documentTypes.filter(el => documents.filter((document: any) => document.type !== el.description).length)
  }, [documentTypes]);

  return (
    <>
      <Header title='Business Detail' button={HeaderButtonType.SUBMIT} isValid={!file || !documentType} onClickButton={onSubmit} />
      <div className='flex flex-col p-4 mt-4'>
        <label className='text-xl font-bold text-font-primary'>Upload Document</label>

        <SelectButton
          className='mt-4'
          label='Document Type'
          text={documentType?.description || ''}
          onClick={() => setOpenDocumentSelect(true)}
        />
        <UploadFileComponent
          type={documentType?.code || ''}
          getFile={getFile}
        />

        <div className="mt-8">
          <label className='text-lg font-bold text-font-primary'>Uploaded Documents</label>
          <table className="min-w-full text-left text-sm font-light font-text-primary">
            <thead className="border-b font-medium">
              <tr>
                <th scope="col" className="px-4 py-4">#</th>
                <th scope="col" className="px-4 py-4">Document</th>
                <th scope="col" className="px-4 py-4">Date</th>
              </tr>
            </thead>
            <tbody>
              {
                documents.length > 0 && documents.map((el: any, index: number) => (
                  <tr className="border-b">
                    <td className="whitespace-nowrap px-4 py-4 font-medium">{index + 1}</td>
                    <td className="whitespace-nowrap px-4 py-4">{el.type}</td>
                    <td className="whitespace-nowrap px-4 py-4">{new Date(el.createDate).toLocaleString()}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>

        {
          openDocumentSelect &&
          <SelectDocumentTypePopup
            documentType={documentType}
            documentTypes={_documentTypes}
            onChange={(value) => setDocumentType(value)}
            onClose={() => setOpenDocumentSelect(false)}
          />
        }
      </div>
    </>
  );
};

export default UploadDocuments;