import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Menu from "../../menu/Menu";
import { ArrowLeftSvg, MenuSvg } from "utils/icons";
import { PlatformContext } from "contexts";
import { HeaderButtonType } from "utils/consts";
import Button from "../buttons/Button";

interface IProps {
	title: string,
	backTo?: string,
	button?: HeaderButtonType,
	isValid?: boolean,
	onClickButton?: () => void,
	onClickBack?: () => void,
}

const Header: React.FC<IProps> = (props) => {
	const { title, backTo, button = HeaderButtonType.MENU, isValid, onClickBack, onClickButton } = props;
	const navigate = useNavigate();
	const [isSettingsOpen, setSettingsOpen] = useState<boolean>(false);
	const { currency, crypto } = useContext(PlatformContext);

	const goBack = () => {
		onClickBack && onClickBack();
		if (backTo) {
			navigate(backTo as string);
		}
		else navigate(-1);
	};

	return (
		<>
			<div className="flex justify-between items-center text-lg font-bold text-center text-gray-500 p-4">
				<div className="flex items-center">
					<img src={ArrowLeftSvg} onClick={goBack} />
					<span className="text-base font-medium text-font-primary ml-4">{title}</span>
				</div>
				{
					{
						[HeaderButtonType.MENU]: <img src={MenuSvg} onClick={() => { setSettingsOpen(true); }} />,
						[HeaderButtonType.SUBMIT]: <Button className="py-2" disabled={isValid} onClick={onClickButton} >Submit</Button>,
						[HeaderButtonType.UPDATE]: <Button className="py-2" disabled={isValid} onClick={onClickButton}>Update</Button>
					}[button]
				}
			</div>
			{
				isSettingsOpen &&
				<Menu
					currency={currency}
					crypto={crypto}
					onClose={() => { setSettingsOpen(false); }}
				/>
			}
		</>
	);
};

export default Header;