import { PayloadAction } from '@reduxjs/toolkit';
import { createPaymentApi } from 'api';
import { AxiosError } from 'axios';
import { AppActions } from 'store';

import * as AppActionTypes from '../../types';

import { call, put, takeEvery } from 'redux-saga/effects';

// import { makeAPIRequst } from 'utils';

interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function* createPaymentRequestSaga(action: any) {
  try {
    const result: ResponseGenerator = yield call(
      async () => await createPaymentApi.createPaymentNuvei(action.payload)
    );

    yield put(AppActions.order.createPaymentRequestSuccess(result));

    if (action.payload.next) {
      action.payload.next();
    }
  } catch (error: unknown) {
    if (error instanceof AxiosError) {
      const reasonCode: string = error.request?.data.reason;
      if (reasonCode && action.payload.errors) {
        const errorHandler: () => void = action.payload.errors[reasonCode];
        errorHandler();
      }
    }
  }
}
