import Header from "components/common/header/Header";
import PendingIcon from "assets/icons/PendingIcon";
import { PATHS } from "utils/consts";
import { AppActions } from "store";
import { useDispatch } from "react-redux";

const OrderAMLHold: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <>
      <Header title="Go back Home!" backTo={PATHS.BUY} onClickBack={() => { dispatch(AppActions.order.initOrderStatus({})) }} />
      <div className="flex flex-col justify-between p-4 mt-4">
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-bold text-font-primary text-center">
            Your order is pending!
          </h1>
          <p className="text-sm font-medium text-font-primary mt-4">Your order is under review by AML! This might take some time!</p>
          <div className="flex items-center justify-center h-72">
            <div className={`w-36 h-36 z-20 animate-spin-slow`} >
              <PendingIcon className='fill-font-secondary w-36 h-36' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderAMLHold;