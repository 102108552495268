import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {
}

const ProfileIcon: React.FC<IProps> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0368 14.5962C15.6316 14.5962 16.1137 14.114 16.1137 13.5192C16.1137 12.9245 15.6316 12.4423 15.0368 12.4423C14.442 12.4423 13.9599 12.9245 13.9599 13.5192C13.9599 14.114 14.442 14.5962 15.0368 14.5962ZM15.0368 15.5385C16.152 15.5385 17.056 14.6344 17.056 13.5192C17.056 12.404 16.152 11.5 15.0368 11.5C13.9216 11.5 13.0176 12.404 13.0176 13.5192C13.0176 14.6344 13.9216 15.5385 15.0368 15.5385Z" fill="#7F818A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0376 11.7008C14.0329 11.7008 13.2184 12.5153 13.2184 13.52C13.2184 14.5247 14.0329 15.3392 15.0376 15.3392C16.0423 15.3392 16.8568 14.5247 16.8568 13.52C16.8568 12.5153 16.0423 11.7008 15.0376 11.7008ZM12.8184 13.52C12.8184 12.2944 13.8119 11.3008 15.0376 11.3008C16.2632 11.3008 17.2568 12.2944 17.2568 13.52C17.2568 14.7457 16.2632 15.7392 15.0376 15.7392C13.8119 15.7392 12.8184 14.7457 12.8184 13.52ZM15.0376 12.6431C14.5533 12.6431 14.1607 13.0357 14.1607 13.52C14.1607 14.0043 14.5533 14.3969 15.0376 14.3969C15.5219 14.3969 15.9145 14.0043 15.9145 13.52C15.9145 13.0357 15.5219 12.6431 15.0376 12.6431ZM13.7607 13.52C13.7607 12.8148 14.3324 12.2431 15.0376 12.2431C15.7428 12.2431 16.3145 12.8148 16.3145 13.52C16.3145 14.2252 15.7428 14.7969 15.0376 14.7969C14.3324 14.7969 13.7607 14.2252 13.7607 13.52Z" fill="#7F818A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9636 17.5592H17.1131C17.0067 16.9579 16.7969 16.4627 16.4974 16.1237C16.2017 15.789 15.7633 15.54 15.0383 15.54C14.3134 15.54 13.875 15.789 13.5793 16.1237C13.2798 16.4627 13.07 16.9579 12.9636 17.5592ZM12.8837 17.5592C12.8833 17.5592 12.8831 17.5591 12.8831 17.5591L12.8837 17.5592ZM12.0086 17.5623C12.2355 16.0151 13.0772 14.5977 15.0383 14.5977C16.9995 14.5977 17.8411 16.0151 18.0681 17.5623C18.1436 18.0772 17.7126 18.5015 17.1922 18.5015H12.8845C12.3641 18.5015 11.9331 18.0772 12.0086 17.5623Z" fill="#7F818A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7223 15.3662C13.2462 14.7732 14.0026 14.3965 15.0374 14.3965C16.0723 14.3965 16.8287 14.7732 17.3525 15.3662C17.8707 15.9527 18.1482 16.7354 18.265 17.5321C18.3612 18.1881 17.8122 18.7003 17.1913 18.7003H12.8836C12.2627 18.7003 11.7136 18.1881 11.8098 17.5321C11.9267 16.7353 12.2042 15.9527 12.7223 15.3662ZM13.0221 15.631C12.5725 16.14 12.3157 16.8397 12.2056 17.5902C12.1508 17.964 12.4637 18.3003 12.8836 18.3003H17.1913C17.6112 18.3003 17.9241 17.964 17.8693 17.5902C17.7592 16.8397 17.5024 16.14 17.0528 15.631C16.6088 15.1285 15.9638 14.7965 15.0374 14.7965C14.1111 14.7965 13.4661 15.1285 13.0221 15.631ZM15.0374 15.7388C14.3669 15.7388 13.9841 15.9654 13.7283 16.2549C13.4981 16.5155 13.3188 16.89 13.208 17.358H16.8669C16.7561 16.89 16.5768 16.5155 16.3466 16.2549C16.0908 15.9654 15.708 15.7388 15.0374 15.7388ZM12.7936 17.3786C12.9114 16.8166 13.1229 16.336 13.4285 15.9901C13.7641 15.6103 14.2581 15.3388 15.0374 15.3388C15.8168 15.3388 16.3108 15.6103 16.6463 15.9901C16.9779 16.3653 17.1987 16.899 17.3092 17.5232C17.3195 17.5814 17.3035 17.6412 17.2655 17.6865C17.2275 17.7319 17.1714 17.758 17.1122 17.758H12.9627C12.9491 17.758 12.9358 17.7567 12.9228 17.754C12.9055 17.7575 12.8876 17.7588 12.8692 17.7576C12.8647 17.7572 12.8582 17.7567 12.8504 17.7554C12.8473 17.7549 12.8396 17.7536 12.8301 17.751L12.83 17.751C12.8267 17.7501 12.8141 17.7467 12.7991 17.7398C12.7934 17.7372 12.7729 17.7278 12.7513 17.7091C12.7415 17.7007 12.653 17.6225 12.6911 17.499C12.7078 17.4448 12.7458 17.4023 12.7936 17.3786Z" fill="#7F818A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5 4.5H4.5V13.5H6.5V15.5H4.5C3.39543 15.5 2.5 14.6046 2.5 13.5V4.5C2.5 3.39543 3.39543 2.5 4.5 2.5H13.5C14.6046 2.5 15.5 3.39543 15.5 4.5V6.5H13.5V4.5ZM10.5 10.5H19.5V19.5H10.5V10.5ZM8.5 10.5C8.5 9.39543 9.39543 8.5 10.5 8.5H19.5C20.6046 8.5 21.5 9.39543 21.5 10.5V19.5C21.5 20.6046 20.6046 21.5 19.5 21.5H10.5C9.39543 21.5 8.5 20.6046 8.5 19.5V10.5Z" fill="#7F818A" />
    </svg>

  );
};

export default ProfileIcon;