import React from "react";
import { cryptoSvgs, SIZE } from "../../../utils/consts";

interface IProps {
  type?: string,
  label?: string,
  value?: string,
  className?: string,
  size?: SIZE,
  error?: string,
  placeholder?: string,
  crypto: string,
  onChange?: (value: string) => void,
}

const WalletAddressInput: React.FC<IProps> = (props) => {
  const {
    type,
    className,
    placeholder,
    value,
    label,
    onChange,
    size = SIZE.lg,
    error,
    crypto
  } = props;

  return (
    <div className={`relative ${className}`}>
      <label
        className={`absolute ${(size === SIZE.md ? 'top-2' : 'top-4')} left-4 text-xs font-medium text-font-subtle`}
      >
        {label}
      </label>
      <input
        type={type}
        className={`block ${(size === SIZE.md ? 'pl-4 pr-10 pt-6 pb-2' : 'pl-4 pr-10 pt-8 pb-4')} w-full bg-surface-secondary text-base font-medium text-font-primary rounded-xl border ${!error ? 'border-surface-secondary' : 'border-feedback-warning'} outline-none focus:ring-primary focus:border-primary duration-200`}
        defaultValue={value}
        placeholder={placeholder}
        onChange={(e: any) => { onChange && onChange(e.target.value); }}
      />
      <div className="absolute top-6 right-4 flex items-center">
        <img className="w-6 h-6" src={cryptoSvgs[crypto]} />
      </div>
      {!!error && <label
        className='text-sm font-medium text-feedback-warning duration-200'
      >{error}</label>}
    </div>
  );
};

export default WalletAddressInput;