import { SVGProps } from "react";

const CompleteOrderIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.07312 15.9515C6.07312 15.5878 6.36796 15.293 6.73166 15.293H17.2682C17.6319 15.293 17.9268 15.5878 17.9268 15.9515C17.9268 16.3152 17.6319 16.61 17.2682 16.61H6.73166C6.36796 16.61 6.07312 16.3152 6.07312 15.9515Z" fill="#0A33FF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.41463 12.2194C4.80847 12.2194 4.31707 12.7108 4.31707 13.317V18.5853C4.31707 19.1914 4.80847 19.6828 5.41463 19.6828H18.5854C19.1915 19.6828 19.6829 19.1914 19.6829 18.5853V13.317C19.6829 12.7108 19.1915 12.2194 18.5854 12.2194H15.9512V10.9023H18.5854C19.9189 10.9023 21 11.9834 21 13.317V18.5853C21 19.9188 19.9189 20.9999 18.5854 20.9999H5.41463C4.08107 20.9999 3 19.9188 3 18.5853V13.317C3 11.9834 4.08107 10.9023 5.41463 10.9023H8.04878V12.2194H5.41463Z" fill="#0A33FF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39026 4.97561C7.39026 3.88451 8.27477 3 9.36587 3H14.6342C15.7253 3 16.6098 3.88451 16.6098 4.97561V15.9512H15.2927V4.97561C15.2927 4.61191 14.9979 4.31707 14.6342 4.31707H9.36587C9.00217 4.31707 8.70733 4.61191 8.70733 4.97561V15.9512H7.39026V4.97561Z" fill="#0A33FF" />
      <path d="M12.1992 13.9637C12.0941 14.085 11.906 14.085 11.8009 13.9637L10.0963 11.9954C9.94854 11.8248 10.0697 11.5595 10.2954 11.5595L13.7047 11.5595C13.9304 11.5595 14.0516 11.8248 13.9038 11.9954L12.1992 13.9637Z" fill="#0A33FF" />
      <path d="M11.9554 13.3164C11.5917 13.3164 11.2969 13.0216 11.2969 12.6579V6.95055C11.2969 6.58685 11.5917 6.29202 11.9554 6.29202C12.3191 6.29202 12.6139 6.58685 12.6139 6.95055V12.6579C12.6139 13.0216 12.3191 13.3164 11.9554 13.3164Z" fill="#0A33FF" />
    </svg>

  );
};

export default CompleteOrderIcon;