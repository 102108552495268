import { combineReducers, configureStore } from '@reduxjs/toolkit';

import rootSaga from './saga';
import {
  businessActions,
  businessReducer,
  countryActions,
  countryReducer,
  cryptoActions,
  cryptoReducer,
  currencyActions,
  currencyReducer,
  feeActions,
  feeReducer,
  orderActions,
  orderReducer,
  quoteActions,
  quoteReducer,
  userActions,
  userReducer,
  saasUserCorporationActions,
  saasUserCorporationReducer,
  cardActions,
  cardReducer,
} from './slices';

import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  user: userReducer,
  order: orderReducer,
  quote: quoteReducer,
  currency: currencyReducer,
  crypto: cryptoReducer,
  fee: feeReducer,
  country: countryReducer,
  business: businessReducer,
  saasUserCorporation: saasUserCorporationReducer,
  card: cardReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false, thunk: false }).concat(
      sagaMiddleware
    ),
});

sagaMiddleware.run(rootSaga);

export const AppActions = {
  user: userActions,
  order: orderActions,
  quote: quoteActions,
  currency: currencyActions,
  crypto: cryptoActions,
  fee: feeActions,
  country: countryActions,
  business: businessActions,
  saasUserCorporation: saasUserCorporationActions,
  card: cardActions,
};

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
