import { ReactNode } from "react";

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  children?: ReactNode;
}

const Button: React.FC<IProps> = (props) => {
  const {
    className,
    disabled = false,
    onClick,
    children,
    ...rest
  } = props;
  return (
    <button
      type="button"
      className={`p-4 text-base font-bold text-font-secondary rounded-full bg-primary disabled:bg-feedback-disabled focus:bg-primary-light duration-200 ${className}`}
      onClick={() => { onClick && onClick(); }}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;