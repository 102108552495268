import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { PATHS } from "utils/consts";
import Message from "./Message";
import MessagesList from "./MessagesList";
import NewMessage from "./NewMessage";
import ReplyMessage from "./ReplyMessage";

export const Messages: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <Outlet />
      <Routes location={location}>
        <Route>
          <Route path={PATHS.HOME} element={<MessagesList />} />
          <Route path={PATHS.MESSAGE} element={<Message />} />
          <Route path={`${PATHS.REPLY}/:id`} element={<ReplyMessage />} />
          <Route path={PATHS.EDIT} element={<NewMessage />} />
        </Route>
      </Routes>
    </>
  );
};