// @ts-ignore
import { PlatformContext } from 'contexts';
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "store";
import { PATHS, USER_KYC_STATUS, VERIFF_DISPLAY } from "utils/consts";
import { WarningSvg } from "utils/icons";
import VeriffCongrats from "./VeriffCongrats";
import VeriffDisplay from "./VeriffDisplay";
import VeriffSession from "./VeriffSession";


const VeriffAfterAuth: React.FC = () => {

  const [currentDisplay, setCurrentDisplay] = useState<VERIFF_DISPLAY>(VERIFF_DISPLAY.CHECK);
  const [currentVeriffUuid, setCurrentVeriffUuid] = useState<string>("");

  const navigate = useNavigate();

  const [currentUserKycStatus, setCurrentUserKycStatus] = useState<USER_KYC_STATUS>(USER_KYC_STATUS.NOT_TAKEN);

  const { currentUser } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    if (currentUser) {
      setCurrentUserKycStatus(currentUser.kycStatus);
    } else {
      setCurrentDisplay(VERIFF_DISPLAY.FAILURE);
    }
  }
    , []
  )

  useEffect(() => {//TODO
    if (currentDisplay == VERIFF_DISPLAY.APPROVED) {
      //dispatch(generateToken({ emailAddress: authInfo.emailAddress as string }));
      //      saveToken(COOKIES.JWT)
      navigate(PATHS.BUY);
    }

  }, [currentDisplay]);

  // useEffect(() => {
  //   if (generateTokenStatus === ACTION_STATUS.SUCCESS) {
  //     navigate(PATHS.BUY);
  //   }
  // }, [generateTokenStatus]);

  useEffect(() => {

    if (currentUserKycStatus == null) {
      setCurrentDisplay(VERIFF_DISPLAY.USER_NOT_FOUND);
    } else {
      if (currentUserKycStatus == USER_KYC_STATUS.NOT_TAKEN) {
        if (currentUser.kycVeriffUuid) {
          setCurrentVeriffUuid(currentUser.kycVeriffUuid);
          setCurrentDisplay(VERIFF_DISPLAY.SESSION);
        } else {
          setCurrentDisplay(VERIFF_DISPLAY.FAILURE);
        }
        return;
      }
      if (currentUserKycStatus == USER_KYC_STATUS.PENDING_BY_ID || currentUserKycStatus == USER_KYC_STATUS.PENDING_BY_AML) {
        setCurrentDisplay(VERIFF_DISPLAY.PENDING);
        return;
      }
      if (currentUserKycStatus == USER_KYC_STATUS.REJECTED) {
        setCurrentDisplay(VERIFF_DISPLAY.REJECTED);
        return;
      }
      if (currentUserKycStatus == USER_KYC_STATUS.APPROVED) {
        setCurrentDisplay(VERIFF_DISPLAY.APPROVED);
        //goto buy page
        return;
      }
      //setCurrentDisplay(VERIFF_DISPLAY.CHECK);
    }
  }, [currentUserKycStatus]);


  const onClickHome = () => {
    navigate(PATHS.BUY);
  };
  const nextStepOfVeriffSession = () => {
    setCurrentDisplay(VERIFF_DISPLAY.CONGRATS)
  }


  return (
    <>
      {
        {
          [VERIFF_DISPLAY.SESSION]:
            <VeriffSession
              veriffUuid={currentVeriffUuid}
              nextStep={nextStepOfVeriffSession}
            />,
          [VERIFF_DISPLAY.CHECK]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="We are verifying your submission, please be patient."
            />,
          [VERIFF_DISPLAY.PENDING]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Your submission is under review. You will be contacted shortly with a decision."
            />,
          [VERIFF_DISPLAY.REJECTED]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Your kyc verification is rejected, please contact the support team."
            />,
          [VERIFF_DISPLAY.APPROVED]:
            <></>,

          [VERIFF_DISPLAY.USER_NOT_FOUND]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Invited user not found, please contact the support team."
            />,
          [VERIFF_DISPLAY.CONGRATS]:
            <VeriffCongrats />,
          [VERIFF_DISPLAY.FAILURE]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Unexpected technical issue, please contact the support team."
            />
        }
        [currentDisplay]
      }
    </>
  )
};

export default VeriffAfterAuth;