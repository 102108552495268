interface IProps {
	className?: string;
	width?: number;
	height?: number;
	fill?: string;
	onClick?: () => void;
}

const EyeOpenSvg: React.FC<IProps> = (props) => {
	const { className, width = 24, height = 24, fill = "#7F818A", onClick } = props;
	return (
		<svg
			className={className!}
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path fillRule="evenodd" clipRule="evenodd" d="M4.25205 11.9583C4.97025 11.3101 6.22668 10.2521 7.67195 9.36117C9.1431 8.45432 10.6845 7.8 12 7.8C13.3155 7.8 14.8569 8.45432 16.328 9.36117C17.7733 10.2521 19.0297 11.3101 19.7479 11.9583C19.7514 11.9614 19.7534 11.9638 19.7545 11.9652C19.7555 11.9667 19.7561 11.9677 19.7563 11.9682C19.7568 11.9692 19.7568 11.9697 19.7568 11.9697L19.756 11.9712C19.7554 11.9722 19.7541 11.9743 19.7513 11.9775C19.0734 12.7401 17.8484 14.0218 16.4027 15.1058C14.927 16.2121 13.3683 17 12 17C10.6317 17 9.07297 16.2121 7.59733 15.1058C6.15158 14.0218 4.9266 12.7401 4.24869 11.9775C4.24592 11.9743 4.24456 11.9722 4.24396 11.9712L4.24317 11.9696L4.24365 11.9682C4.24391 11.9677 4.24444 11.9667 4.24553 11.9652C4.2466 11.9638 4.24859 11.9614 4.25205 11.9583ZM12 6C10.1717 6 8.27645 6.87403 6.72742 7.82889C5.15253 8.7997 3.80534 9.93676 3.04606 10.622C2.30516 11.2907 2.23473 12.4211 2.90333 13.1733C3.61951 13.979 4.9354 15.3597 6.51755 16.5459C8.06981 17.7097 10.026 18.8 12 18.8C13.974 18.8 15.9302 17.7097 17.4824 16.5459C19.0646 15.3597 20.3805 13.979 21.0967 13.1733C21.7653 12.4211 21.6948 11.2907 20.9539 10.622C20.1947 9.93676 18.8475 8.7997 17.2726 7.8289C15.7235 6.87403 13.8282 6 12 6ZM10.4 12.4C10.4 11.5163 11.1163 10.8 12 10.8C12.8836 10.8 13.6 11.5163 13.6 12.4C13.6 13.2837 12.8836 14 12 14C11.1163 14 10.4 13.2837 10.4 12.4ZM12 9C10.1222 9 8.59998 10.5222 8.59998 12.4C8.59998 14.2778 10.1222 15.8 12 15.8C13.8777 15.8 15.4 14.2778 15.4 12.4C15.4 10.5222 13.8777 9 12 9Z"/>
		</svg>
	);
};

export default EyeOpenSvg;