import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ACTION_STATUS, SIZE, AUTHENTICATION_TYPE, PATHS, UserType, REGISTRATION_TYPE, merchantId, saasUserCorporationId, privacyPolicyLink, termsOfServiceLink } from "utils/consts";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { signUp, setSignUpStatus } from "store/slices/userSlice";
import Header from "components/common/header/Header";
import Input from "components/common/inputs/Input";
import PhoneCountryCode from "components/common/inputs/PhoneCountryCode";
import CheckBox from "components/common/inputs/CheckBox";
import Button from "components/common/buttons/Button";
import ConfirmPassword from "components/common/inputs/ConfirmPassword";
import { CancelInCircleSvg } from "utils/icons";
import { AuthenticationInfo } from "types";

interface IProps {
  authType: AUTHENTICATION_TYPE;
  nextStep: (authInfo: AuthenticationInfo) => void;
  setAuthenticationType: (type: AUTHENTICATION_TYPE) => void;
  registrationType: REGISTRATION_TYPE;
  setRegistrationType: (type: REGISTRATION_TYPE) => void;
}

const EmailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PhoneNoRegExp = /^[0-9]{9,14}$/;

const RegisterForm: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();

  const referralCode = params.get('promoCode') || '';

  const { authType, nextStep, setAuthenticationType, registrationType, setRegistrationType } = props;

  const [emailAddress, setEmailAddress] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState<string>("+44");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [referralCodeState, setReferralValueState] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [businessName, setBusinessName] = useState<string>("");
  const [userType, setUserType] = useState<UserType>(UserType.INDIVIDUAL_CUSTOMER);

  const [disable, setDisable] = useState<boolean>(false);

  const { signUpStatus, errorMessage } = useSelector((state: RootState) => state.user);

  useEffect(() => {
    setError('');
    if (emailAddress && !EmailRegExp.test(emailAddress)) {
      setError('Please enter a valid email address.');
    }
  }, [emailAddress]);

  useEffect(() => {
    setError('');
    if (phoneNumber && !PhoneNoRegExp.test(phoneNumber)) {
      setError('Please enter a valid phone number.');
    }
  }, [phoneNumber]);

  useEffect(() => {
    setError('');
    setEmailAddress('');
    setPhoneNumber('');
  }, [authType]);

  useEffect(() => {
    setError('');
    setEmailAddress('');
    setPhoneNumber('');
  }, [registrationType]);

  const onClickContinue = () => {

    setDisable(true)

    dispatch(signUp(
      {
        email: authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? emailAddress : undefined,
        phone: authType === AUTHENTICATION_TYPE.PHONE_AUTHENTICATION ? `${phoneCode}${phoneNumber}` : undefined,
        businessName: registrationType === REGISTRATION_TYPE.BUSINESS_CUSTOMER_REGISTRATION ? businessName : undefined,
        password,
        userType: registrationType === REGISTRATION_TYPE.BUSINESS_CUSTOMER_REGISTRATION ? UserType.BUSINESS_CUSTOMER : UserType.INDIVIDUAL_CUSTOMER,
        saasUserCorporationId: saasUserCorporationId,//TODO
        merchantId: merchantId, //TODO
        referralCode: referralCodeState ? referralCodeState : referralCode,
        next: () => {
          nextStep({
            emailAddress,
            phoneCode,
            phoneNumber,
            businessName,
            merchantId: +merchantId,
            saasUserCorporationId,
            userType,
            referralCode
          });
        }
      })
    );
  };

  const changeVerificationMethod = (type: AUTHENTICATION_TYPE) => {
    setAuthenticationType(type);
  };

  const changeRegistrationTypeAndUserType = (type: REGISTRATION_TYPE, userType: UserType) => {
    setRegistrationType(type);
    setUserType(userType)
  }

  const onChangePassword = (value: string) => {
    setPassword(value);
  };

  const onCloseWarning = () => {
    dispatch(setSignUpStatus(ACTION_STATUS.NONE));
  };


  const disableBtm = () => {
    if (registrationType === REGISTRATION_TYPE.INDIVIDUAL_CUSTOMER_REGISTRATION)
      return (authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? !emailAddress : !phoneNumber) || !!error || !confirmation || !password
    else
      return (authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? !emailAddress : !phoneNumber) || !!error || !confirmation || !password || !businessName
  }

  return (
    <>
      <Header title="Create Your Account" />
      <div className="p-4 mt-4">
        {
          signUpStatus === ACTION_STATUS.FAILURE && !!errorMessage &&
          <div className="flex items-center bg-feedback-warning rounded-xl px-4 py-2 mb-4" onClick={onCloseWarning}>
            <img src={CancelInCircleSvg} />
            <p className="text-sm font-medium text-font-secondary ml-2">{errorMessage}</p>
          </div>
        }
        {
          {
            [REGISTRATION_TYPE.INDIVIDUAL_CUSTOMER_REGISTRATION]: <h2 className="text-h2 font-bold text-font-primary">Create Your <a className="text-feedback-success">Personal Account</a></h2>,
            [REGISTRATION_TYPE.BUSINESS_CUSTOMER_REGISTRATION]: <h2 className="text-h2 font-bold text-font-primary">Create Your <a className="text-feedback-warning">Business Account</a></h2>,
          }[registrationType]
        }
        <span className="text-base font-medium text-font-primary mt-2">
          {
            {
              [AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION]: "Enter your email, we will send you a confirmation code there.",
              [AUTHENTICATION_TYPE.PHONE_AUTHENTICATION]: "Enter your phone number, we will send you a confirmation code there.",
            }[authType]
          }
        </span>
        {
          {
            [REGISTRATION_TYPE.INDIVIDUAL_CUSTOMER_REGISTRATION]:
              <span className="text-sm text-primary font-medium cursor-pointer" onClick={() => { changeRegistrationTypeAndUserType(REGISTRATION_TYPE.BUSINESS_CUSTOMER_REGISTRATION, UserType.BUSINESS_CUSTOMER); }}><br />Switch to Business Account</span>,
            [REGISTRATION_TYPE.BUSINESS_CUSTOMER_REGISTRATION]:
              <span className="text-sm text-primary font-medium cursor-pointer" onClick={() => { changeRegistrationTypeAndUserType(REGISTRATION_TYPE.INDIVIDUAL_CUSTOMER_REGISTRATION, UserType.INDIVIDUAL_CUSTOMER); }}><br />Switch to Personal Account</span>
          }[registrationType]
        }
        {
          {
            [REGISTRATION_TYPE.INDIVIDUAL_CUSTOMER_REGISTRATION]:
              <div />,
            [REGISTRATION_TYPE.BUSINESS_CUSTOMER_REGISTRATION]:
              <Input
                className="mt-8 mb-2"
                label="Registered Company Name"
                type="text"
                onChange={(value: string) => { setBusinessName(value); }}
                // error={error}
                size={SIZE.md}
              />

          }[registrationType]
        }
        {
          {
            [AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION]: (
              <>
                <Input
                  className="mt-8 mb-2"
                  label="Email"
                  type="email"
                  onChange={(value: string) => { setEmailAddress(value); }}
                  error={error}
                  size={SIZE.md}
                />
                {/* <a className="text-sm text-primary font-medium" onClick={() => { changeVerificationMethod(AUTHENTICATION_TYPE.PHONE_AUTHENTICATION); }}>Sign Up with Phone</a> */}
              </>
            ),
            [AUTHENTICATION_TYPE.PHONE_AUTHENTICATION]: (
              <>
                <div className="flex mt-8 mb-2">
                  <PhoneCountryCode className="w-60" onChangeCode={(value) => { setPhoneCode(value); }} />
                  <Input
                    className="w-full ml-2"
                    label="Phone Number"
                    onChange={(value: string) => { setPhoneNumber(value); }}
                    error={error}
                    size={SIZE.md}
                  />
                </div>
                <a className="text-sm text-primary font-medium" onClick={() => { changeVerificationMethod(AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION); }}>Sign Up with Email</a>
              </>
            ),
          }[authType]
        }
        <ConfirmPassword
          className="mt-8"
          onChange={onChangePassword}
        />
        <Input
          className="mt-8 mb-2"
          label="Promo Code"
          onChange={(value: string) => { setReferralValueState(value); }}
          error={error}
          value={referralCode.length ? referralCode : referralCodeState}
          disabled={!!referralCode.length}
          size={SIZE.md}
        />
        <div className="flex items-center mt-8">
          <CheckBox className="mr-4" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setConfirmation(e.target.checked); }} />
          <span className="text-sm font-medium text-font-primary">I agree with WynPay’s <a className="text-blue-500 underline" href={termsOfServiceLink} target='_blank'>Terms of Use</a> and <a className="text-primary" href={privacyPolicyLink} target='_blank'>Privacy Policy</a>.</span>
        </div>
        <div className="flex items-center mt-4">
          <CheckBox className="mr-4" onChange={() => { }} />
          <span className="text-sm font-medium text-font-primary">Send me news and exclusive offers from WynPay and its partners. I’ll get marketing cookies. I know I can optout at any time.</span>
        </div>
        <Button
          className="w-full mt-8"
          // disabled={(authType === AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION ? !emailAddress : !phoneNumber) || !!error || !confirmation || !password}
          disabled={disableBtm() || disable}
          onClick={onClickContinue}
        >
          Continue
        </Button>
        <div className="flex justify-center items-center mt-8">
          <span className="w-full text-center text-sm font-medium text-font-primary">Already have an account? <Link to={PATHS.LOGIN} className="text-primary">Log in</Link></span>
        </div>
        <div className="flex flex-col items-center w-full">
          <span className="text-center text-xs font-medium text-font-primary mt-2">This site is protected by the WynPay <a className="underline underline-offset-2" href={privacyPolicyLink} target='_blank'>Privacy Policy</a> and <a className="underline underline-offset-2" href={termsOfServiceLink} target='_blank'>Terms of Service</a> apply.</span>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
