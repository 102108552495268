import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {
}

const UploadDocumentsIcon: React.FC<IProps> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.5 19H7C6.44772 19 6 18.5523 6 18V4C6 3.44772 6.44772 3 7 3H13.3333C13.7661 3 14.1871 3.14036 14.5333 3.4L17.2 5.4C17.7036 5.77771 18 6.37049 18 7V18C18 18.5523 17.5523 19 17 19H15.5" stroke="#FEFEFE" stroke-width="1.6" stroke-linecap="round" />
      <path d="M11.1992 11H12.7992V20.2C12.7992 20.6418 12.441 21 11.9992 21C11.5574 21 11.1992 20.6418 11.1992 20.2V11Z" fill="#FEFEFE" />
      <path d="M9 12L11.4453 10.3698C11.7812 10.1459 12.2188 10.1459 12.5547 10.3698L15 12" stroke="#FEFEFE" stroke-width="1.6" stroke-linecap="round" />
    </svg>
  );
};

export default UploadDocumentsIcon;