import { useState } from "react";
import SelectButton from "../buttons/SelectButton";

interface IProps {
  className?: string,
  value: 'include' | 'exclude'
  onSelectChange: (value: boolean) => void,
}

export const SelectFeeType: React.FC<IProps> = (props) => {
  const { className, value, onSelectChange } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div className={`relative flex w-full space-between ${className}`}>
        <SelectButton
          className='w-full'
          label='Fees'
          text={value}
          onClick={() => setOpen(prev => !prev)}
        />
        {
          open &&
          <div className="absolute left-0 mt-20 w-full p-4 z-50 border border-primary bg-surface-primary rounded-xl w-full">
            <div
              className={`flex ${value === 'include' && 'bg-feedback-information-tint'} px-4 py-2 rounded-xl mb-2`}
              onClick={() => { onSelectChange(true); setOpen(false); }}
            >
              include
            </div>
            <div
              className={`flex ${value === 'exclude' && 'bg-feedback-information-tint'} px-4 py-2 rounded-xl mb-2`}
              onClick={() => { onSelectChange(false); setOpen(false); }}
            >
              exclude
            </div>
          </div>
        }
      </div>
    </>
  );
};