import { useEffect, useState } from "react";
import { SuccessSvg, FailureSvg } from "utils/icons";
import PasswordInput from "./PasswordInput";

const PasswordRegEx = [
	{
		regEx: /^(.{8,})$/,
		description: "At least 8 characters",
	},
	{
		regEx: /^(.*[A-Z].*)$/,
		description: "At least 1 upper case alphabet",
	},
	{
		regEx: /^(.*\d.*)$/,
		description: "At least 1 numeric character",
	},
	{
		regEx: /^(.*\W.*)$/,
		description: "At least 1 special character (such as &, # and $)",
	}
];

interface IProps {
	className?: string,
	onChange?: (value: string) => void,
}

const ConfirmPassword: React.FC<IProps> = (props) => {
	const { className, onChange } = props;
	const [password, setPassword] = useState<string>("");
	const [confirmPassword, setConfirmPassword] = useState<string>("");
	const [error, setError] = useState<string>("");

	useEffect(() => {
		setError("");
		if (!PasswordRegEx.every(el => el.regEx.test(password) === true)) {
			onChange && onChange("");
		} else {
			if (password !== confirmPassword) {
				setError("Passwords do not match.");
				onChange && onChange("");
			} else {
				onChange && onChange(password);
			}
		}
	}, [confirmPassword, password]);

	return (
		<div className={className}>
			<PasswordInput
				className="mb-2"
				label="Password"
				onChange={(value) => { setPassword(value); }}
				error={error && " "}
			/>
			<PasswordInput
				label="Confirm Password"
				onChange={(value) => { setConfirmPassword(value); }}
				error={error}
			/>
			<span className="text-xs font-medium text-font-subtle">Your password must contain:</span>
			<ul className="text-[0.75rem] font-medium text-font-subtle list-disc ml-6">
				{
					PasswordRegEx.map((el, index) => (
						<li key={index} style={ password ? {
								listStyleImage: `url(${el.regEx.test(password) ? FailureSvg : SuccessSvg})`
							} : {}
						}>
							{el.description}
						</li>
					))
				}
			</ul>
		</div>
	);
};

export default ConfirmPassword;