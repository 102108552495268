import { createSlice } from "@reduxjs/toolkit";
import { ISaaSUserCorporation } from "types";

type SaaSUserCorporationState = {
  saasUserCorporation?: ISaaSUserCorporation;
};

const initialState: SaaSUserCorporationState = {};

const accountSlice = createSlice({
  name: "saasUserCorporation",
  initialState: initialState,
  reducers: {
    // Get SaaSUserCorporation
    getSaaSUserCorporationRequest(
      _state: SaaSUserCorporationState,
      _action
    ) { },
    getSaaSUserCorporationSuccess(
      state: SaaSUserCorporationState,
      action
    ) {
      state.saasUserCorporation = action.payload.saasUserCorporation;
    },
    getSaaSUserCorporationFailure(_state: SaaSUserCorporationState) { },
  },
});

export const reducer = accountSlice.reducer;
export const actions = accountSlice.actions;
