import { countryApi } from 'api';
import { AppActions } from 'store';

import { call, put } from 'redux-saga/effects';

export function* getCountrySaga(action: any) {
  try {
    const { ...data } = yield call(
      async () => await countryApi.getCountries(action.payload)
    );
    yield put(AppActions.country.getCountrySuccess(data.countries));
  } catch (error: any) {
    yield put(AppActions.country.getCountryFailure(error.response.data));
  }
}
