import { customerServerApiClient, lambdaServerApiClient } from "utils/apiClient";

export const orderApi = {
  async createOrder(data: any) {
    const res = await lambdaServerApiClient.post('order/customer', data);
    const resBody = res.data;
    return resBody;
  },
  async createOffRampOrder(data: any) {
    const res = await lambdaServerApiClient.post('order/customer', data);
    const resBody = res.data;
    return resBody;
  },
  async getOrder(data: any) {
    const { orderUuid } = data;
    const res = await customerServerApiClient.get(`order/${orderUuid}`);
    const resBody = res.data;
    return resBody;
  },
  async getOrders(data: any) {
    const { offset = 0, limit = 10 } = data;
    const res = await customerServerApiClient.get('order', {
      params: {
        offset,
        limit
      }
    });
    const resBody = res.data;
    return resBody;
  },
  async getUncompletedOrders() {
    const res = await customerServerApiClient.get('order/uncompleted');
    const resBody = res.data;
    return resBody;
  },
  async updateOrder(data: any) {
    const res = await lambdaServerApiClient.put('order/customer', data);
    const resBody = res.data;
    return resBody;
  },
};
