import { countries } from "utils/consts";
import { ArrowDownSvg } from "utils/icons";
import { useEffect, useRef, useState } from "react";
import { ICountry } from "types";

interface IProps {
  label?: string,
  className?: string,
  svgPath?: any,
  subText?: string,
  onChangeCode?: (code: string) => void,
}

const PhoneCountryCode: React.FC<IProps> = (props) => {
  const { className, onChangeCode } = props;
  const [country, setCountry] = useState<ICountry>(countries[0]);
  const [open, setOpen] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const onClickOpen = () => {
    setOpen(!open);
  };

  const onClickItem = (el: ICountry) => {
    setCountry(el);
    setOpen(false);
    onChangeCode && onChangeCode(el.phoneCode || '');
  };

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className={className}>
      <button
        className="flex justify-between items-center w-full px-4 pt-4 pb-4 text-base font-medium text-font-primary rounded-xl border bg-surface-secondary border-surface-secondary hover:bg-surface-secondary focus:bg-surface-primary focus:border-primary duration-200"
        onClick={onClickOpen}
      >
        <div className="flex items-center">
          <img className="w-8 h-6" src={country.flag} />
          <span className="text-base font-bold text-font-primary ml-2">{country.phoneCode}</span>
        </div>
        <img className="left-1" src={ArrowDownSvg} />
      </button>
      {
        open && <div className="absolute left-0 w-full px-4 z-50">
          <div ref={wrapperRef} className="p-4 border border-primary bg-surface-primary rounded-xl w-full mr-8 mt-2">
            {
              countries.map(el => (
                <div
                  key={el.name}
                  className={`flex ${country === el && 'bg-feedback-information-tint'} px-4 py-2 rounded-xl mb-2`}
                  onClick={() => { onClickItem(el); }}
                >
                  <img className="w-8 h-6" src={el.flag} />
                  <span className="ml-2">{el.name}</span>
                  <span className="ml-2">{el.phoneCode}</span>
                </div>
              ))
            }
          </div>
        </div>
      }
    </div>
  );
};

export default PhoneCountryCode;