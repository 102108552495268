import CancelIcon from "assets/icons/CancelIcon";
import CheckIcon from "assets/icons/CheckIcon";
import PendingIcon from "assets/icons/PendingIcon";
import WarningIcon from "assets/icons/WarningIcon";
import { Link } from "react-router-dom";
import { PATHS, RAMPING, ORDER_STATUS } from "utils/consts";

interface IProps {
  type: RAMPING,
  status: ORDER_STATUS,
  orderedTime: string,
  orderId?: string,
  crypto: string,
}

const OrderStatusComponent: React.FC<IProps> = (props) => {
  const {
    type,
    status,
    orderedTime,
    orderId,
    crypto,
  } = props;

  return (
    <div className="relative">
      <div className="relative flex items-center mt-4 mb-8">
        <div className={`flex items-center justify-center w-9 h-9 bg-feedback-success rounded-full z-20`} >
          <CheckIcon className='fill-font-secondary' />
        </div>
        <div className="ml-4">
          <label className="text-base font-medium text-font-primary">
            Ordered
          </label>
          <p className="text-xs font-medium text-font-subtle">{orderedTime}</p>
        </div>
      </div>

      <div className="relative flex items-center mt-4 mb-8">
        {
          (status === ORDER_STATUS.AML_START || status === ORDER_STATUS.AML_HOLD) ?
            <div className={`flex items-center justify-center w-9 h-9 bg-feedback-success-tint rounded-full z-20`} >
              <PendingIcon className='fill-font-secondary animate-spin-slow' />
            </div> :
            status === ORDER_STATUS.DEPOSIT_DECLINED ?
              <div className={`flex items-center justify-center w-16 h-9 bg-feedback-warning rounded-full z-20`} >
                <CancelIcon className='fill-font-secondary' />
              </div> :
              status === ORDER_STATUS.WAITING_FOR_DEPOSIT ?
                <div className={`flex items-center justify-center w-9 h-9 bg-feedback-attention rounded-full z-20`} >
                  <WarningIcon className='fill-font-secondary' />
                </div> :
                <div className={`flex items-center justify-center w-9 h-9 bg-feedback-success rounded-full z-20`} >
                  <CheckIcon className='fill-font-secondary' />
                </div>
        }
        <div className="ml-4">
          {
            (status === ORDER_STATUS.AML_START || status === ORDER_STATUS.AML_HOLD) ?
              <label className="text-base font-bold text-font-primary">Pending</label> :
              status === ORDER_STATUS.DEPOSIT_DECLINED ?
                <>
                  <label className="text-base font-bold text-font-primary">Deposit Declined</label>
                  <p className="text-xs font-medium text-font-subtle">Your transaction has been cancelled because we were unable to receive your deposit within the specified time frame.</p>
                </> :
                type === RAMPING.OFF && status === ORDER_STATUS.WAITING_FOR_DEPOSIT ?
                  <>
                    <label className="text-base font-bold text-font-primary">Waiting for Deposit</label>
                    <div className="bg-primary-light rounded-xl p-2 mt-1">
                      <p className="text-sm font-medium text-font-primary">To complete your transaction, please send us your crypto.</p>
                      <Link to={`${PATHS.DEPOSIT}/${orderId}`} className="text-sm font-bold text-primary" >View deposit details</Link>
                    </div>
                  </> :
                  <label className="text-base font-medium text-font-primary">{type === RAMPING.ON ? "Verified" : "Received"}</label>
          }
        </div>
      </div>

      <div className="relative flex items-center mt-4 mb-8">
        {
          status === ORDER_STATUS.PROCESSING ?
            <div className={`flex items-center justify-center w-9 h-9 bg-feedback-success-tint rounded-full z-20 animate-spin-slow`} >
              <PendingIcon className='fill-font-secondary' />
            </div> :
            status === ORDER_STATUS.DONE || status === ORDER_STATUS.FULLFILLED ?
              <div className={`flex items-center justify-center w-9 h-9 bg-feedback-success rounded-full z-20`} >
                <CheckIcon className='fill-font-secondary' />
              </div> :
              <div className={`flex items-center justify-center w-9 h-9 bg-feedback-disabled rounded-full z-20`} >
                <CheckIcon className='fill-font-secondary' />
              </div>
        }
        <div className="ml-4">
          {
            status === ORDER_STATUS.PROCESSING ?
              <>
                <label className="text-base font-bold text-font-primary">Processing</label>
                <p className="text-xs font-medium text-font-subtle">We’re processing your {crypto}. This takes between a few minutes and a few hours. Thank you for your patience.</p>
              </> :
              status === ORDER_STATUS.DONE || status === ORDER_STATUS.FULLFILLED ?
                <label className="text-base font-medium text-font-primary">Processing</label> :
                <label className="text-base font-medium text-font-subtle">Processing</label>
          }
        </div>
      </div>

      <div className="relative flex items-center mt-4 mb-8">
        {
          (status === ORDER_STATUS.DONE || status === ORDER_STATUS.FULLFILLED) ?
            <div className={`flex items-center justify-center w-9 h-9 bg-feedback-success rounded-full z-20`} >
              <CheckIcon className='fill-font-secondary' />
            </div> :
            <div className={`flex items-center justify-center w-9 h-9 bg-feedback-disabled rounded-full z-20`} >
              <CheckIcon className='fill-font-secondary' />
            </div>
        }
        <div className="ml-4">
          {
            (status === ORDER_STATUS.DONE || status === ORDER_STATUS.FULLFILLED) ?
              <label className="text-base font-bold text-font-primary">Delivered</label> :
              <label className="text-base font-medium text-font-subtle">Delivering</label>
          }
        </div>
      </div>
      <div className="absolute bg-surface-tertiary w-0.5 h-full left-[17px] top-2 z-10"> </div>
    </div>
  )
}

export default OrderStatusComponent;