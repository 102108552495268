import Header from "components/common/header/Header";
import Input from "components/common/inputs/Input";
import SelectButton from "components/common/buttons/SelectButton";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { HeaderButtonType, flagImages } from "utils/consts";
import CheckBox from "components/common/inputs/CheckBox";
import SelectCountryPopup from "components/common/popups/SelectCountryPopup";
import { PlatformContext } from "contexts";
import { IBusinessDetail } from "types";

interface Address {
  address: string,
  city: string,
  postCode: string,
  country: string,
}

interface IBusinessAddressProps {
  businessDetail: IBusinessDetail,
}

const BusinessAddress: React.FC<IBusinessAddressProps> = (props) => {
  const dispatch = useDispatch();
  const { countries } = useSelector((root: RootState) => root.country);
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const [isSameAddress, setSameCountry] = useState<boolean>(false);
  const { getUser } = useContext(PlatformContext);
  const { businessDetail } = props;
  const { operatingAddress: _operatingAddress, registeredBusinessAddress } = useSelector((root: RootState) => root.business);

  const [businessAddress, setBusinessAddress] = useState<Address>({
    address: '',
    city: '',
    postCode: '',
    country: 'FR',
  });

  const [operatingAddress, setOperatingAddress] = useState<Address>({
    address: '',
    city: '',
    postCode: '',
    country: 'FR',
  });

  useEffect(() => {
    dispatch(AppActions.country.getCountry({}));
  }, []);

  useEffect(() => {
    if (businessDetail.registrationAddress !== undefined) {
      dispatch(AppActions.business.getRegisteredBusinessAddress({
        addressId: businessDetail.registrationAddress,
      }));
    }
    if (businessDetail.businessAddress !== undefined) {
      dispatch(AppActions.business.getOperatingAddress({
        addressId: businessDetail.businessAddress,
      }));
    }
  }, [businessDetail]);

  useEffect(() => {
    setBusinessAddress({
      address: registeredBusinessAddress.address1 || registeredBusinessAddress.address2,
      city: registeredBusinessAddress.city,
      postCode: registeredBusinessAddress.postCode,
      country: businessDetail.country || 'FR',
    });

    setOperatingAddress({
      address: _operatingAddress.address1 || _operatingAddress.address2,
      city: _operatingAddress.city,
      postCode: _operatingAddress.postCode,
      country: _operatingAddress.countryName,
    });
  }, [_operatingAddress, registeredBusinessAddress]);

  const changeSameAddress = (value: boolean) => {
    setSameCountry(value);
    if (value === true)
      setOperatingAddress({ ...businessAddress });
  }

  useEffect(() => {
    if (isSameAddress) {
      setOperatingAddress({ ...businessAddress });
    }
  }, [isSameAddress, businessAddress]);

  const onClickUpdate = () => {
    const user = getUser();
    if (user) {
      dispatch(AppActions.business.updateBusinessAddress({
        customerId: user.customerId,
        businessAddress: businessAddress.address,
        businessTown: businessAddress.city,
        businessZipCode: businessAddress.postCode,
        businessCountry: businessAddress.country,
        operatingAddress: operatingAddress.address,
        operatingTown: operatingAddress.city,
        operatingZipCode: operatingAddress.postCode,
        operatingCountry: operatingAddress.country,
      }));
    }
  };

  return (
    <>
      <Header title='Business Address' button={HeaderButtonType.UPDATE} onClickButton={onClickUpdate} />
      <div className="flex flex-col h-full p-4 mt-4">
        <label className="text-xl font-bold text-font-primary">Registered Business Address</label>
        <p className="text-base font-medium text-font-primary mt-2">The official address registered for the Business. It can be different from the address at which you have your business or coworking space.</p>

        <Input
          className="mt-4"
          label="Address"
          value={businessAddress.address}
          onChange={(value) => setBusinessAddress(prev => { return { ...prev, address: value } })}
        />

        <div className="flex justify-content mt-2">
          <Input
            className="mr-2"
            label="Town / City"
            value={businessAddress.city}
            onChange={(value) => setBusinessAddress(prev => { return { ...prev, city: value } })}
          />
          <Input
            label="Post Code / Zip Code"
            value={businessAddress.postCode}
            onChange={(value) => setBusinessAddress(prev => { return { ...prev, postCode: value } })}
          />
        </div>

        <SelectButton
          className="mt-2 mb-8"
          label="Country"
          text={countries.find(el => el.code === businessAddress.country)?.name}
          svgPath={flagImages[businessAddress.country]}
          flag
          disabled
        />

        <label className="text-xl font-bold text-font-primary">Operating address</label>
        <p className="text-base font-medium text-font-primary mt-2">The address where you would usually conduct Day to Day Operations.</p>

        <Input
          className="mt-4"
          label="Address"
          value={operatingAddress.address}
          onChange={(value) => setOperatingAddress(prev => { return { ...prev, address: value } })}
          disabled={isSameAddress}
        />

        <div className="flex justify-content mt-2">
          <Input
            className="mr-2"
            label="Town / City"
            value={operatingAddress.city}
            onChange={(value) => setOperatingAddress(prev => { return { ...prev, city: value } })}
            disabled={isSameAddress}
          />
          <Input
            label="Post Code / Zip Code"
            value={operatingAddress.postCode}
            onChange={(value) => setOperatingAddress(prev => { return { ...prev, postCode: value } })}
            disabled={isSameAddress}
          />
        </div>

        <SelectButton
          className="mt-2"
          label="Country"
          text={countries.find(el => el.code === operatingAddress.country)?.name}
          svgPath={flagImages[operatingAddress.country]}
          onClick={() => setOpenCountrySelect(true)}
          flag
          disabled={isSameAddress}
        />

        <div className="flex mt-4">
          <CheckBox onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeSameAddress(e.target.checked)} />
          <p className="text-base font-medium text-font-primary ml-4">Same as registered business address</p>
        </div>
      </div>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={operatingAddress.country}
          onChange={(value) => {
            setOperatingAddress(prev => {
              return {
                ...prev,
                country: value,
              }
            });
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  );
};

export default BusinessAddress;