import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {
}

const DirectorIcon: React.FC<IProps> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.87635 11.1923C10.0659 11.1923 11.0302 10.228 11.0302 9.03846C11.0302 7.84892 10.0659 6.88461 8.87635 6.88461C7.68681 6.88461 6.7225 7.84892 6.7225 9.03846C6.7225 10.228 7.68681 11.1923 8.87635 11.1923ZM8.87635 13.0769C11.1067 13.0769 12.9148 11.2688 12.9148 9.03846C12.9148 6.80808 11.1067 5 8.87635 5C6.64597 5 4.83789 6.80808 4.83789 9.03846C4.83789 11.2688 6.64597 13.0769 8.87635 13.0769Z" fill="#FEFEFE" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4.72791 17.1165H13.027C12.8142 15.9138 12.3945 14.9235 11.7955 14.2454C11.2042 13.576 10.3274 13.078 8.87747 13.078C7.42757 13.078 6.55075 13.576 5.95945 14.2454C5.36038 14.9235 4.94076 15.9138 4.72791 17.1165ZM4.56821 17.1164C4.56742 17.1164 4.56705 17.1163 4.56706 17.1163L4.56821 17.1164ZM2.81803 17.1227C3.27188 14.0282 4.95511 11.1934 8.87747 11.1934C12.7998 11.1934 14.483 14.0282 14.9369 17.1227C15.0879 18.1525 14.226 19.0011 13.1851 19.0011H4.56979C3.52894 19.0011 2.667 18.1525 2.81803 17.1227Z" fill="#FEFEFE" />
      <path d="M15.1255 11.1923C16.315 11.1923 17.2793 10.228 17.2793 9.03846C17.2793 7.84892 16.315 6.88461 15.1255 6.88461C14.673 6.88461 14.2531 7.02412 13.9065 7.26247C13.7975 7.33747 13.6418 7.30666 13.584 7.18759L12.9349 5.85083C12.8915 5.76148 12.92 5.65336 13.0045 5.60112C13.6209 5.21998 14.3475 5 15.1255 5C17.3558 5 19.1639 6.80808 19.1639 9.03846C19.1639 11.2688 17.3558 13.0769 15.1255 13.0769H14.809C14.7405 13.0769 14.6767 13.0419 14.6401 12.984L13.7993 11.6583C13.7429 11.5692 13.7645 11.4501 13.8562 11.3982C14.2198 11.1923 14.5784 11.1923 15.1255 11.1923Z" fill="#FEFEFE" />
      <path d="M19.2754 17.1165C19.0626 15.9138 18.643 14.9235 18.0439 14.2454C17.4526 13.576 16.5758 13.078 15.1259 13.078C14.8131 13.078 15.1833 13.079 14.8096 13.0782C14.7412 13.0781 14.6771 13.043 14.6405 12.9853L13.7996 11.6592C13.7431 11.5701 13.7648 11.451 13.8566 11.3993C14.2225 11.1934 14.589 11.1934 15.1259 11.1934C19.0483 11.1934 20.7315 14.0282 21.1853 17.1227C21.3363 18.1525 20.4744 19.0011 19.4335 19.0011L15.9592 19.0009C15.8279 19.0009 15.7322 18.8765 15.7659 18.7496L16.1598 17.2652C16.183 17.1775 16.2624 17.1165 16.3531 17.1165H19.2754Z" fill="#FEFEFE" />
    </svg>
  );
};

export default DirectorIcon;