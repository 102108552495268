import { useNavigate } from "react-router-dom";
import Header from "components/common/header/Header";
import PendingIcon from "assets/icons/PendingIcon";
import CheckIcon from "assets/icons/CheckIcon";
import { useContext, useEffect, useState } from "react";
import { PATHS } from "utils/consts";
import { PlatformContext } from "contexts";

const TravelRulesChecking: React.FC = () => {
  const { crypto } = useContext(PlatformContext);
  const navigate = useNavigate();
  const [pending, setPending] = useState<boolean>(true);

  useEffect(() => {
    if (pending) {
      setTimeout(() => {
        setPending(false);
      }, 3000);
    }
    if (!pending) {
      setTimeout(() => {
        navigate(`${PATHS.BUY}${PATHS.CONFRIM_ORDER}`);
      }, 1000);
    }
  }, [pending])
  return (
    <>
      <Header title="Checking Travel Rules" />
      <div className="flex flex-col justify-between p-4 mt-4">
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-bold text-font-primary text-center">
            {pending ? "Checking Travel Rules" : "Travel Rules Check Success"}
          </h1>
          <p className="text-sm font-medium text-font-primary mt-4">Scams within the {crypto} space have become more common so it is important to look out for red flags. Do not proceed if:</p>
          <div className="flex items-center justify-center h-72">
            {
              pending ?
                <div className={`w-36 h-36 z-20 animate-spin-slow`} >
                  <PendingIcon className='fill-font-secondary w-36 h-36' />
                </div> :
                <div className="w-36 h-36 bg-feedback-success-tint rounded-full" >
                  <CheckIcon className='w-36 h-36' />
                </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelRulesChecking;