import { useState } from "react";

import {
  RegisterForm,
  CodeVerification,
} from "components/authentication";

import { AUTHENTICATION_STATUS, AUTHENTICATION_TYPE, REGISTRATION_TYPE } from "utils/consts";
import { AuthenticationInfo } from "types";
import Veriff from "@veriff/js-sdk";
import { VeriffAfterAuth } from "components/authentication/veriff";

enum REGISTERATION_STEPS {
  REGISTERATION_INPUT = "REGISTERATION_INPUT",
  CODE_VERIFICATION = "CODE_VERIFICATION",
  VERIFF_VERIFICATION = "VERIFF_VERIFICATION"
}

const Register: React.FC = () => {
  const [authType, setAuthType] = useState<AUTHENTICATION_TYPE>(AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION);
  const [registrationType, setRegistType] = useState<REGISTRATION_TYPE>(REGISTRATION_TYPE.INDIVIDUAL_CUSTOMER_REGISTRATION);
  const [currentStep, setCurrentStep] = useState<REGISTERATION_STEPS>(REGISTERATION_STEPS.REGISTERATION_INPUT);
  const [authInfo, setAuthenticationInfo] = useState<AuthenticationInfo>({});

  const nextStepOfRegisterForm = (authInfo: AuthenticationInfo) => {
    setAuthenticationInfo(authInfo);
    setCurrentStep(REGISTERATION_STEPS.CODE_VERIFICATION);
  };

  const previousStepOfCodeVerification = () => {
    setCurrentStep(REGISTERATION_STEPS.REGISTERATION_INPUT);
  };

  const nextStepOfCodeVerification = () => {
    setCurrentStep(REGISTERATION_STEPS.VERIFF_VERIFICATION);
  };


  return (
    <>
      {
        {
          [REGISTERATION_STEPS.REGISTERATION_INPUT]:
            <RegisterForm
              authType={authType}
              setAuthenticationType={(type: AUTHENTICATION_TYPE) => { setAuthType(type); }}
              registrationType={registrationType}
              setRegistrationType={(type: REGISTRATION_TYPE) => { setRegistType(type); }}
              nextStep={nextStepOfRegisterForm}
            />,
          [REGISTERATION_STEPS.CODE_VERIFICATION]:
            <CodeVerification
              authType={authType}
              authInfo={authInfo}
              authStatus={AUTHENTICATION_STATUS.SIGNUP}
              previousStep={previousStepOfCodeVerification}
              nextStep={nextStepOfCodeVerification}
            />,
          [REGISTERATION_STEPS.VERIFF_VERIFICATION]: <VeriffAfterAuth />
        }[currentStep]
      }
    </>
  );
};

export default Register;