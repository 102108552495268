import { lambdaServerApiClient } from 'utils/apiClient';

export const counterPartyApi = {
  async getCounterPartyId(data: any) {
    const res = await lambdaServerApiClient.post('apm/get-counterparty-id', {
      ...data,
    });
    const resBody = res.data;
    return resBody;
  }
};