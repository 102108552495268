import CancelIcon from "assets/icons/CancelIcon";
import CheckIcon from "assets/icons/CheckIcon";
import PendingIcon from "assets/icons/PendingIcon";
import WarningIcon from "assets/icons/WarningIcon";
import Header from "components/common/header/Header";
import { useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppActions, RootState } from "store";
import { PATHS, RAMPING, ORDER_STATUS, orderStatus, cryptoSvgs, getCurrencySvg } from "utils/consts";

interface IOrderList {
  id: string,
  type: RAMPING,
  fiatAmount: number,
  currency: string,
  currencySymbol: string,
  cryptoAmount: number,
  crypto: string,
  createdTime: string,
  status: ORDER_STATUS,
}
const History: React.FC = () => {
  const dispatch = useDispatch();

  const { orders, totalOrdersCount, offset, limit } = useSelector((state: RootState) => state.order);

  const [orderList, setOrderList] = useState<IOrderList[]>([]);

  useEffect(() => {
    const array: IOrderList[] = orders.map(el => ({
      id: el.orderUuid,
      type: el.type ? RAMPING.OFF : RAMPING.ON,
      fiatAmount: el.fiatAmount,
      currency: el.currency,
      currencySymbol: el.currencySymbol,
      cryptoAmount: el.cryptoAmount,
      crypto: el.crypto,
      createdTime: new Date(el.createdTime).toLocaleString(),
      status: orderStatus[el.status],
    }));

    setOrderList(prev => {
      if (prev.length < Math.min((offset + 1) * limit, totalOrdersCount))
        return [...prev, ...array];
      return [...prev];
    });
  }, [orders, offset, limit, totalOrdersCount]);

  const loadMoreData = () => {
    dispatch(AppActions.order.getOrders({
      offset: +offset + 1,
      limit: limit,
    }));
  }

  useEffect(() => {
    dispatch(AppActions.order.getOrders({
      offset: 0,
      limit: 10,
    }));
  }, []);

  return (
    <>
      <Header title="Trade History" backTo={PATHS.BUY} />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Trade History</label>
        <div className="mt-4">
          <InfiniteScroll
            dataLength={orderList.length}
            next={loadMoreData}
            hasMore={orderList.length < totalOrdersCount}
            loader={
              <div className={`flex items-center justify-center w-9 h-9 bg-feedback-success-tint rounded-full z-20 animate-spin-slow`} >
                <PendingIcon className='fill-font-secondary' />
              </div>}
          >
            {
              orderList && orderList.length > 0 && orderList.map(order => (
                <Link to={`${PATHS.HISTORY}/${order.id}`}>
                  <div
                    key={order.id}
                    className="flex justify-between items-center bg-feedback-information-tint rounded-xl p-4 mb-2"
                  >
                    <div className="flex">
                      <div className="relative w-10 h-10 rounded-full bg-center bg-cover mr-4" style={{
                        backgroundImage: `url(${{
                          [RAMPING.ON]: cryptoSvgs[order.crypto],
                          [RAMPING.OFF]: getCurrencySvg(order.currency),
                        }[order.type]
                          })`
                      }}>
                        {
                          (order.status === ORDER_STATUS.DONE || order.status === ORDER_STATUS.FULLFILLED) &&
                          <div className={`absolute right-0 bottom-0 flex items-center justify-center w-3 h-3 bg-feedback-success rounded-full z-20`} >
                            <CheckIcon className='fill-font-secondary w-2 h-2' />
                          </div>
                        }
                        {
                          (order.status === ORDER_STATUS.AML_REJECT || order.status === ORDER_STATUS.PROCESSING || order.status === ORDER_STATUS.AML_HOLD) &&
                          <div className={`absolute right-0 bottom-0 flex items-center justify-center w-3 h-3 bg-feedback-success-tint rounded-full z-20`} >
                            <PendingIcon className='fill-font-secondary w-2 h-2' />
                          </div>
                        }
                        {
                          (order.status === ORDER_STATUS.WAITING_FOR_DEPOSIT) &&
                          <div className={`absolute right-0 bottom-0 flex items-center justify-center w-3 h-3 bg-feedback-attention rounded-full z-20`} >
                            <WarningIcon className='fill-font-secondary w-2 h-2' />
                          </div>
                        }
                        {
                          (order.status === ORDER_STATUS.DEPOSIT_DECLINED) &&
                          <div className={`absolute right-0 bottom-0 flex items-center justify-center w-3 h-3 bg-feedback-warning rounded-full z-20`} >
                            <CancelIcon className='fill-font-secondary w-2 h-2' />
                          </div>
                        }
                      </div>
                      <div className="flex flex-col">
                        <label className="text-base font-medium text-font-primary">
                          {
                            {
                              [RAMPING.ON]: <>Bought {order.crypto}</>,
                              [RAMPING.OFF]: <>Sold {order.crypto}</>,
                            }[order.type]
                          }
                        </label>
                        <span className="text-sm font-medium text-font-primary">{order.createdTime}</span>
                      </div>
                    </div>
                    <div className="flex flex-col items-end">
                      <label className="text-base font-bold text-font-primary">
                        {
                          {
                            [RAMPING.ON]: <>{order.currencySymbol}{order.fiatAmount}</>,
                            [RAMPING.OFF]: <>{order.cryptoAmount} {order.crypto}</>,
                          }[order.type]
                        }
                      </label>
                      <span className="text-sm text-font-primary">
                        {
                          {
                            [RAMPING.ON]: <>{order.cryptoAmount} {order.crypto}</>,
                            [RAMPING.OFF]: <>{order.currencySymbol}{order.fiatAmount}</>,
                          }[order.type]
                        }
                      </span>
                    </div>
                  </div>
                </Link>
              ))
            }
          </InfiniteScroll>
        </div>
      </div>
    </>
  );
};

export default History;