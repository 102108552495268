import { AppActions } from 'store';

import { call, put } from 'redux-saga/effects';
import { customerServerApiClient } from 'utils/apiClient';

interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function* getFeeSaga(action: any) {
  try {
    const result: ResponseGenerator = yield call(
      async () => await customerServerApiClient.post('fee/fee', action.payload)
    );
    if (result.data) {
      yield put(AppActions.fee.getFeeSuccess(result.data));
    }
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.fee.getFeeFailure(error.response.data));
  }
}
