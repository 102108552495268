import { createSlice } from '@reduxjs/toolkit';
import { BusinessMember, IBusinessDetail, IDocumentType } from 'types';

interface IInitialBusinessSlice {
  documentTypes: IDocumentType[],
  gettingDocumentTypes: boolean,
  gotDocumentTypes: boolean,

  businessDetail: IBusinessDetail,
  gettingBusinessDetail: boolean,
  gotBusinessDetail: boolean,

  businessDocuments: any,
  gettingBusinessDocument: boolean,
  gotBusinessDocument: boolean,

  updatingBusinessDetail: boolean,
  updatedBusinessDetail: boolean,

  businessAddress: any,
  updatingBusinessAddress: boolean,
  updatedBusinessAddress: boolean,

  registeredBusinessAddress: any,
  gettingRegisteredBusinessAddress: boolean,
  gotRegisteredBusinessAddress: boolean,

  operatingAddress: any,
  gettingOperatingAddress: boolean,
  gotOperatingAddress: boolean,

  creatingBusinessMember: boolean,
  createdBusinessMember: boolean,

  businessMembers: BusinessMember[],
  gettingBusinessMembers: boolean,
  gotBusinessMembers: boolean,


  documents: any,
  creatingDocument: boolean,
  createdDocument: boolean,

  gettingDocuments: boolean,
  gotDocuments: boolean,

  error: any;
}

const businessSlice = createSlice({
  name: 'business',
  initialState: {
    documentTypes: [],
    gettingDocumentTypes: false,
    gotDocumentTypes: false,

    businessDetail: {
      businessName: '',
    },
    gettingBusinessDetail: false,
    gotBusinessDetail: false,

    businessDocuments: [],
    gettingBusinessDocument: false,
    gotBusinessDocument: false,

    updatingBusinessDetail: false,
    updatedBusinessDetail: false,

    businessAddress: {},
    updatingBusinessAddress: false,
    updatedBusinessAddress: false,

    registeredBusinessAddress: {},
    gettingRegisteredBusinessAddress: false,
    gotRegisteredBusinessAddress: false,

    operatingAddress: {},
    gettingOperatingAddress: false,
    gotOperatingAddress: false,

    creatingBusinessMember: false,
    createdBusinessMember: false,

    businessMembers: [],
    gettingBusinessMembers: false,
    gotBusinessMembers: false,

    creatingDocument: false,
    createdDocument: false,

    documents: [],
    gettingDocuments: false,
    gotDocuments: false,

    error: '',
  } as IInitialBusinessSlice,
  reducers: {
    getDocumentTypes(state) {
      state.gettingDocumentTypes = true;
      state.gotDocumentTypes = false;
    },

    getDocumentTypesSuccess(state, action) {
      state.gettingDocumentTypes = false;
      state.gotDocumentTypes = true;
      state.documentTypes = action.payload;
    },

    getDocumentTypesFailure(state, action) {
      state.gettingDocumentTypes = false;
      state.gotDocumentTypes = false;
      state.error = action.payload;
    },

    getBusinessDetail(state, action) {
      state.gettingBusinessDetail = true;
      state.gotBusinessDetail = false;
    },
    getBusinessDetailSuccess(state, action) {
      state.gettingBusinessDetail = false;
      state.gotBusinessDetail = true;
      state.businessDetail = action.payload;
    },
    getBusinessDetailFailure(state, action) {
      state.gettingBusinessDetail = false;
      state.gotBusinessDetail = false;
      state.error = action.payload;
    },

    updateBusinessDetail(state, action) {
      state.updatingBusinessDetail = true;
      state.updatedBusinessDetail = false;
    },
    updateBusinessDetailSuccess(state, action) {
      state.updatingBusinessDetail = false;
      state.updatedBusinessDetail = true;
      state.businessDetail = action.payload;
    },
    updateBusinessDetailFailure(state, action) {
      state.updatingBusinessDetail = false;
      state.updatedBusinessDetail = false;
      state.error = action.payload;
    },

    getBusinessDocument(state, action) {
      state.gettingBusinessDocument = true;
      state.gotBusinessDocument = false;
    },
    getBusinessDocumentSuccess(state, action) {
      state.gettingBusinessDocument = false;
      state.gotBusinessDocument = true;
    },
    getBusinessDocumentFailure(state, action) {
      state.gettingBusinessDocument = false;
      state.gotBusinessDocument = false;
    },

    updateBusinessAddress(state, action) {
      state.updatingBusinessAddress = true;
      state.updatedBusinessAddress = false;
    },
    updateBusinessAddressSuccess(state, action) {
      state.updatingBusinessAddress = false;
      state.updatedBusinessAddress = true;
      state.businessAddress = action.payload;
    },
    updateBusinessAddressFailure(state, action) {
      state.updatingBusinessAddress = false;
      state.updatedBusinessAddress = false;
      state.error = action.payload;
    },

    getRegisteredBusinessAddress(state, action) {
      state.gettingRegisteredBusinessAddress = true;
      state.gotRegisteredBusinessAddress = false;
    },
    getRegisteredBusinessAddressSuccess(state, action) {
      state.gettingRegisteredBusinessAddress = false;
      state.gotRegisteredBusinessAddress = true;
      state.registeredBusinessAddress = action.payload;
    },
    getRegisteredBusinessAddressFailure(state, action) {
      state.gettingRegisteredBusinessAddress = false;
      state.gotRegisteredBusinessAddress = false;
    },

    getOperatingAddress(state, action) {
      state.gettingOperatingAddress = true;
      state.gotOperatingAddress = false;
    },
    getOperatingAddressSuccess(state, action) {
      state.gettingOperatingAddress = false;
      state.gotOperatingAddress = true;
      state.operatingAddress = action.payload;
    },
    getOperatingAddressFailure(state, action) {
      state.gettingOperatingAddress = false;
      state.gotOperatingAddress = false;
    },

    createBusinessMember(state, action) {
      state.creatingBusinessMember = true;
      state.createdBusinessMember = false;
    },
    createBusinessMemberSuccess(state, action) {
      state.creatingBusinessMember = false;
      state.createdBusinessMember = true;
    },
    createBusinessMemberFailure(state, action) {
      state.creatingBusinessMember = false;
      state.createdBusinessMember = false;
    },

    getBusinessMember(state, action) {
      state.gettingBusinessMembers = true;
      state.gotBusinessMembers = false;
    },
    getBusinessMemberSuccess(state, action) {
      state.gettingBusinessMembers = false;
      state.gotBusinessMembers = true;
      state.businessMembers = [...action.payload];
    },
    getBusinessMemberFailure(state, action) {
      state.gettingBusinessMembers = false;
      state.gotBusinessMembers = false;
    },

    createBusinessDocument(state, action) {
      state.creatingDocument = true;
      state.createdDocument = false;
    },
    createBusinessDocumentSuccess(state, action) {
      state.creatingDocument = false;
      state.createdDocument = true;
    },
    createBusinessDocumentFailure(state, action) {
      state.creatingDocument = false;
      state.createdDocument = false;
    },

    getBusinessDocuments(state, action) {
      state.gettingDocuments = true;
      state.gotDocuments = false;
    },
    getBusinessDocumentsSuccess(state, action) {
      state.gettingDocuments = false;
      state.gotDocuments = true;
      state.documents = [...action.payload];
    },
    getBusinessDocumentsFailure(state, action) {
      state.gettingDocuments = false;
      state.gotDocuments = false;
    }
  },
});

export const actions = businessSlice.actions;
export const reducer = businessSlice.reducer;
