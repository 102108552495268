interface IProps {
  name?: string;
  className?: string;
  children?: React.ReactNode;
  onChange?: (value: boolean) => void;
}

const RadioButton: React.FC<IProps> = (props) => {
  const { name, className, children, onChange } = props;

  return (
    <div className={className}>
      <input
        type="radio"
        className="w-6 h-6 border border-surface-tertiary rounded-full mr-2"
        name={name}
        onChange={(e: any) => { onChange && onChange(e.target.checked); }}
      />
      {children}
    </div>
  );
};

export default RadioButton;