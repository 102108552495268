import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/common/buttons/Button";
import Header from "../../components/common/header/Header";
import CheckBox from "components/common/inputs/CheckBox";
import PasswordInput from "components/common/inputs/PasswordInput";
import { PaymentLogo1, PaymentLogo2, PaymentLogo3 } from "utils/icons";
import { PATHS } from "utils/consts";

const BankCardAuthentication: React.FC = () => {
  const navigate = useNavigate();

  const onClickContinue = () => {
    navigate(`${PATHS.SELL}${PATHS.CONFRIM_ORDER}`);
  }

  const onClickCancel = () => {
    navigate(`${PATHS.SELL}`)
  }

  return (
    <>
      <Header title="Authenticate Your Card" />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Authenticate Card</label>
        <p className="text-sm font-medium text-font-primary mt-2">Please enter the CVC number or security code in the field provided.</p>
        <div className="flex mt-8">
          <PasswordInput className="flex w-1/2 mr-2" label="CVC / Security Code" />
          <div className="flex w-1/2 items-center">
            <img src={PaymentLogo3} />
            <span className="text-base font-bold">**** 4242</span>
          </div>
        </div>
        <div className="flex items-center mt-8">
          <CheckBox className="mr-4" onChange={() => { }} />
          <span className="text-sm font-medium text-font-primary">
            Securely save a copy of this card on this device for faster checkout.
          </span>
        </div>
        <div
          className="absolute w-full px-2 left-0 mt-8"
        >
          <Button
            className="w-full"
            onClick={onClickContinue}
          >
            <>Continue</>
          </Button>
          <p className="text-md font-medium text-primary text-center mt-4" onClick={onClickCancel}>Cancel</p>
        </div>
      </div>
    </>
  );
}

export default BankCardAuthentication;