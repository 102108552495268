import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {
}

const UserIcon: React.FC<IProps> = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" fill="#D1D4E3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.0006 15.3077C17.0202 15.3077 17.8467 14.4811 17.8467 13.4615C17.8467 12.4419 17.0202 11.6154 16.0006 11.6154C14.981 11.6154 14.1544 12.4419 14.1544 13.4615C14.1544 14.4811 14.981 15.3077 16.0006 15.3077ZM16.0006 16.9231C17.9124 16.9231 19.4621 15.3733 19.4621 13.4615C19.4621 11.5498 17.9124 10 16.0006 10C14.0888 10 12.5391 11.5498 12.5391 13.4615C12.5391 15.3733 14.0888 16.9231 16.0006 16.9231Z" fill="#A2A4B0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.4448 20.3855H19.5583C19.3759 19.3547 19.0162 18.5058 18.5027 17.9246C17.9959 17.3509 17.2443 16.924 16.0016 16.924C14.7588 16.924 14.0072 17.3509 13.5004 17.9246C12.9869 18.5058 12.6272 19.3547 12.4448 20.3855ZM12.3079 20.3855C12.3072 20.3855 12.3069 20.3854 12.3069 20.3854L12.3079 20.3855ZM10.8078 20.3909C11.1968 17.7385 12.6395 15.3086 16.0016 15.3086C19.3636 15.3086 20.8063 17.7385 21.1953 20.3909C21.3248 21.2736 20.586 22.0009 19.6938 22.0009H12.3093C11.4171 22.0009 10.6783 21.2736 10.8078 20.3909Z" fill="#A2A4B0" />
    </svg>
  );
};

export default UserIcon;