import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { PATHS } from "utils/consts";
import CookiePreference from "./CookiePreference";
import DefaultCurrency from "./DefaultCurrency";
import Language from "./Language";
import PaymentMethod from "./PaymentMethod";
import SettingList from "./SettingList";
import DefaultAddress from "./DefaultAddress";

export const Settings: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <Outlet />
      <Routes location={location}>
        <Route>
          <Route path={PATHS.HOME} element={<SettingList />} />
          <Route path={PATHS.PAYMENT_METHOD} element={<PaymentMethod />} />
          <Route path={PATHS.LANGUAGE} element={<Language />} />
          <Route path={PATHS.DEFAULT_CURRENCY} element={<DefaultCurrency />} />
          <Route path={PATHS.DEFAULT_ADDRESS} element={<DefaultAddress />} />
          <Route path={PATHS.COOKIE_PREFERENCE} element={<CookiePreference />} />
        </Route>
      </Routes>
    </>
  );
};