import { currencyApi } from 'api/currency.api';
import { call, put } from 'redux-saga/effects';
import { AppActions } from 'store';

export function* getCurrenciesSaga(action: any) {
  try {
    const { currencies, offRampCurrencies } = yield call(
      async () => await currencyApi.getCurrencies()
    );
    yield put(AppActions.currency.getCurrenciesSuccess({
      currencies,
      offRampCurrencies,
    }));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.currency.getCurrenciesFailure(error.response.data));
  }
}