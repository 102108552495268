import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import Header from "components/common/header/Header";
import { PlatformContext } from "contexts";
import { PATHS } from "utils/consts";

import { ArrowRightSvg, BinSvg, CookieSvg, DollarInCircleSvg, LanguageSvg, PaymentSvg } from "../../utils/icons";
import CloseAccountPopup from "components/settings/CloseAccountPopup";

const settingsList = [
  // {
  //   path: "",
  //   icon: PrivacySvg,
  //   title: "Security",
  //   unLoggedShow: false,
  // },
  // {
  //   path: PATHS.PAYMENT_METHOD,
  //   icon: PaymentSvg,
  //   title: "Payment Methods",
  //   unLoggedShow: false,
  // },
  {
    path: PATHS.DEFAULT_CURRENCY,
    icon: DollarInCircleSvg,
    title: "Default Currency",
    subTitle: "currency",
    unLoggedShow: true,
  },
  {
    path: PATHS.DEFAULT_ADDRESS,
    icon: PaymentSvg,
    title: "Default Billing Address",
    subTitle: "billing address",
    unLoggedShow: true,
  },
  // {
  //   path: PATHS.LANGUAGE,
  //   icon: LanguageSvg,
  //   title: "Language",
  //   subTitle: "language",
  //   unLoggedShow: true,
  // },
  // {
  //   path: PATHS.COOKIE_PREFERENCE,
  //   icon: CookieSvg,
  //   title: "Cookie Preferences",
  //   unLoggedShow: true,
  // },
  {
    path: "",
    icon: BinSvg,
    title: "Close Account",
    unLoggedShow: false,
  }
]

const SettingList: React.FC = () => {
  const { getLoggedIn, currency, language } = useContext(PlatformContext);
  const [isOpen, setOpen] = useState<boolean>(false);

  const subTitle: {
    [key: string]: string,
  } = {
    currency: currency,
    language,
  }

  const closeAccount = () => {
    setOpen(false);
  }

  return (
    <>
      <Header title="Settings" />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Settings</label>
        <ul className="mt-4">
          {
            settingsList.map(el => {
              if (getLoggedIn() || el.unLoggedShow) {
                return (
                  <li
                    key={el.title}
                    className="mb-2"
                  >
                    <Link
                      className="flex justify-between items-center bg-surface-secondary rounded-lg p-5 w-full"
                      to={`${PATHS.SETTINGS}${el.path}`}
                      onClick={() => { el.title === "Close Account" && setOpen(true) }}
                    >
                      <div className="flex items-center mt-2">
                        <img src={el.icon} />
                        <span className="text-base font-medium text-font-primary ml-4">{el.title}</span>
                        {
                          !!el.subTitle &&
                          <span className="text-sm font-medium text-font-subtle ml-4">{subTitle[el.subTitle]}</span>
                        }
                      </div>
                      <img src={ArrowRightSvg} />
                    </Link>
                  </li>
                )
              }
            })
          }
        </ul>
      </div>
      {
        isOpen &&
        <CloseAccountPopup
          onConfirm={closeAccount}
          onClose={() => { setOpen(false) }}
        />
      }
    </>
  );
}

export default SettingList;