import Header from "components/common/header/Header";
import { SelectCurrency } from "components/common/selects";
import { PlatformContext } from "contexts";
import { useContext } from "react";

const DefaultCurrency: React.FC = (props) => {
  const { currency, saveCurrency } = useContext(PlatformContext);

  return (
    <>
      <Header title="Default Currency" />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Default Currency</label>
        <SelectCurrency
          type={0}
          className="mt-4"
          currency={currency}
          onChange={(currency) => saveCurrency(currency.code)}
        />
      </div>
    </>
  );
};

export default DefaultCurrency;