import { useNavigate } from "react-router-dom";
import { CheckGreenSvg } from "utils/icons";
import { BusinessMemberType, PATHS } from "utils/consts";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlatformContext } from "contexts";
import { AppActions, RootState } from "store";
import BinIcon from "assets/icons/BinIcon";
import ProfileIcon from "assets/icons/ProfileIcon";
import UserIcon from "assets/icons/UserIcon";
import IconButton from "components/common/buttons/IconButton";
import Header from "components/common/header/Header";
import { BusinessMember } from "types";

const Director: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getUser } = useContext(PlatformContext);
  const { businessMembers } = useSelector((root: RootState) => root.business);

  useEffect(() => {
    const user = getUser();
    if (user?.customerId)
      dispatch(AppActions.business.getBusinessMember({
        customerId: user?.customerId
      }));
  }, []);

  const onClickAddDirector = () => {
    navigate(`${PATHS.BUSINESS}${PATHS.DIRECTOR}/new?memberType=${BusinessMemberType.DIRECTOR}`);
  }

  const onClickAddShareholder = () => {
    navigate(`${PATHS.BUSINESS}${PATHS.DIRECTOR}/new?memberType=${BusinessMemberType.SHAREHOLDER}`);
  }

  const onPreview = (id: string) => {
    navigate(`${PATHS.BUSINESS}${PATHS.DIRECTOR}/${id}`);
  }

  return (
    <>
      <Header title='Director / Shareholders' />
      <div className="flex flex-col h-full p-4 mt-4">
        <label className="text-xl font-bold text-font-primary">Directors</label>
        {
          businessMembers && businessMembers.filter((el: BusinessMember) => el.memberType == BusinessMemberType.DIRECTOR).map((el: BusinessMember) =>
            <div key={el.id} className="rounded-xl border border-font-subtle p-4 mt-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <UserIcon />
                  <p className="text-base font-medium text-font-primary ml-4">{el.firstName} {el.lastName}</p>
                  <img className="h-6 w-6 border border-feedback-success border-2 rounded-full ml-4" src={CheckGreenSvg} />
                </div>
                <div className="flex">
                  <button className="mr-4">
                    <ProfileIcon onClick={() => onPreview(el.id)} />
                  </button>
                  <button>
                    <BinIcon fill="#7F818A" />
                  </button>
                </div>
              </div>
            </div>
          )
        }

        <div className="flex justify-center">
          <IconButton className="flex text-sm font-medium text-font-primary px-4 py-2 w-fit mt-4 mb-8" onClick={onClickAddDirector}>
            + Add Director
          </IconButton>
        </div>

        <label className="text-xl font-bold text-font-primary">Shareholders</label>

        {
          !!businessMembers.length && businessMembers.filter((el: any) => el.memberType == BusinessMemberType.SHAREHOLDER).map((el: any) =>
            <div key={el.id} className="rounded-xl border border-font-subtle p-4 mt-4">
              <div className="flex justify-between items-center">
                <div className="flex items-center">
                  <UserIcon />
                  <p className="text-base font-medium text-font-primary ml-4">{el.firstName} {el.lastName}</p>
                  <img className="h-6 w-6 border border-feedback-success border-2 rounded-full ml-4" src={CheckGreenSvg} />
                </div>
                <div className="flex">
                  {/* <button className="mr-4" onClick={onClickEdit}>
                    <EditIcon fill="#7F818A" />
                  </button> */}
                  <button className="mr-4" onClick={() => onPreview(el.id)}>
                    <ProfileIcon />
                  </button>
                  <button>
                    <BinIcon fill="#7F818A" />
                  </button>
                </div>
              </div>
              {/* <div className="p-2 bg-feedback-attention-light rounded-xl mt-2">
                <p className="text-sm font-medium text-font-primary">Complete profile now.</p>
              </div> */}
            </div>
          )
        }
        <div className="flex flex-col items-center">
          {
            !businessMembers.length &&
            <p className="flex text-md font-medium text-font-subtle mt-4">You don’t have any shareholders.</p>
          }

          <IconButton className="flex text-sm font-medium text-font-primary px-4 py-2 w-fit mt-4 mb-8" onClick={onClickAddShareholder}>
            + Add Shareholder
          </IconButton>
        </div>
      </div>
    </>
  );
};

export default Director;