// @ts-ignore
import { Veriff } from "@veriff/js-sdk";
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk';
import { useEffect, useContext, useState, useRef, ReactElement } from "react";
import Header from "../../common/header/Header";
import { ACTION_STATUS, PATHS, USER_KYC_STATUS, VERIFF_DISPLAY, COOKIES } from "utils/consts";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { useNavigate, useSearchParams } from "react-router-dom";
import { finishKYC, verifyUserKycStatusByUuid } from "store/slices/userSlice";
import { Buffer } from 'buffer';
import Button from "components/common/buttons/Button";
import WarningIcon from "assets/icons/WarningIcon";
import { CheckGreenSvg, FailureSvg, SuccessSvg, WarningSvg } from "utils/icons";
import { destroyCookie } from "nookies";
import { PlatformContext } from 'contexts/platformContext';




interface IProps {
  //children: JSX.Element,
  displayImg?: string;
  displayMsg?: string;
  btnLabel?: string;
}

const VeriffDisplay: React.FC<IProps> = (props) => {

  const { resetSession } = useContext(PlatformContext);

  const navigate = useNavigate();

  const onClickHome = () => {
    resetSession();
    //destroyCookie(null, COOKIES.JWT);
    //onClose();
    window.location.href = PATHS.BUY;
  };

  let { displayMsg, displayImg, btnLabel } = props;

  return (
    <>
      <div className="flex mt-4">
        <img src={displayImg} />
      </div>
      <div className="flex mt-4 items-center">
        <span className="text-base font-medium text-font-primary ml-4">
          {/* We are verifying your kyc status, please be patient. */}
          {displayMsg}
        </span>
      </div>
      <Button
        className="w-full mt-8"
        onClick={onClickHome}
      ><>{ btnLabel?btnLabel:'Home' }</></Button>
    </>
  )

}

export default VeriffDisplay;

