import Header from "components/common/header/Header";
import { BusinessMemberType, BusinessMemberTypeName, HeaderButtonType, flagImages } from "utils/consts";
import SelectButton from "components/common/buttons/SelectButton";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import SelectCountryPopup from "components/common/popups/SelectCountryPopup";
import Input from "components/common/inputs/Input";
import PhoneCountryCode from "components/common/inputs/PhoneCountryCode";
import { useParams, useSearchParams } from "react-router-dom";
import { PlatformContext } from "contexts";
import { BusinessMember } from "types";

const DirectorProfile: React.FC = () => {
  const dispatch = useDispatch();
  const { id: directorId } = useParams();
  const [searchParams] = useSearchParams();
  const [country, setCountry] = useState<string>('FR');
  const [openCountrySelect, setOpenCountrySelect] = useState<boolean>(false);
  const { businessMembers } = useSelector((root: RootState) => root.business);
  const { countries } = useSelector((root: RootState) => root.country);

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("+44");
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [birthday, setBirthday] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [postCode, setPostCode] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [businessMember, setBusinessMember] = useState<BusinessMember>();
  const [memberType, setMemberType] = useState<BusinessMemberType>(BusinessMemberType.DIRECTOR);
  const { getUser } = useContext(PlatformContext);

  useEffect(() => {
    dispatch(AppActions.country.getCountry({}));
  }, []);

  useEffect(() => {
    setMemberType(+(searchParams.get('memberType') || BusinessMemberType.DIRECTOR) as BusinessMemberType);
  }, [searchParams]);

  useEffect(() => {
    const user = getUser();
    if (directorId && user) {
      dispatch(AppActions.business.getBusinessMember({
        customerId: user.customerId,
      }))
    }
  }, [directorId]);

  useEffect(() => {
    setBusinessMember(businessMembers.find((el: BusinessMember) => el.id === directorId));
  }, [directorId, businessMembers]);

  useEffect(() => {
    if (businessMember) {
      setMemberType(businessMember.memberType);
      setFirstName(businessMember.firstName);
      setLastName(businessMember.lastName);
      setBirthday((businessMember.birthday || '').split('T')[0]);
      setEmail(businessMember.email);
      setCountry(businessMember.country);
      setAddress(businessMember.address);
      setCity(businessMember.city);
      setPostCode(businessMember.postCode);
      setCountryCode(businessMember.countryCode);
      setPhoneNumber(businessMember.phoneNumber);
    }
  }, [businessMember])

  const onClickSubmit = () => {
    const user = getUser();
    if (user) {
      dispatch(AppActions.business.createBusinessMember({
        customerId: user.customerId,
        memberType,
        firstName,
        lastName,
        birthday,
        email,
        countryCode,
        phoneNumber,
        address,
        city,
        postCode,
        country
      }));
    }
  }

  return (
    <>
      <Header
        title={`${BusinessMemberTypeName[memberType]} Profile ${businessMember?.firstName ? `- ${businessMember?.firstName}` : ''} ${businessMember?.lastName || ''}`}
        button={!businessMember ? HeaderButtonType.SUBMIT : undefined}
        onClickButton={onClickSubmit}
      />
      <div className="flex flex-col p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">
          {BusinessMemberTypeName[memberType]} Profile {businessMember?.firstName ? `- ${businessMember?.firstName}` : ''} {businessMember?.lastName}
        </label>
        <div className="mt-8 mb-8">
          <label className="text-xl font-bold text-font-primary">Personal Detail</label>
          <div className="flex mt-4">
            <Input
              label="First Name"
              value={firstName}
              onChange={value => setFirstName(value)}
            />
            <Input
              className="ml-2"
              label="Last Name"
              value={lastName}
              onChange={value => setLastName(value)}
            />
          </div>

          <Input
            type="date"
            className="mt-2"
            label="Birthday"
            value={birthday}
            onChange={value => setBirthday(value)}
          />

          <p className="text-sm font-medium text-font-primary mt-4">Want to change your personal detail?</p>
          <p className="text-sm font-medium text-primary mb-8">Contact customer service</p>


          <label className="text-xl font-bold text-font-primary">Email and Phone Number</label>
          <Input
            className="mt-4"
            label="Email"
            value={email}
            onChange={value => setEmail(value)}
          />
          <div className="flex mt-2 mb-8">
            <PhoneCountryCode className="w-60" onChangeCode={(value) => { setCountryCode(value); }} />
            <Input
              className="w-full ml-2"
              label="Phone Number"
              onChange={(value: string) => { setPhoneNumber(value); }}
            />
          </div>

          <label className="text-xl font-bold text-font-primary">Address</label>
          <Input
            className="mt-4"
            label="Address"
            value={address}
            onChange={value => setAddress(value)}
          />
          <div className="flex justify-content mt-2">
            <Input
              className="mr-2"
              label="Town / City"
              value={city}
              onChange={value => setCity(value)}
            />
            <Input
              label="Post Code / Zip Code"
              value={postCode}
              onChange={value => setPostCode(value)}
            />
          </div>

          <SelectButton
            className="mt-2 mb-8"
            label="Country"
            text={countries.find(el => el.code === 'FR')?.name}
            svgPath={flagImages['FR']}
            onClick={() => setOpenCountrySelect(true)}
            flag
          />
        </div>
      </div>
      {
        openCountrySelect &&
        <SelectCountryPopup
          country={country}
          onChange={(value) => {
            setCountry(value);
            setOpenCountrySelect(true);
          }}
          onClose={
            () => setOpenCountrySelect(false)
          }
        />
      }
    </>
  );
};

export default DirectorProfile;