import React, { ReactNode, useState } from 'react';
import { SwitchOff, SwitchOn } from 'utils/icons';

interface IProps {
  title: string,
  children: ReactNode,
  selected?: boolean,
  always?: boolean,
  onChange?: (value: boolean) => void,
}

const Accordion: React.FC<IProps> = (props) => {
  const { title, children, selected, always, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border-t">
      <div className="flex items-center justify-between py-4 cursor-pointer" onClick={handleClick} >
        <div className="flex items-center">
          <svg className={`w-3 h-3 transition-transform transform ${isOpen ? '' : 'rotate-180'}`} width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12.2761 6.95758L5.98455 0.665987L0.0576782 6.98798L1.03039 7.8999L6.01545 2.58251L11.3333 7.90039L12.2761 6.95758Z" fill="#FEFEFE" />
          </svg>
          <h3 className="text-sm font-bold font-medium ml-4" > {title} </h3>
        </div>
        <div>
          {
            always ?
              <span className="text-sm text-primary-light">Always Active</span> :
              <img src={selected ? SwitchOn : SwitchOff} alt="switch" onClick={() => { onChange && onChange(!selected); }} />
          }
        </div>
      </div>
      {isOpen && <div className="font-s px-4 pb-4"> {children} </div>}
    </div>
  )
}

export default Accordion;

