// @ts-ignore
import { Buffer } from 'buffer';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from "store";
import { setVerifyUserKycStatusByUuidStatus, verifyUserKycStatusByUuid } from "store/slices/userSlice";
import { ACTION_STATUS, USER_KYC_STATUS, VERIFF_DISPLAY } from "utils/consts";
import { CongratsSvg, WarningSvg } from "utils/icons";
import VeriffCongrats from "./VeriffCongrats";
import VeriffDisplay from "./VeriffDisplay";
import VeriffSession from "./VeriffSession";





const VeriffFromInvitationLink: React.FC = () => {


  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [currentUserKycStatus, setCurrentUserKycStatus] = useState<USER_KYC_STATUS>();
  const [currentVeriffUuid, setCurrentVeriffUuid] = useState<string>("");
  const [currentDisplay, setCurrentDisplay] = useState<VERIFF_DISPLAY>(VERIFF_DISPLAY.CHECK);

  const { verifyUserKycStatusByUuidStatus, currentUser } = useSelector((state: RootState) => state.user)


  useEffect(() => {

    const veriffUuidFromUrl = searchParams.get("veriffUuid");// by url =>base64URL
    if (veriffUuidFromUrl) {
      const decode = Buffer.from(veriffUuidFromUrl, 'base64').toString('binary');
      dispatch(verifyUserKycStatusByUuid({ veriffUuid: decode }));
      setCurrentVeriffUuid(decode)
    } else {
      setCurrentDisplay(VERIFF_DISPLAY.FAILURE);
    }
  }
    , []
  )


  useEffect(() => {
    if (verifyUserKycStatusByUuidStatus === ACTION_STATUS.SUCCESS && currentUser) {
      setCurrentUserKycStatus(currentUser.kycStatus);
    }

    if (verifyUserKycStatusByUuidStatus === ACTION_STATUS.FAILURE) {
      setCurrentDisplay(VERIFF_DISPLAY.FAILURE);
      dispatch(setVerifyUserKycStatusByUuidStatus(ACTION_STATUS.NONE));
    }

  }, [verifyUserKycStatusByUuidStatus]);


  useEffect(() => {
    if (verifyUserKycStatusByUuidStatus === ACTION_STATUS.SUCCESS) {

      if (currentUserKycStatus == null) {
        //user not found
        setCurrentDisplay(VERIFF_DISPLAY.USER_NOT_FOUND);
      } else {
        if (currentUserKycStatus == USER_KYC_STATUS.NOT_TAKEN) {
          setCurrentDisplay(VERIFF_DISPLAY.SESSION);
          dispatch(setVerifyUserKycStatusByUuidStatus(ACTION_STATUS.NONE));
          return;
        }
        if (currentUserKycStatus == USER_KYC_STATUS.PENDING_BY_ID || currentUserKycStatus == USER_KYC_STATUS.PENDING_BY_AML) {
          setCurrentDisplay(VERIFF_DISPLAY.PENDING);
          return;
        }
        if (currentUserKycStatus == USER_KYC_STATUS.REJECTED) {

          setCurrentDisplay(VERIFF_DISPLAY.REJECTED);
          return;
        }
        if (currentUserKycStatus == USER_KYC_STATUS.APPROVED) {
          setCurrentDisplay(VERIFF_DISPLAY.APPROVED);
          return;
        }
      }
    }
  }, [currentUserKycStatus]);

  const nextStepOfVeriffSession = () => {
    setCurrentDisplay(VERIFF_DISPLAY.CONGRATS)
  }

  return (
    <>
      {
        {
          [VERIFF_DISPLAY.SESSION]:
            <VeriffSession
              veriffUuid={currentVeriffUuid}
              nextStep={nextStepOfVeriffSession}
            />,
          [VERIFF_DISPLAY.CHECK]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="We are verifying your submission, please be patient."
            />,
          [VERIFF_DISPLAY.PENDING]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Your submission is under review. You will be contacted shortly with a decision."
            />,
          [VERIFF_DISPLAY.REJECTED]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Your kyc verification is rejected, please contact the support team."
            />,
          [VERIFF_DISPLAY.APPROVED]:
            <VeriffDisplay
              displayImg={CongratsSvg}
              displayMsg="Your submission is already approved. Now, you can login to the platform."
              btnLabel="Proceed to Login"
            />,

          [VERIFF_DISPLAY.USER_NOT_FOUND]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Invited user not found, please contact the support team."
            />,
          [VERIFF_DISPLAY.CONGRATS]:
            <VeriffCongrats />,
          [VERIFF_DISPLAY.FAILURE]:
            <VeriffDisplay
              displayImg={WarningSvg}
              displayMsg="Unexpected technical issue, please contact the support team."
            />
        }
        [currentDisplay]
      }
    </>
  )
};

export default VeriffFromInvitationLink;