import { quoteApi } from 'api/quote.api';
import { AppActions } from 'store';

import { call, put } from 'redux-saga/effects';

export function* getQuoteSaga(action: any) {
  try {
    const { ...data } = yield call(
      async () => await quoteApi.getQuote(action.payload)
    );
    yield put(AppActions.quote.getQuoteSuccess(data));
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.quote.getQuoteFailure(error.response.data));
  }
}
