interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

const CheckIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#3BB861"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M21.3644 8.46498L10.0507 19.7787L3.58571 13.3137L5.20195 11.6975L10.0507 16.5462L19.7481 6.84873L21.3644 8.46498Z" />
    </svg>
  );
};

export default CheckIcon;