import { AppActions } from 'store';

import { call, put } from 'redux-saga/effects';
import { customerServerApiClient } from 'utils/apiClient';

interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

export function* getCardsSaga(action: any) {
  try {
    const result: ResponseGenerator = yield call(
      async () => await customerServerApiClient.get('acquirer/checkout/card-tokens', action.payload)
    );
    if (result.data) {
      yield put(AppActions.card.getCardsSuccess(result.data.tokenList));
    }
  } catch (error: any) {
    if (error.response)
      yield put(AppActions.card.getCardsFailure(error.response.data));
  }
}
