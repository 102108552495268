import { CancelSvg } from "utils/icons";
import { SelectCurrency } from "../selects";

interface IProps {
  currency: string;
  type: number;
  onChange: (currency: string) => void;
  onClose: () => void;
}

const SelectCurrencyPopup: React.FC<IProps> = (props) => {
  const { currency, type, onChange, onClose } = props;

  return (
    <div className="flex flex-col fixed left-0 top-0 w-full h-screen z-50 bg-surface-primary p-4">
      <div className="flex justify-between items-end mb-4">
        <label className="text-lg font-bold text-font-primary">Select a Currency</label>
        <img className="" src={CancelSvg} onClick={() => { onClose(); }} />
      </div>
      <SelectCurrency
        type={type}
        currency={currency}
        onChange={(currency) => {
          onChange(currency.code);
          onClose();
        }}
      />
    </div>
  );
};

export default SelectCurrencyPopup;