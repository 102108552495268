import { useEffect, useRef, useState } from "react";

interface IProps {
  className?: string;
  onChange?: (value: string) => void;
}

const CodeVerificationInput: React.FC<IProps> = (props) => {
  const { className, onChange } = props;
  const [values, setValues] = useState<any>(['', '', '', '', '', '']);
  const refs: any = [];
  const input1 = useRef<HTMLInputElement>(null);
  refs.push(input1);
  const input2 = useRef<HTMLInputElement>(null);
  refs.push(input2);
  const input3 = useRef<HTMLInputElement>(null);
  refs.push(input3);
  const input4 = useRef<HTMLInputElement>(null);
  refs.push(input4);
  const input5 = useRef<HTMLInputElement>(null);
  refs.push(input5);
  const input6 = useRef<HTMLInputElement>(null);
  refs.push(input6);

  useEffect(() => {
    onChange && onChange(values.join(''));
  }, [values]);

  const onChangeInput = (index: number, val: string) => {
    if (val === '') {
      const temp = [...values];
      temp[index] = '';
      setValues(temp);      
      refs[(index - 1) >= 0 ? index - 1 : 0].current?.focus();
    } else if ('1234567890'.includes(val[val.length - 1])) {
      const temp = [...values];
      temp[index] = (val[val.length - 1]);
      setValues(temp);
      refs[(index + 1) < 6 ? index + 1 : 5].current?.focus();
    }
  };

  const onPaste = (index: number, code: string) => {
    const temp = [...values];
    for (let i = index; i < 6; i++) {
      temp[i] = code[i - index] ? code[i - index] : temp[i];
    }
    setValues(() => {
      refs[(index + code.length) < 6 ? index + code.length - 1 : 5].current?.focus();
      return temp;
    });
  };

  return (
    <div className={`flex justify-between ${className}`}>
      {
        refs.map((ref: any, index: number) => (
          <input
            key={index}
            className={`block w-14 h-16 bg-surface-secondary text-xl text-center font-medium text-font-primary rounded-xl border border-surface-secondary outline-none focus:ring-primary focus:border-primary duration-200`}
            ref={ref}
            value={values[index]}
            onChange={(e: any) => { onChangeInput(index, e.target.value); }}
            onPaste={(e: any) => { onPaste(index, e.clipboardData.getData('Text')); }}
          />
        ))
      }
    </div>
  );
};

export default CodeVerificationInput;