import { createSlice } from '@reduxjs/toolkit';
import { IQuote } from 'types';

interface IInitialQuoteSlice {
  quote: IQuote;
  gettingQuote: boolean;
  gotQuote: boolean;
  error: any;
}

const quoteSlice = createSlice({
  name: 'quote',
  initialState: {
    quote: {
      liquidityProvider: '',
      liquidityQuotes: 0,
      subscriptionFee: 0,
    },
    error: '',
  } as IInitialQuoteSlice,
  reducers: {
    getQuote(state, action) {
      state.gettingQuote = true;
      state.gotQuote = false;
    },

    getQuoteSuccess(state, action) {
      state.gettingQuote = false;
      state.gotQuote = true;
      state.quote = { liquidityQuotes: action.payload.liquidityQuotes, liquidityProvider: action.payload.liquidityPool, subscriptionFee: 0 };
      state.quote.liquidityProvider = action.payload.liquidityPool;
    },

    getQuoteFailure(state, action) {
      state.gettingQuote = false;
      state.gotQuote = false;
      state.error = action.payload;
    },
  },
});

export const actions = quoteSlice.actions;
export const reducer = quoteSlice.reducer;
