import { customerServerApiClient, lambdaServerApiClient } from "utils/apiClient";
import { getClient } from "utils/apiClient/client";
import { merchantId, saasUserCorporationId, UserType } from "utils/consts";

export const userApi = {
  async signUp(data: any) {
    const res = await customerServerApiClient.post('auth/sign-up', data);
    const resBody = res.data;
    return resBody;
  },
  async signUpResend(data: any) {
    const res = await customerServerApiClient.post('auth/sign-up/code', data);
    const resBody = res.data;
    return resBody;
  },
  async signUpVerify(data: any) {
    const res = await customerServerApiClient.post('auth/sign-up/verify-code', data);
    const resBody = res.data;
    return resBody;
  },
  async signIn(data: any) {
    const res = await customerServerApiClient.post('auth/sign-in', data);
    const resBody = res.data;
    return resBody;
  },
  async signInResend(data: any) {
    const res = await customerServerApiClient.post('auth/sign-in/code', data);
    const resBody = res.data;
    return resBody;
  },
  async signInVerify(data: any) {
    const res = await customerServerApiClient.post('auth/sign-in/verify-code', data);
    const resBody = res.data;
    return resBody;
  },
  async forgotPassword(data: any) {
    const res = await customerServerApiClient.post('auth/resetpassword/link', {
      email: data.email,
      saasUserCorporationId: saasUserCorporationId,
      merchantId: merchantId,
      userType: UserType.INDIVIDUAL_CUSTOMER,
    });
    const resBody = res.data;
    return resBody;
  },
  async verifyForgotPassword(data: any) {
    const { token } = data;
    const res = await customerServerApiClient.post(
      `auth/resetpassword/check`, {
        token,
      }
    );
    const resBody = res.data;
    return resBody;
  },
  async resetPassword(data: any) {
    const res = await customerServerApiClient.post('auth/resetpassword', data);
    const resBody = res.data;
    return resBody;
  },
  async generateToken(data: any) {
    const res = await customerServerApiClient.post('user/generatetoken', data);
    const resBody = res.data;
    return resBody;
  },

  async finishKYC(data: any) {
    const { kycVeriffUuid } = data;
    const res = await customerServerApiClient.post(`kyc/finish/${kycVeriffUuid}`, data);
    const resBody = res.data;
    return resBody;
  },

  async verifyUserKycStatus(data: any) {
    const { token, veriffUuid } = data;
    const res = await customerServerApiClient.get(`kyc/${veriffUuid}`);
    const resBody = res.data;
    return resBody;
  },

  async ssoLogin(data: any) {
    const res = await lambdaServerApiClient.post(`sso/login`, data);
    const resBody = res.data;
    return resBody;
  },
};
