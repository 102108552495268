import { customerServerApiClient } from 'utils/apiClient';
import { saasUserCorporationId } from 'utils/consts';

export const cryptoApi = {
  async getCryptos() {
    const res = await customerServerApiClient.get('crypto/supported', {
      params: {
        saasUserCorporationId: saasUserCorporationId
      }
    });
    const resBody = res.data;
    return resBody;
  },

  async checkTravelRules(data: any) {
    const res = await customerServerApiClient.post('', data);
    const resBody = res.data;
    return resBody;
  }
};