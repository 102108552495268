import { useNavigate } from "react-router-dom";
import Button from "components/common/buttons/Button";
import Header from "components/common/header/Header";
import { PATHS, SIZE } from "utils/consts";
import CheckBox from "components/common/inputs/CheckBox";
import { useContext, useEffect, useState } from "react";
import RadioButton from "components/common/inputs/RadioButton";
import Input from "components/common/inputs/Input";
import { useDispatch, useSelector } from "react-redux";
import { AppActions, RootState } from "store";
import { PlatformContext } from "contexts";
import { getFiat } from "utils/supports";

const ConfirmWalletAddress: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [isOwner, setOwner] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const [physicalAddress, setPhysicalAddress] = useState<string>('');
  const [walletHost, setWalletHost] = useState<string>('');
  const [entity, setEntity] = useState<string>('');

  const { crypto, currency, user, getUser } = useContext(PlatformContext);
  const { walletAddress } = useSelector((state: RootState) => state.user);
  const { amount } = useSelector((state: RootState) => state.crypto);
  const { quote } = useSelector((state: RootState) => state.quote);
  const { fee } = useSelector((state: RootState) => state.fee);

  useEffect(() => {
    getUser();
  }, []);

  const onClickContinue = () => {
    dispatch(AppActions.order.createOrder({
      customerWalletAddress: walletAddress,
      type: 0, // ON RAMP
      currency: currency,
      fiatAmount: getFiat(amount, quote, fee.fee, 0),
      crypto: crypto,
      cryptoAmount: amount,
      liquidityQuote: quote.liquidityQuotes,
      liquidityProvider: quote.liquidityProvider,
      processingFee: fee.fee,

      beneficiaryName: isOwner ? `${user?.firstName} ${user?.lastName}` : fullName,
      beneficiaryAccountNumber: walletAddress,
      beneficiaryOwnershipProof: {
        type: 'N/A',
        proof: 'N/A',
      },

      next: () => {
        navigate(`${PATHS.BUY}${PATHS.CARD_INFO}`);
      }
    }));
  };

  return (
    <>
      <Header title="Confirm Your Wallet" backTo={PATHS.BUY} />
      <div className="flex flex-col h-full justify-between p-4 mt-4">
        <div className="flex flex-col mt-4">
          <h1 className="text-2xl font-bold text-font-primary text-center">Additional Information Required</h1>
          <p className="text-sm font-medium text-font-primary text-center mt-4">Due to regulations, we're required to collect additional information about the recipient</p>

          <p className="text-sm font-bold text-font-primary mt-8">Transfering to an account you own?</p>
          <div className="flex items-center mt-1">
            <CheckBox className="mr-4" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOwner(e.target.checked)} />
            <p className="text-sm font-bold text-font-primary">Yes, I own this account</p>
          </div>

          {
            !isOwner &&
            <>
              <p className="text-sm font-bold text-font-primary mt-8 mb-1">Where is recipient's wallet hosted?</p>
              <select
                className="bg-surface-secondary text-base font-medium text-font-primary rounded-xl border border-surface-secondary outline-none focus:ring-primary focus:border-primary duration-200"
                onChange={(event) => setWalletHost(event.target.value)}
              >
                <option selected>Select recipent's VASP</option>
                <option value="Coinbase">Coinbase</option>
                <option value="Binance">Binance</option>
              </select>

              <p className="text-sm font-bold text-font-primary mt-4 mb-1">Select recipient entity</p>
              <div className="flex">
                <RadioButton
                  name="recipient-entity"
                  onChange={() => setEntity('Natural')}
                >
                  Natural Person
                </RadioButton>
                <RadioButton
                  className="ml-4"
                  name="recipient-entity"
                  onChange={() => setEntity('Legal')}
                >
                  Legal Person
                </RadioButton>
              </div>

              <p className="text-sm font-bold text-font-primary mt-4 mb-1">Recipient's full name</p>
              <Input
                placeholder="Enter name"
                size={SIZE.md}
                onChange={(value) => setFullName(value)}
              />

              <p className="text-sm font-bold text-font-primary mt-4 mb-1">Recipient's physical address (street, city, postal, country)</p>
              <Input
                placeholder="Enter physical address"
                size={SIZE.md}
                onChange={(value) => setPhysicalAddress(value)}
              />
            </>
          }
        </div>
        <div className="w-full mt-4">
          <div className="flex items-start mb-4">
            <CheckBox className="mr-4" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmation(e.target.checked)} />
            <div>
              <p className="text-sm font-bold text-font-primary">
                I agree to the terms below
              </p>
              <p className="text-sm font-medium text-font-primary">
                By sending this transfer you agree to share your personal information (full name, physical address, national identification information) with the receiving exchange.
              </p>
            </div>
          </div>

          <Button
            className="w-full"
            disabled={!isOwner ? (!confirmation || !fullName || !physicalAddress) : !confirmation}
            onClick={onClickContinue}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default ConfirmWalletAddress;