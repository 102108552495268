import Header from "components/common/header/Header";
import RemovePopup from "components/settings/paymentMethod/RemovePopup";
import { useState } from "react";
import { PATHS } from "utils/consts";
import { BankSvg, BinSvg, PaymentLogo3 } from "utils/icons";

const PaymentMethod: React.FC = () => {
  const [method, setMethod] = useState<string>('');
  const [isOpen, setOpen] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState(
    [
      {
        id: "method1",
        logo: PaymentLogo3,
        title: "MasterCard",
        description: "Ending 4242",
      },
      {
        id: "method2",
        logo: BankSvg,
        title: "HSBC",
        description: "04-00-15 15514804",
      }
    ]
  );

  const removePaymentMethod = () => {
    setPaymentMethods([...paymentMethods.filter(el => el.id !== method)]);
    setOpen(false);
  };

  return (
    <>
      <Header title="Payment Methods" />
      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Payment Methods</label>
        {
          paymentMethods.map(method => (
            <div
              className="flex justify-between items-center bg-surface-secondary rounded-xl p-4 mt-4 mb-2"
              key={method.id}
            >
              <div className="flex items-center mr-6">
                <img src={method.logo} />
                <div className="ml-4">
                  <div className="text-base font-medium text-font-primary">{method.title}</div>
                  <span className="text-xs font-medium text-font-subtle">{method.description}</span>
                </div>
              </div>
              <img onClick={() => {
                setOpen(true);
                setMethod(method.id);
              }} src={BinSvg} />
            </div>
          ))
        }
      </div>
      {isOpen && <RemovePopup
        onConfirm={removePaymentMethod}
        onClose={() => { setOpen(false) }}
      />}
    </>
  );
};

export default PaymentMethod;