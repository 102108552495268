interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

const CopyIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5 4.5H4.5V13.5H6.5V15.5H4.5C3.39543 15.5 2.5 14.6046 2.5 13.5V4.5C2.5 3.39543 3.39543 2.5 4.5 2.5H13.5C14.6046 2.5 15.5 3.39543 15.5 4.5V6.5H13.5V4.5ZM10.5 10.5H19.5V19.5H10.5V10.5ZM8.5 10.5C8.5 9.39543 9.39543 8.5 10.5 8.5H19.5C20.6046 8.5 21.5 9.39543 21.5 10.5V19.5C21.5 20.6046 20.6046 21.5 19.5 21.5H10.5C9.39543 21.5 8.5 20.6046 8.5 19.5V10.5Z" fill="#FEFEFE" />
    </svg>

  );
};

export default CopyIcon;