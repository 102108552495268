import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  LoginForm,
  CodeVerification,
} from "components/authentication";
import { RootState } from "store";
import { generateToken } from "store/slices/userSlice";
import { ACTION_STATUS, AUTHENTICATION_STATUS, AUTHENTICATION_TYPE, LOGIN_AS, PATHS } from "utils/consts";
import { AuthenticationInfo } from "types";
import { VeriffAfterAuth } from "components/authentication/veriff";

enum LOGIN_STEPS {
  LOGIN_INPUT = "LOGIN_INPUT",
  CODE_VERIFICATION = "CODE_VERIFICATION",
  VERIFF_VERIFICATION = "VERIFF_VERIFICATION",
}


const Login: React.FC = () => {

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [authType, setAuthType] = useState<AUTHENTICATION_TYPE>(AUTHENTICATION_TYPE.EMAIL_AUTHENTICATION);
  const [loginAs, setLoginAsType] = useState<LOGIN_AS>(LOGIN_AS.INDIVIDUAL_CUSTOMER);
  const [currentStep, setCurrentStep] = useState<LOGIN_STEPS>(LOGIN_STEPS.LOGIN_INPUT);
  const [authInfo, setAuthenticationInfo] = useState<AuthenticationInfo>({});

  const { generateTokenStatus } = useSelector((state: RootState) => state.user);

  const nextStepOfLoginForm = (authInfo: AuthenticationInfo) => {
    setAuthenticationInfo(authInfo);
    setCurrentStep(LOGIN_STEPS.CODE_VERIFICATION);
  };

  const previousStepOfCodeVerification = () => {
    setCurrentStep(LOGIN_STEPS.LOGIN_INPUT);
  };

  const nextStepOfCodeVerification = () => {
    //dispatch(generateToken({ emailAddress: authInfo.emailAddress as string }));
    setCurrentStep(LOGIN_STEPS.VERIFF_VERIFICATION);
  };

  const nextStepOfVeriffVeriffVerification = () => {
    dispatch(generateToken({ emailAddress: authInfo.emailAddress as string }));
    //setCurrentStep(LOGIN_STEPS.VERIFF_VERIFICATION);
  };

  useEffect(() => {
    if (generateTokenStatus === ACTION_STATUS.SUCCESS) {
      navigate(PATHS.BUY);
    }
  }, [generateTokenStatus]);

  useEffect(() => {
    return () => {
      setCurrentStep(LOGIN_STEPS.LOGIN_INPUT);
    }
  }, []);

  return (
    <>
      {
        {
          [LOGIN_STEPS.LOGIN_INPUT]:
            <LoginForm
              authType={authType}
              loginAs={loginAs}
              setAuthenticationType={(type: AUTHENTICATION_TYPE) => { setAuthType(type); }}
              setLoginAsType={(type: LOGIN_AS) => { setLoginAsType(type); }}
              nextStep={nextStepOfLoginForm}
            />,
          [LOGIN_STEPS.CODE_VERIFICATION]:
            <CodeVerification
              authType={authType}
              authInfo={authInfo}
              authStatus={AUTHENTICATION_STATUS.SIGNIN}
              previousStep={previousStepOfCodeVerification}
              nextStep={nextStepOfCodeVerification}
            />,
          [LOGIN_STEPS.VERIFF_VERIFICATION]: <VeriffAfterAuth />
        }[currentStep]
      }
    </>
  );
};

export default Login;