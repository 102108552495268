interface IProps {
  className?: string;
  width?: number;
  height?: number;
  fill?: string;
  onClick?: () => void;
}

const BinIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#FEFEFE"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M9.34966 4.56584C9.40048 4.4642 9.50436 4.4 9.61799 4.4H14.3819C14.4956 4.4 14.5994 4.4642 14.6503 4.56584L15.3739 6.01305C15.5468 6.35883 15.9672 6.49899 16.313 6.3261C16.6588 6.15321 16.7989 5.73274 16.6261 5.38695L15.9025 3.93974C15.6145 3.3638 15.0258 3 14.3819 3H9.61799C8.97408 3 8.38543 3.3638 8.09747 3.93974L7.37386 5.38695C7.20097 5.73274 7.34112 6.15321 7.68691 6.3261C8.03269 6.49899 8.45316 6.35883 8.62606 6.01305L9.34966 4.56584ZM3.8 7.19995C3.35817 7.19995 3 7.55812 3 7.99995C3 8.44178 3.35817 8.79995 3.8 8.79995H5.24378L6.13132 20.338C6.20346 21.2758 6.98546 21.9999 7.92602 21.9999H16.074C17.0145 21.9999 17.7965 21.2758 17.8687 20.338L18.7562 8.79995H20.2C20.6418 8.79995 21 8.44178 21 7.99995C21 7.55812 20.6418 7.19995 20.2 7.19995H3.8ZM7.72661 20.2153L6.84851 8.79995H17.1515L16.2734 20.2153C16.2654 20.3195 16.1785 20.3999 16.074 20.3999H7.92602C7.82151 20.3999 7.73463 20.3195 7.72661 20.2153ZM9.50005 10.7C9.11345 10.7 8.80005 11.0134 8.80005 11.4V18C8.80005 18.3866 9.11345 18.7 9.50005 18.7C9.88665 18.7 10.2 18.3865 10.2 18V11.4C10.2 11.0134 9.88665 10.7 9.50005 10.7ZM11.3 11.4C11.3 11.0134 11.6134 10.7 12 10.7C12.3866 10.7 12.7 11.0134 12.7 11.4V18C12.7 18.3865 12.3866 18.7 12 18.7C11.6134 18.7 11.3 18.3866 11.3 18V11.4ZM14.5 10.7C14.1134 10.7 13.8 11.0134 13.8 11.4V18C13.8 18.3866 14.1134 18.7 14.5 18.7C14.8866 18.7 15.2 18.3865 15.2 18V11.4C15.2 11.0134 14.8866 10.7 14.5 10.7Z" />
    </svg>
  );
};

export default BinIcon;