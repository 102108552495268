import { SVGProps } from "react";

interface IProps extends SVGProps<SVGSVGElement> {
}

const EditIcon: React.FC<IProps> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="#0A33FF"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M16.4834 5.10108C16.0603 4.74607 15.4296 4.80126 15.0746 5.22433L14.4318 5.99038L17.496 8.56153L18.1388 7.79548C18.4938 7.37241 18.4386 6.74165 18.0155 6.38665L16.4834 5.10108ZM11.7431 18.5289C11.3259 19.0262 10.7586 19.3747 10.1265 19.5221L7.20492 20.2036C5.90644 20.5065 4.68077 19.4781 4.75358 18.1467L4.91739 15.1512C4.95283 14.503 5.19754 13.8839 5.61478 13.3866L13.5425 3.93876C14.6075 2.66953 16.4998 2.50398 17.769 3.56899L19.3011 4.85456C20.5703 5.91957 20.7359 7.81184 19.6708 9.08106L11.7431 18.5289ZM12.5034 8.28851L7.14687 14.6722C7.00779 14.838 6.92622 15.0444 6.9144 15.2604L6.75059 18.2559L9.67216 17.5744C9.88288 17.5253 10.072 17.4091 10.211 17.2434L15.5676 10.8597L12.5034 8.28851Z" />
    </svg>
  );
};

export default EditIcon;