import Button from "components/common/buttons/Button";
import { WarningSvg } from "utils/icons";

interface IProps {
  onClose: () => void,
  onConfirm: () => void,
}

const CloseAccountPopup: React.FC<IProps> = (props) => {
  const { onConfirm, onClose } = props;

  return (
    <div className="fixed left-0 top-0 w-full h-screen flex justify-center items-center z-50 bg-font-primary/75 p-4">
      <div className="bg-surface-primary flex flex-col justify-center items-center rounded-xl p-8">
        <img src={WarningSvg} />
        <h1 className="text-2xl font-bold text-font-primary mb-4 mt-4">Warning!</h1>
        <span className="text-base font-medium text-font-primary text-center">Your account will be permanently closed. Please confirm that you wish to proceed to close your account.</span>
        <Button className="w-full mt-8 mb-4" onClick={onConfirm}><>Close Account</></Button>
        <a className="text-sm font-bold text-primary" onClick={onClose}>Dismiss</a>
      </div>
    </div>
  )
}

export default CloseAccountPopup;