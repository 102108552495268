import Header from "components/common/header/Header";
import { Link } from "react-router-dom";
import { PATHS } from "utils/consts";
import { ArrowRightSvg, BankCardSvg, BankSvg, PaymentLogo1, PaymentLogo2, PaymentLogo3, PaymentLogo4 } from "utils/icons";

const PaymentMethod: React.FC = () => {

  return (
    <>
      <Header title="Payment Methods" />

      <div className="p-4 mt-4">
        <label className="text-2xl font-bold text-font-primary">Payment Methods</label>
        <div className="flex justify-between items-center bg-surface-secondary rounded-xl p-4 mt-4 mb-2">
          <Link to={`${PATHS.SELL}${PATHS.BANKCARD}`} className="w-[100%]">
            <div className="flex items-center mr-6">
              <img src={BankCardSvg} />
              <div className="flex ml-4">
                <span className="text-base font-bold text-font-primary">
                  Bank Card
                </span>
                <img src={PaymentLogo1} className="ml-2" />
                <img src={PaymentLogo2} className="ml-2" />
                <img src={PaymentLogo3} className="ml-2" />
              </div>
              <img src={ArrowRightSvg} className="ml-auto"/>
            </div>
          </Link>
        </div>

        <div className="flex justify-between items-center bg-surface-secondary rounded-xl p-4 mt-4 mb-2">
          <Link to={`${PATHS.SELL}${PATHS.BANK_DETAIL}`} className="w-[100%]">
            <div className="flex items-center mr-6">
              <img src={BankSvg} />
              <div className="flex ml-4">
                <span className="text-base font-bold text-font-primary">
                  Bank Transfer
                </span>
                <img src={PaymentLogo4} className="ml-2" />
              </div>
              <img src={ArrowRightSvg} className="ml-auto"/>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PaymentMethod;