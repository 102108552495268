import { CancelSvg, EmailSvg } from "utils/icons";

interface IProps {
  email: string;
  onClose: () => void;
	onResend: (email: string) => void;
}

const CheckEmail: React.FC<IProps> = (props) => {
  const { email, onClose, onResend } = props;

	const resend = () => {
		onResend(email);
	};

  return (
    <div className="fixed left-0 top-0 w-full h-screen z-50 bg-surface-primary p-4">
      <div className="flex justify-between items-end mb-4">
        <label className="text-lg font-bold text-font-primary"></label>
        <img className="" src={CancelSvg} onClick={() => { onClose(); }} />
      </div>
      <div className="w-full mt-8">
				<div className="bg-feedback-information-tint flex flex-col justify-center items-center p-8 rounded-xl">
          <div className="rounded-full p-4 w-fit bg-feedback-information mb-4">
            <img src={EmailSvg} />
          </div>
					<div className="w-full font-bold text-3xl font-text-primary text-center">Check Your Email</div>
					<div className="w-full font-medium text-base font-text-primary mt-4">We’ve sent the password recovery instructions to your email.</div>
					<div className="w-full font-medium text-sm font-text-primary text-center mt-4">Didn't receive it <a className="text-primary font-bold" onClick={resend}>Resend</a></div>
				</div>
      </div>
    </div>
  );
};

export default CheckEmail;