interface IProps {
  className?: string;
  onClick?: () => void,
  children: React.ReactNode;
}

const IconButton: React.FC<IProps> = (props) => {
  const { className, onClick, children } = props;
  return (
    <button className={`p-4 rounded-full border border-font-placeholder ${className}`} onClick={onClick}>
      {children}
    </button>
  );
};

export default IconButton;